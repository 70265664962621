import * as Sentry from "@sentry/react"
import { SeverityLevel } from "@sentry/react"

export class SentryLog {
    msg(message: string, level: SeverityLevel = "info", extra?: Record<string, any>) {
        if (level === "error") {
            console.error(message)
        } else {
            console.log(message)
        }
        Sentry.captureMessage(message, {
            level,
            extra,
        })
    }

    err(e: unknown, level: SeverityLevel = "error", extra?: Record<string, unknown>) {
        if (level === "error" || level === "fatal") {
            console.error(e)
        } else {
            console.log(e)
        }
        Sentry.captureException(e, { level, extra })
    }
}

export const sentryLog = new SentryLog()
