import { atom } from "jotai"

/**
 * Open and close quick action modal
 * @usage const [open, setOpen] = useAtom(openQuickActionModal)
 */

export const openQueueStatus = atom(false)
export const openNotify = atom(false)
export const openVaultBg = atom(false)
export const openEmail = atom(false)
export const openBridge = atom(false)
