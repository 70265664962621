// This page list all available vaults from contract
import { useEffect } from "react"
import { useWalletClient } from "wagmi"
import { shallow } from "zustand/shallow"
import { useAprStore } from "../../service/databricks/aprStore"
import { useHygraphStore } from "../../service/hygraph/hygraphStore"
import { useKantabanStore } from "../../service/kantaban/kantabanStore"

export function useVaults() {
    // TODO： this looks like can be replaced with standard loader to boost performance https://reactrouter.com/en/main/route/loader#loader
    // i.e. all pages use the same loader and let react-router handle the loading
    // First get CMS data back from react-router
    const { vaultsCMS, listVaults, frontpagesCMS, listFrontpages } = useHygraphStore(
        state => ({
            vaultsCMS: state.vaults,
            listVaults: state.listVaults,
            frontpagesCMS: state.frontpages,
            listFrontpages: state.listFrontpages,
        }),
        shallow,
    )
    useEffect(() => {
        listVaults()
        listFrontpages()
    }, [listVaults, listFrontpages])

    // Get vaults spec from contract
    const { vaults, fetchVaults, isLoadingVaults } = useKantabanStore(
        state => ({
            vaults: state.vaults,
            fetchVaults: state.fetchVaults,
            isLoadingVaults: state.loading,
        }),
        shallow,
    )

    // fetchVaults
    const { data: signer } = useWalletClient()
    useEffect(() => {
        fetchVaults()
    }, [fetchVaults, signer])

    // Get APR
    const { fetchApr, dailyAprMap } = useAprStore(
        state => ({
            fetchApr: state.fetch,
            dailyAprMap: state.dailyAprMap,
        }),
        shallow,
    )

    useEffect(() => {
        fetchApr()
    }, [fetchApr])

    return { vaultsCMS, frontpagesCMS, vaults, dailyAprMap, isLoadingVaults }
}
