import { Footer, globalCss } from "@sayaww/nomimono"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import { ConnectKitProvider } from "connectkit"
import flagsmith from "flagsmith"
import { FlagsmithProvider } from "flagsmith/react"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { WagmiConfig } from "wagmi"
import { BridgeModal } from "./components/BridgeModal"
import { Spinner } from "./components/Spinner"
import { FLAGSMITH_ENV_ID, NODE_ENV, SENTRY_DSN } from "./constants"
import "./index.css"
import Error from "./pages/Error"
import NotFound from "./pages/NotFound"
import Root from "./pages/Root"
import VaultDetail from "./pages/VaultDetail/VaultDetail"
import VaultDetailOriginal from "./pages/VaultDetail/VaultDetailOriginal"
import Vaults from "./pages/Vaults/Vaults"
import { flagSmithDefaultFlags } from "./service/flagsmith/flagSmith"
import { useWagmi } from "./service/wagmi/useWagmi"
import { isProduction } from "./util/stage"
// NOTE:
// With create-react-app, NODE_ENV === 'production' is true
// only when 'npm run build'
if (NODE_ENV === "production") {
    Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        enabled: isProduction(),
    })
}

const globalStyles = globalCss({
    "*": { webkitFontSmoothing: "antialiased" },
})

// Routes setup
// TODO: add a catch all 404 page. Now it renders a blank page
const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <Error />,
        children: [
            {
                //  all vaults
                element: <Vaults />,
                index: true,
                errorElement: <Error />,
            },
            {
                // og vault detail
                path: "/og/:vaultSlug",
                element: <VaultDetailOriginal />,
                errorElement: <Error />,
            },
            {
                // vault detail
                path: "/:vaultSlug",
                element: <VaultDetail />,
                errorElement: <Error />,
            },
            {
                // catch-all 404 route (TODO: not working because it conflicts with vaultSlug...)
                path: "*",
                element: <NotFound />,
            },
        ],
    },
])

function App() {
    const client = useWagmi("HotTub")
    globalStyles()

    // apply dark theme
    // useEffect(() => {
    //     document.getElementsByTagName("html")[0].classList.add(darkTheme)
    // }, [])

    if (!client) {
        return (
            <div
                style={{
                    color: "white",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div style={{ height: 48, width: 48 }}>
                    <Spinner />
                </div>
            </div>
        )
    }

    return (
        <FlagsmithProvider
            options={{
                environmentID: FLAGSMITH_ENV_ID,
                defaultFlags: flagSmithDefaultFlags,
            }}
            flagsmith={flagsmith}
        >
            <WagmiConfig config={client}>
                <ConnectKitProvider
                    mode="dark"
                    options={{
                        // language: "zh-CN",
                        walletConnectName: "WalletConnect",
                        // disclaimer: "This is some custom  disclaimer placeholder text.",
                    }}
                >
                    <>
                        <RouterProvider router={router} />
                        <div
                            style={{
                                fontFamily: "SkModernistBold",
                            }}
                        >
                            <Footer isDark />
                        </div>
                    </>
                </ConnectKitProvider>
            </WagmiConfig>
        </FlagsmithProvider>
    )
}

export default App
