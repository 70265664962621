import { Box, BrandButton, Button, Dialog, LucideIcon, Stack, Text } from "@sayaww/nomimono"
import { Squircle } from "corner-smoothing"
import { AnimatePresence, motion } from "framer-motion"
import React, { useCallback, useEffect, useState } from "react"
import { Toaster } from "react-hot-toast"
import { useKonami } from "react-konami-code"
import Particles from "react-particles"
import { Link, Outlet, ScrollRestoration, useLocation } from "react-router-dom"
import { TopAppBanner } from "src/components/TopAppBanner"
import { useGeolocationStore } from "src/service/geolocation/geolocationStore"
import { EventGroup, EventName, segmentService } from "src/service/segment/SegmentService"
import { loadFull } from "tsparticles"
import type { Engine } from "tsparticles-engine"
import { ISourceOptions } from "tsparticles-engine"
import { useAccount, useDisconnect } from "wagmi"
import { HotTubLogo } from "../components/Logo"
import { Notifications } from "../components/Notifications"
// TODO move particlesOptions out from the util folder

import { useSetAtom } from "jotai"
import { BridgeModal } from "src/components/BridgeModal"
import { EmailSignupModal } from "src/components/EmailSignup"
import { openBridge } from "src/util/uiAtom"
import particlesOptions from "../util/particles.json"
import { isProduction } from "../util/stage"
import { ConnectWalletBtn } from "./Vaults/ConnectWalletBtn"

// This is the home page where we list all vaults
export default function Root() {
    // Note track page change.
    const { pathname } = useLocation()
    useEffect(() => segmentService.page(), [pathname])

    const { address, connector } = useAccount()

    useEffect(() => {
        if (address && connector) {
            segmentService.identify(address)
            segmentService.track({
                eventGroup: EventGroup.INTERACTION,
                eventName: EventName.WALLET_CONNECTED,
                payload: { connector: connector.name },
            })
            console.log("connector:", connector)
        }
    }, [address, connector])

    const particlesInit = useCallback(async (engine: Engine) => {
        await loadFull(engine)
    }, [])

    return (
        <Stack className="overflow-visible nomimono-style" width={"100%"}>
            <TopAppBanner />
            <ScrollRestoration />
            <Toaster />
            <Nav />
            <Notifications />
            <BridgeModal />
            <AnimatePresence>
                <Outlet />
            </AnimatePresence>
            <GeoBlock />

            <EmailSignupModal />
            <div style={{ zIndex: -1 }}>
                <Particles id={"tsparticles"} options={particlesOptions as ISourceOptions} init={particlesInit} />
            </div>
        </Stack>
    )
}

const GeoBlock = () => {
    const { fetch, isBlocked } = useGeolocationStore()

    useEffect(() => {
        fetch()
    }, [fetch])

    const enteredBypassCode = localStorage.getItem("showGeoBlock") === "false"

    const [showGeoBlock, setShowGeoBlock] = useState(!enteredBypassCode)

    useEffect(() => {
        if (isProduction() && isBlocked) {
            setShowGeoBlock(!enteredBypassCode)
        } else {
            setShowGeoBlock(false)
        }
    }, [isBlocked, enteredBypassCode])

    const bypass = () => {
        // enter "kantaban" again to bypass or un-bypass
        if (!enteredBypassCode) {
            setShowGeoBlock(false)
            console.log("aye, hot tub is real arb.")
            localStorage.setItem("showGeoBlock", "false")
        }

        if (enteredBypassCode) {
            setShowGeoBlock(true)
            localStorage.setItem("showGeoBlock", "true")
        }
    }

    useKonami(bypass, { code: [75, 65, 78, 84, 65, 66, 65, 78] })
    // code is: `kantaban`

    return (
        <Dialog.Root open={showGeoBlock}>
            <Dialog.Portal>
                <Dialog.Overlay>
                    <Dialog.Content asChild>
                        <Squircle
                            cornerRadius={30}
                            cornerSmoothing={0.6}
                            style={{
                                padding: "36px",
                                width: "100%",
                                maxWidth: "480px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                backgroundColor: "white",
                            }}
                        >
                            <Box
                                css={{
                                    flex: 1,
                                    flexDirection: "column",
                                    gap: "$8",
                                }}
                            >
                                <Box>
                                    <motion.div
                                        style={{
                                            width: "48px",
                                            height: "48px",
                                            position: "relative",
                                            display: "inline-block",
                                        }}
                                        initial={{ rotate: 0, color: "#F46CBA" }}
                                        whileHover={{
                                            rotate: 360,
                                            transition: {
                                                duration: 18,
                                                repeat: Infinity,
                                                type: "tween",
                                                ease: "linear",
                                            },
                                        }}
                                        animate={{
                                            rotate: 360,
                                            color: ["#F46CBA", "#6C7AF4", "#F46CBA"],
                                        }}
                                        transition={{
                                            repeat: Infinity,
                                            duration: 6,
                                            type: "tween",
                                            ease: "linear",
                                        }}
                                    >
                                        <LucideIcon name="globe-2" size={48} />
                                        <motion.div
                                            initial={{ rotate: 0, color: "#F46CBA" }}
                                            animate={{
                                                rotate: 360,
                                                backgroundColor: ["#F46CBA", "#6C7AF4", "#F46CBA"],
                                            }}
                                            transition={{
                                                repeat: Infinity,
                                                duration: 6,
                                                type: "tween",
                                                ease: "linear",
                                            }}
                                            onClick={() => {
                                                setShowGeoBlock(false)
                                                console.log("aye, hot tub is real arb.")
                                                localStorage.setItem("showGeoBlock", "false")
                                            }}
                                            style={{
                                                borderRadius: "20px",
                                                width: "6px",
                                                height: "6px",
                                                position: "absolute",
                                                top: "8px",
                                                left: "22px",
                                            }}
                                        ></motion.div>
                                    </motion.div>
                                </Box>
                                <Text size="title2">Service Not Available in Your Region</Text>
                                <Text size="body">
                                    Sorry! For compliance reasons, this service is not accessible in your area. Use of
                                    VPN, Tor, proxies or other means to circumvent this restriction is a violation of
                                    our{" "}
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href="https://support.perp.com/hc/en-us/articles/7529352712729-Terms-of-Service"
                                    >
                                        Terms of Service
                                    </a>
                                    .
                                </Text>
                            </Box>
                        </Squircle>
                    </Dialog.Content>{" "}
                </Dialog.Overlay>
            </Dialog.Portal>
        </Dialog.Root>
    )
}

const Nav = () => {
    const { disconnect } = useDisconnect()
    const setOpen = useSetAtom(openBridge)
    return (
        <Box
            css={{
                maxWidth: 1440,
                zIndex: 3,
                position: "relative",
                margin: "0 auto",
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                px: 24,
                py: 24,
                "@sm": {
                    px: 36,
                    py: 24,
                },
            }}
        >
            <LogoLink />
            <Box css={{ flex: 1 }} />
            <BrandButton
                onClick={() => {
                    setOpen(true)
                    disconnect()
                }}
                css={{
                    fontWeight: 400,
                    cursor: "pointer",
                    marginRight: "$4",
                }}
                brand={"normal"}
                size={{
                    "@initial": "xs",
                    "@sm": "s",
                    "@md": "s",
                    "@lg": "m",
                    "@xl": "m",
                    "@2xl": "m",
                }}
                leftIcon={<LucideIcon name="banknote" size={16} />}
            >
                Bridge
            </BrandButton>
            <Stack direction={"row"} gap={2} justifyContent={"center"} alignItems={"center"}>
                <ConnectWalletBtn />
            </Stack>
        </Box>
    )
}

const LogoLink = () => {
    return (
        <Box
            as={Link}
            to="/"
            css={{
                color: "white",
                textDecoration: "none",
                objectFit: "contain",
                gap: "$2",
                direction: "row",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box
                css={{
                    boxSizing: "border-box",
                    display: "block",
                    flexShrink: 0,
                    height: "24px",
                    aspectRatio: "101/23",
                    "@sm": {
                        height: "24px",
                    },
                    "@md": {
                        height: "24px",
                    },
                    "@lg": {
                        height: "31px",
                    },
                    "@xl": {
                        height: "31px",
                    },
                    "@2xl": {
                        height: "31px",
                    },
                }}
            >
                <HotTubLogo width="100%" height="100%" />
            </Box>
        </Box>
    )
}
