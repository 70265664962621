import Big from "big.js"
import { Signer } from "ethers"
import { DateTime } from "luxon"
import { Cache } from "node-ts-cache"
import {
    ERC20__factory,
    Kantaban__factory,
    ShowerRoom__factory,
    VaultConfig__factory,
    VaultToken__factory,
} from "../../typechain"
import { logMethod } from "../../util/log"
import { bigNum2Big } from "../../util/number"
import { memoryCache } from "../cache/nodeTsCache"
import { IMulticallParams, multicallClient } from "../multicall/MulticallClient"
import { kantabanClient } from "./KantabanClient"
import { KantabanBalance } from "./KantabanVault"
import type { KantabanVaultMeta } from "./KantabanVaultMeta"

interface IKantabanVaultInfo {
    totalShares: Big
    totalSupplyCap: Big
    totalShareValue: Big
    transferCooldown: number
    srMaxDepositPerUser: Big
}

interface IKantabanVaultInfoBySigner {
    balance: KantabanBalance
    isWhitelistedLiquidityProvider: boolean
    lastMintedAt?: DateTime
    allowance: Big
    srAllowance: Big
}

export class McKantabanClient {
    @logMethod
    async getVaultInfoBySigner(
        vaultInfo: IKantabanVaultInfo,
        vaultMeta: KantabanVaultMeta,
        signer: Signer,
    ): Promise<IKantabanVaultInfoBySigner> {
        const signerAddr = await signer.getAddress()
        const callParams: IMulticallParams[] = [
            {
                contractAddr: vaultMeta.shareAsset.addr,
                abi: VaultToken__factory.abi,
                funcName: "balanceOf",
                funcParams: [signerAddr],
            },
            {
                contractAddr: vaultMeta.vaultConfigAddr,
                abi: VaultConfig__factory.abi,
                funcName: "isWhitelistedLiquidityProvider",
                funcParams: [signerAddr],
            },
            {
                contractAddr: vaultMeta.shareAsset.addr,
                abi: VaultToken__factory.abi,
                funcName: "getLastMintedAt",
                funcParams: [signerAddr],
            },
            {
                contractAddr: vaultMeta.depositAsset.addr,
                abi: ERC20__factory.abi,
                funcName: "allowance",
                funcParams: [signerAddr, vaultMeta.contractAddr],
            },
            {
                contractAddr: vaultMeta.depositAsset.addr,
                abi: ERC20__factory.abi,
                funcName: "allowance",
                funcParams: [signerAddr, vaultMeta.showerRoomAddr],
            },
        ]
        const callResults = await multicallClient.tryAggregate(callParams)

        const [[balanceOf], [isWhitelistedLiquidityProvider], [_lastMintedAt], [_allowance], [_srAllowance]] =
            callResults
        const shares = bigNum2Big(balanceOf, vaultMeta.shareAsset.decimal)
        const balance = shares.lte(0)
            ? {
                  positionShares: Big(0),
                  positionShareValue: Big(0),
              }
            : {
                  positionShares: shares,
                  positionShareValue: kantabanClient.calculatePositionShareValue(
                      shares,
                      vaultInfo.totalShareValue,
                      vaultInfo.totalShares,
                  ),
              }
        const lastMintedAt = _lastMintedAt.isZero() ? undefined : DateTime.fromSeconds(_lastMintedAt.toNumber())
        const allowance = bigNum2Big(_allowance, vaultMeta.depositAsset.decimal)
        const srAllowance = bigNum2Big(_srAllowance, vaultMeta.depositAsset.decimal)
        return {
            balance,
            isWhitelistedLiquidityProvider,
            lastMintedAt,
            allowance,
            srAllowance,
        }
    }

    @logMethod
    @Cache(memoryCache, { ttl: 30 })
    async getVaultInfo(vaultMeta: KantabanVaultMeta): Promise<IKantabanVaultInfo> {
        const callParams: IMulticallParams[] = [
            {
                contractAddr: vaultMeta.shareAsset.addr,
                abi: VaultToken__factory.abi,
                funcName: "totalSupply",
                funcParams: [],
            },
            {
                contractAddr: vaultMeta.contractAddr,
                abi: Kantaban__factory.abi,
                funcName: "totalAssets",
                funcParams: [],
            },
            {
                contractAddr: vaultMeta.shareAsset.addr,
                abi: VaultToken__factory.abi,
                funcName: "getTotalSupplyCap",
                funcParams: [],
            },
            {
                contractAddr: vaultMeta.shareAsset.addr,
                abi: VaultToken__factory.abi,
                funcName: "getTransferCooldown",
                funcParams: [],
            },
            {
                contractAddr: vaultMeta.showerRoomAddr,
                abi: ShowerRoom__factory.abi,
                funcName: "getMaxDepositPerUser",
                funcParams: [],
            },
        ]
        const callResults = await multicallClient.tryAggregate(callParams)
        const [[totalSupply], [totalAssets], [totalSupplyCap], [transferCooldown], [maxDepositPerUser]] = callResults

        return {
            totalShares: bigNum2Big(totalSupply, vaultMeta.shareAsset.decimal),
            totalSupplyCap: bigNum2Big(totalSupplyCap, vaultMeta.shareAsset.decimal),
            totalShareValue: bigNum2Big(totalAssets, vaultMeta.depositAsset.decimal),
            transferCooldown,
            srMaxDepositPerUser: bigNum2Big(maxDepositPerUser, vaultMeta.depositAsset.decimal),
        }
    }
}

export const mcKantabanClient = new McKantabanClient()
