import { isEmpty } from "lodash"
import { STAGE, Stage } from "../../constants"
import { EventGroup, EventName, segmentService } from "../segment/SegmentService"

export class MailModoClient {
    private readonly ENDPOINT =
        STAGE === Stage.Production
            ? "https://mo5x8mw6k0.execute-api.ap-southeast-1.amazonaws.com/production"
            : "https://c95xvsp52e.execute-api.ap-northeast-1.amazonaws.com/staging"

    async submitEmail(email: string) {
        if (isEmpty(email)) {
            throw new Error(`The format of ${email} is invalid`)
        }

        const res = await fetch(`${this.ENDPOINT}/subscribe`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: email }),
        })
        if (res.status >= 400) {
            throw new Error(`Bad ${res.status} response from server`)
        }

        segmentService.track({
            eventGroup: EventGroup.INTERACTION,
            eventName: EventName.HOTTUB_SIGNUP_VAULT,
            payload: { email: email },
        })
    }

    async register(address: string, email: string) {
        const res = await fetch(`${this.ENDPOINT}/register`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ address, email }),
        })
        if (res.status >= 400) {
            throw new Error(`Bad ${res.status} response from server`)
        }

        segmentService.track({
            eventGroup: EventGroup.INTERACTION,
            eventName: EventName.REGISTER_EMAIL,
            payload: { address, email },
        })
    }

    async checkRegister(address: string) {
        const res = await fetch(`${this.ENDPOINT}/has-registered?address=${address}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        if (res.status >= 400) {
            throw new Error(`Bad ${res.status} response from server`)
        }
        const result: { hasRegistered: boolean } = await res.json()
        return result.hasRegistered
    }
}

export const mailModoClient = new MailModoClient()
