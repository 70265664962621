import { create, StateCreator } from "zustand"
import { devtools } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"
import { errorParser } from "./ErrorParser"

export type NotifStatus = "info" | "success" | "error"

export interface NotifState {
    isOpen: boolean
    status: NotifStatus
    message: string
    /**
     * undefined if the toast should be persisted
     */
    duration?: number
    /**
     * Transaction hash if this message is associate with certain transaction
     */
    hash?: string

    openSuccess: (message: string, duration?: number, hash?: string) => void
    openError: (err: unknown, duration?: number) => void
    close: () => void
}

export const initNotifState = {
    isOpen: false,
    status: "info" as NotifStatus,
    message: "",
}

const createNotifStore: StateCreator<
    NotifState,
    [["zustand/immer", never], ["zustand/devtools", never]],
    [],
    NotifState
> = set => ({
    ...initNotifState,
    openSuccess: (message: string, duration?: number, hash?: string) =>
        set(() => ({
            isOpen: true,
            status: "success",
            message,
            duration,
            hash,
        })),
    openError: (err: unknown, duration?: number) => {
        const { message, hash } = errorParser.parse(err)
        set(() => ({
            isOpen: true,
            status: "error",
            message,
            duration,
            hash,
        }))
    },
    close: () =>
        set(() => ({
            isOpen: initNotifState.isOpen,
            status: initNotifState.status,
            message: initNotifState.message,
            duration: undefined,
            hash: undefined,
        })),
})

export const useNotifStore = create(immer(devtools(createNotifStore)))
