import { create, StateCreator } from "zustand"
import { devtools } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"
import { notifyError } from "../notif/notifHelper"
import { LoadableState, ZustandGet, ZustandLoadingGuardSequence, ZustandSet } from "../zustand/ZustandLoadingGuard"
import { mailModoClient } from "./MailModoClient"

export type SubscriptionResult = "subscribed"

export interface MailModoState extends LoadableState {
    // for subscribe to mailmodo only
    subscriptionResultMap: Record<string, SubscriptionResult>
    submitEmail: (email: string) => Promise<void>

    // for subscribe to mailmodo and shower room events
    isChecking: boolean
    hasRegistered: boolean
    register: (address: string, email: string) => Promise<void>
    checkRegister: (address: string) => Promise<void>
}

export const initMailModoState = {
    loading: false,
    subscriptionResultMap: {},
    hasRegistered: false,
    isChecking: true,
}

async function submitEmail(get: ZustandGet<MailModoState>, set: ZustandSet<MailModoState>, email: string) {
    try {
        await mailModoClient.submitEmail(email)
        set(state => {
            state.subscriptionResultMap[email] = "subscribed"
        })
    } catch (e) {
        notifyError(e)
    }
}

async function register(
    get: ZustandGet<MailModoState>,
    set: ZustandSet<MailModoState>,
    address: string,
    email: string,
) {
    try {
        await mailModoClient.register(address, email)
        set(state => {
            state.hasRegistered = true
            state.subscriptionResultMap[email] = "subscribed"
        })
    } catch (e) {
        notifyError(e)
    }
}

async function checkRegister(get: ZustandGet<MailModoState>, set: ZustandSet<MailModoState>, address: string) {
    try {
        const hasRegister = await mailModoClient.checkRegister(address)
        set(state => {
            state.hasRegistered = hasRegister
            // user can unsubscribe manually so registered does not necessarily mean subscribed
            state.isChecking = false
        })
    } catch (e) {
        notifyError(e)
    }
}

const lg = new ZustandLoadingGuardSequence<MailModoState>()
const createMailModoStore: StateCreator<
    MailModoState,
    [["zustand/immer", never], ["zustand/devtools", never]],
    [],
    MailModoState
> = (set, get) => ({
    ...initMailModoState,
    submitEmail: async email => lg.warp(get, set, submitEmail, email),
    register: async (address, email) => lg.warp(get, set, register, address, email),
    checkRegister: async address => lg.warp(get, set, checkRegister, address),
})

export const useMailModoStore = create(immer(devtools(createMailModoStore)))
