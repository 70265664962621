// src/pages/NotFound.tsx

import { Box, Text } from "@sayaww/nomimono"
import React from "react"

const NotFound = () => {
    return (
        <Box
            css={{
                p: 80,
                textAlign: "center",
            }}
        >
            <Text
                css={{
                    color: "$baseBackground",
                }}
                size="page-title2"
            >
                404 - Page Not Found
            </Text>
        </Box>
    )
}

export default NotFound
