import { isProduction } from "src/util/stage"
import { Erc20Meta } from "../eth/Erc20Client"

enum KantabanVaultIdList {
    ethUsdcBaseVault = "ethUsdcBaseVault",
    ethUsdcQuoteVault = "ethUsdcQuoteVault",
    opUsdcQuoteVault = "opUsdcQuoteVault",
    opUsdcBaseVault = "opUsdcBaseVault",
    btcUsdcQuoteVault = "btcUsdcQuoteVault",
    btcUsdcBaseVault = "btcUsdcBaseVault",
}

export type KantabanVaultId =
    | KantabanVaultIdList.ethUsdcBaseVault
    | KantabanVaultIdList.ethUsdcQuoteVault
    | KantabanVaultIdList.opUsdcQuoteVault
    | KantabanVaultIdList.opUsdcBaseVault
    | KantabanVaultIdList.btcUsdcQuoteVault
    | KantabanVaultIdList.btcUsdcBaseVault

/**
 * Get data here: https://metadata.perp.exchange/kantaban/optimism.json
 * slug: needs to be in sync with hygraph
 */
export interface KantabanVaultMeta {
    displayName: string
    displaySubtitle: string
    id: KantabanVaultId
    slug: string
    minDepositAmount: number
    contractAddr: string
    vaultConfigAddr: string
    showerRoomAddr: string
    depositAsset: Erc20Meta
    shareAsset: Erc20Meta
}

const ktbVaultMetasCommon: Record<
    KantabanVaultIdList,
    Pick<KantabanVaultMeta, "displayName" | "displaySubtitle" | "id" | "slug" | "minDepositAmount">
> = {
    ethUsdcBaseVault: {
        displayName: "Ethereum",
        displaySubtitle: "Arbitrage (ETH)",
        id: KantabanVaultIdList.ethUsdcBaseVault,
        slug: "arbeth-eth",
        minDepositAmount: 0.005,
    },
    ethUsdcQuoteVault: {
        displayName: "USDC",
        displaySubtitle: "Arbitrage (ETH)",
        id: KantabanVaultIdList.ethUsdcQuoteVault,
        slug: "arbeth-usdc",
        minDepositAmount: 50,
    },
    opUsdcBaseVault: {
        displayName: "Optimism",
        displaySubtitle: "Arbitrage (OP)",
        id: KantabanVaultIdList.opUsdcBaseVault,
        slug: "arbop-op",
        minDepositAmount: 30,
    },
    opUsdcQuoteVault: {
        displayName: "USDC",
        displaySubtitle: "Arbitrage (OP)",
        id: KantabanVaultIdList.opUsdcQuoteVault,
        slug: "arbop-usdc",
        minDepositAmount: 50,
    },
    btcUsdcBaseVault: {
        displayName: "Bitcoin",
        displaySubtitle: "Arbitrage (BTC)",
        id: KantabanVaultIdList.btcUsdcBaseVault,
        slug: "arbbtc-btc",
        minDepositAmount: 0.001,
    },
    btcUsdcQuoteVault: {
        displayName: "USDC",
        displaySubtitle: "Arbitrage (BTC)",
        id: KantabanVaultIdList.btcUsdcQuoteVault,
        slug: "arbbtc-usdc",
        minDepositAmount: 50,
    },
}

export const kantabanVaultMetasOpTest: Record<KantabanVaultId, KantabanVaultMeta> = {
    ethUsdcBaseVault: {
        ...ktbVaultMetasCommon.ethUsdcBaseVault,
        contractAddr: "0xDcC5Adb3cD4cf726A47Fddd0072cdFfA0B491fBA",
        vaultConfigAddr: "0x9b7a8D8B8Fcc3473e9763438479cD4d250B9eFFb",
        showerRoomAddr: "0x17b03f203B46d3bf4c5e42E1c4f3Db6DbFcB699F",
        depositAsset: {
            symbol: "WETH",
            addr: "0x4200000000000000000000000000000000000006",
            decimal: 18,
        },
        shareAsset: {
            symbol: "HT-ETH",
            addr: "0x49f56FD2EEF7E109d398c052503F275E3B63A5be",
            decimal: 36,
        },
    },
    ethUsdcQuoteVault: {
        ...ktbVaultMetasCommon.ethUsdcQuoteVault,
        contractAddr: "0x85360B875FC6735eE7C65476d0160E7499AbAef8",
        vaultConfigAddr: "0x9b7a8D8B8Fcc3473e9763438479cD4d250B9eFFb",
        showerRoomAddr: "0x190295F9145A85D29f69B1bE0987788e418F6794",
        depositAsset: {
            symbol: "USDC",
            addr: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
            decimal: 6,
        },
        shareAsset: {
            symbol: "HT-ETH-USDC",
            addr: "0xa62BfF45e31b48B20a41Ca514F9f4fD51B462d53",
            decimal: 24,
        },
    },
    opUsdcBaseVault: {
        ...ktbVaultMetasCommon.opUsdcBaseVault,
        contractAddr: "0x86C2A9D04783569EEC6C75709d41Edf779e35911",
        vaultConfigAddr: "0x9b7a8D8B8Fcc3473e9763438479cD4d250B9eFFb",
        showerRoomAddr: "0x21E86f9833ffb634F1eC267d0577D697CB6d3A3C",
        depositAsset: {
            symbol: "OP",
            addr: "0x4200000000000000000000000000000000000042",
            decimal: 18,
        },
        shareAsset: {
            symbol: "HT-OP",
            addr: "0x4D9Eb6eF918471182b71B8D50fde90628ad4e5f8",
            decimal: 36,
        },
    },
    opUsdcQuoteVault: {
        ...ktbVaultMetasCommon.opUsdcQuoteVault,
        contractAddr: "0xCA405Fc29938042e664358ABd4b01Af2b18Fa5be",
        vaultConfigAddr: "0x9b7a8D8B8Fcc3473e9763438479cD4d250B9eFFb",
        showerRoomAddr: "0xCbFfD2Ea2AbdbCFcd70EB60feEED552651a83a75",
        depositAsset: {
            symbol: "USDC",
            addr: "0x7f5c764cbc14f9669b88837ca1490cca17c31607",
            decimal: 6,
        },
        shareAsset: {
            symbol: "HT-OP-USDC",
            addr: "0x10928354c00EF6574a1525E36219532845e1009b",
            decimal: 24,
        },
    },
    btcUsdcBaseVault: {
        ...ktbVaultMetasCommon.btcUsdcBaseVault,
        contractAddr: "0x6e9205B734455b578D06135de51E87D742d32cF2",
        vaultConfigAddr: "0x9b7a8D8B8Fcc3473e9763438479cD4d250B9eFFb",
        showerRoomAddr: "0xECF6d4BF378576D3084140B7535A7870722C2CA4",
        depositAsset: {
            symbol: "WBTC",
            addr: "0x68f180fcCe6836688e9084f035309E29Bf0A2095",
            decimal: 8,
        },
        shareAsset: {
            symbol: "HT-BTC",
            addr: "0x78Ef3a1Dfb1Cee9B62b55a96fc076F2Ad09D8B6D",
            decimal: 26,
        },
    },
    btcUsdcQuoteVault: {
        ...ktbVaultMetasCommon.btcUsdcQuoteVault,
        contractAddr: "0x42A87FdB6f165509c6d93EA928E2a57389d7F7A6",
        vaultConfigAddr: "0x9b7a8D8B8Fcc3473e9763438479cD4d250B9eFFb",
        showerRoomAddr: "0xFCDF06C521757eA23ec8441f5fE787f6d92B8081",
        depositAsset: {
            symbol: "USDC",
            addr: "0x7f5c764cbc14f9669b88837ca1490cca17c31607",
            decimal: 6,
        },
        shareAsset: {
            symbol: "HT-BTC-USDC",
            addr: "0x180137bb5E9f43334223E1429cfd54694BafB890",
            decimal: 24,
        },
    },
}

export const kantabanVaultMetasOp: Record<KantabanVaultId, KantabanVaultMeta> = {
    ethUsdcBaseVault: {
        ...ktbVaultMetasCommon.ethUsdcBaseVault,
        contractAddr: "0x722Ef09F933f09069257C68563B715486365B895",
        vaultConfigAddr: "0x5Da8E71EA120DE22447BC8aE339FE398c17178fA",
        showerRoomAddr: "0x2A6bf6182a372e3F2AC7f933E3f56FcB985ede69",
        depositAsset: {
            symbol: "WETH",
            addr: "0x4200000000000000000000000000000000000006",
            decimal: 18,
        },
        shareAsset: {
            symbol: "HT-ETH",
            addr: "0xf2c886623309c93b56537C8c4d4321813ef78439",
            decimal: 36,
        },
    },
    ethUsdcQuoteVault: {
        ...ktbVaultMetasCommon.ethUsdcQuoteVault,
        contractAddr: "0x748A38f4a430504Deb15fD5BB157dd466f8284B9",
        vaultConfigAddr: "0x5Da8E71EA120DE22447BC8aE339FE398c17178fA",
        showerRoomAddr: "0x5476B2638D9A466059E0C0886539fb56dFF36761",
        depositAsset: {
            symbol: "USDC",
            addr: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
            decimal: 6,
        },
        shareAsset: {
            symbol: "HT-ETH-USDC",
            addr: "0x83d6675FE072928132c1B98ca3647De2fA9c8d84",
            decimal: 24,
        },
    },
    opUsdcBaseVault: {
        ...ktbVaultMetasCommon.opUsdcBaseVault,
        contractAddr: "0x5039cDE9ed71256BE27A36440380F36cAD45d270",
        vaultConfigAddr: "0x5Da8E71EA120DE22447BC8aE339FE398c17178fA",
        showerRoomAddr: "0x9732540a00877FE08a8c5236C7A821864905FEb0",
        depositAsset: {
            symbol: "OP",
            addr: "0x4200000000000000000000000000000000000042",
            decimal: 18,
        },
        shareAsset: {
            symbol: "HT-OP",
            addr: "0xeF9d827408C52C0F87278e1068Ef652Ad7274356",
            decimal: 36,
        },
    },
    opUsdcQuoteVault: {
        ...ktbVaultMetasCommon.opUsdcQuoteVault,
        contractAddr: "0x7AF98C4edc50cd7EC18C8B28A34200a16f53F3a4",
        vaultConfigAddr: "0x5Da8E71EA120DE22447BC8aE339FE398c17178fA",
        showerRoomAddr: "0x8D9A3A205e13850945FB748A495cc130c865c8c1",
        depositAsset: {
            symbol: "USDC",
            addr: "0x7f5c764cbc14f9669b88837ca1490cca17c31607",
            decimal: 6,
        },
        shareAsset: {
            symbol: "HT-OP-USDC",
            addr: "0x0A8C0b205549589FEAdFB954282fBd187E541D83",
            decimal: 24,
        },
    },
    btcUsdcBaseVault: {
        ...ktbVaultMetasCommon.btcUsdcBaseVault,
        contractAddr: "0x6e9205B734455b578D06135de51E87D742d32cF2",
        vaultConfigAddr: "0x5Da8E71EA120DE22447BC8aE339FE398c17178fA",
        showerRoomAddr: "0xA67b1EC1F4b788421BbeaF9dfbfD1577484C2758",
        depositAsset: {
            symbol: "WBTC",
            addr: "0x68f180fcCe6836688e9084f035309E29Bf0A2095",
            decimal: 8,
        },
        shareAsset: {
            symbol: "HT-BTC",
            addr: "0x78Ef3a1Dfb1Cee9B62b55a96fc076F2Ad09D8B6D",
            decimal: 26,
        },
    },
    btcUsdcQuoteVault: {
        ...ktbVaultMetasCommon.btcUsdcQuoteVault,
        contractAddr: "0x243e5F2CedDf3C7098832bCB890eF70A34099415",
        vaultConfigAddr: "0x5Da8E71EA120DE22447BC8aE339FE398c17178fA",
        showerRoomAddr: "0xB158Ee3420000a65ac650724b8A16b39E338CeeE",
        depositAsset: {
            symbol: "USDC",
            addr: "0x7f5c764cbc14f9669b88837ca1490cca17c31607",
            decimal: 6,
        },
        shareAsset: {
            symbol: "HT-BTC-USDC",
            addr: "0xA825825F3C0E7D0e6413981ad940AfD8a8b1B976",
            decimal: 24,
        },
    },
}

/**************************************************************
 * since we possibly won't apply all contracts to all vaults, *
 * we need to list them here to make sure they are deployed   *
 **************************************************************/
export const kantabanVaultSupportList = isProduction()
    ? [
          KantabanVaultIdList.ethUsdcBaseVault,
          KantabanVaultIdList.ethUsdcQuoteVault,
          KantabanVaultIdList.opUsdcBaseVault,
          KantabanVaultIdList.opUsdcQuoteVault,
          KantabanVaultIdList.btcUsdcBaseVault,
          KantabanVaultIdList.btcUsdcQuoteVault,
      ]
    : [
          KantabanVaultIdList.ethUsdcBaseVault,
          KantabanVaultIdList.ethUsdcQuoteVault,
          KantabanVaultIdList.opUsdcBaseVault,
          KantabanVaultIdList.opUsdcQuoteVault,
          KantabanVaultIdList.btcUsdcBaseVault,
          KantabanVaultIdList.btcUsdcQuoteVault,
      ]
export const kantabanVaultMetas = isProduction() ? kantabanVaultMetasOp : kantabanVaultMetasOpTest
