import { Box, BrandButton, LucideIcon, Stack, Text } from "@sayaww/nomimono"
import Big from "big.js"
import clsx from "clsx"
import { motion } from "framer-motion"
import { useSetAtom } from "jotai"
import React, { useEffect, useState } from "react"
import { SubmitErrorHandler, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useParams } from "react-router-dom"
import { BaseInput } from "src/components/Input"
import { useHygraphStore } from "src/service/hygraph/hygraphStore"
import { useKantabanStore } from "src/service/kantaban/kantabanStore"
import { EventGroup, EventName, segmentService } from "src/service/segment/SegmentService"
import { openVaultBg } from "src/util/uiAtom"
import { useAccount, useBalance, useWalletClient } from "wagmi"
import { shallow } from "zustand/shallow"

const MotionStack = motion(Stack)

// For direct deposit
function Deposit() {
    // Define form
    // Form Document https://react-hook-form.com/get-started#Quickstart
    type FormData = {
        amount: string
    }
    const {
        setValue,
        setFocus,
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors, isSubmitting },
    } = useForm<FormData>({
        defaultValues: {
            amount: "",
        },
    })

    useEffect(() => {
        setFocus("amount")
    }, [setFocus])

    // Handle vaults and vault data from URL
    // get params from url
    const { vaultSlug } = useParams()

    // get icon url from hygraph
    const { vaultDetails, getVaultDetail } = useHygraphStore()
    useEffect(() => {
        if (!vaultSlug) {
            return
        }
        getVaultDetail(vaultSlug)
    }, [vaultSlug])
    const iconUrl = vaultSlug ? vaultDetails[vaultSlug]?.icon?.url : undefined

    // get all vault data
    const { vaults, deposit, approve, fetchVaults, isLoadingVaults } = useKantabanStore(
        state => ({
            isLoadingVaults: state.loading,
            vaults: state.vaults,
            deposit: state.deposit,
            approve: state.approve,
            fetchVaults: state.fetchVaults,
        }),
        shallow,
    )

    // get vault data by vaultId
    const vault = vaults.find(vault => vault.slug === vaultSlug)
    const vaultData = {
        minDepositAmount: vault?.minDepositAmount,
        vaultId: vault?.id,
        vaultName: vault?.displayName,
        totalShares: vault?.totalShares.toFixed(),
        totalShareValue: vault?.totalShareValue.toFixed(),
        shares: vault?.positionShares.toFixed(),
        shareValue: vault?.positionShareValue.toFixed(),
        allowance: vault?.allowance.toFixed(),
        displaySymbol: vault?.displaySubtitle,
        depositAsset: vault?.depositAsset,
        shareAsset: vault?.shareAsset,
        isWhitelisted: vault?.isWhitelistedLiquidityProvider,
    }

    // Handle form submit
    const setOpen = useSetAtom(openVaultBg)
    const { vaultId } = vaultData
    const handleDeposit = async (data: FormData) => {
        if (vaultId === undefined) {
            toast.error("vaultId is undefined")
            return
        }

        toast.loading("Depositing: " + data.amount + " " + vaultData.depositAsset?.symbol, {
            id: "depositing",
        })
        const txResult = await deposit(vaultId, Big(data.amount))

        if (txResult.data !== null) {
            setOpen(false)
            toast.dismiss("depositing")
            toast.success("Deposit successful")
            makeFormEditable()
        }

        if (txResult.error !== null) {
            makeFormEditable()
        }

        segmentService.track({
            eventGroup: EventGroup.INTERACTION,
            eventName: EventName.DEPOSIT,
            payload: { vaultId, ...data },
        })
    }
    const handleApprove = (data: FormData) => {
        if (vaultId === undefined) {
            toast.error("vaultId is undefined")
            return
        }
        approve(vaultId, Big(data.amount))
        toast.loading("Approving:  " + data.amount + " " + vaultData.depositAsset?.symbol, { id: "approving" })
        segmentService.track({
            eventGroup: EventGroup.INTERACTION,
            eventName: EventName.APPROVE,
            payload: { vaultId, ...data },
        })
    }

    // TODO: Handle form error. At least log to sentry
    const onError: SubmitErrorHandler<FormData> = (errors, e) => console.log(errors, e)

    const [disabled, setDisabled] = useState(false)

    // handle button disable states
    // check if allowance is enough
    const allowanceNotEnough = (vaultData.allowance && Big(vaultData.allowance).lt(Big(watch("amount") || 0))) || false

    useEffect(() => {
        if (allowanceNotEnough) {
            setDisabled(false)
        } else if (vaultData.allowance === "0") {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [vaultData.allowance, allowanceNotEnough])

    // show balance from wallet below the deposit input

    const { address } = useAccount()
    const { data } = useBalance({
        address: address,
        token: vaultData.depositAsset?.addr as `0x${string}`,
        chainId: 10,
    })

    // fetchVaults if switching wallets to get whitelisted status
    const { data: signer } = useWalletClient()
    useEffect(() => {
        fetchVaults()
    }, [fetchVaults, signer])

    const balance = Big(data?.formatted ? data.formatted : "0")

    // make form editable again (reset) after tx success or failed

    function makeFormEditable() {
        console.log("made editable")
        reset(
            { amount: "" },
            {
                keepValues: true,
            },
        )
    }
    return (
        <form onSubmit={handleSubmit(handleDeposit, onError)}>
            <MotionStack layout p="6" gap="4">
                <Text css={{ mb: 8 }} size="title3">
                    Deposit
                </Text>

                {/* form input */}
                <Box id="deposit" css={{ flex: 1, flexDirection: "column", gap: 4 }}>
                    <Box
                        as="label"
                        css={{
                            position: "relative",
                        }}
                    >
                        <Text
                            css={{
                                position: "absolute",
                                left: 16,
                                top: 16,
                                display: "inline-flex",
                                gap: 4,
                                borderRadius: "$xl",
                                bg: "black",
                                color: "white",
                                p: "$2",
                            }}
                            size="label"
                        >
                            {iconUrl && <img width={20} height={20} src={iconUrl} alt="token icon" />}{" "}
                            {vaultData.depositAsset?.symbol}
                        </Text>

                        <Box
                            css={{
                                position: "absolute",
                                left: 16,
                                bottom: 16,
                                display: "inline-flex",
                                gap: 4,
                            }}
                        >
                            <Text size="label">
                                Balance: {data ? Big(data.formatted).toFixed(7) : "—"} {data?.symbol}
                            </Text>
                            <Text
                                size="label"
                                css={{
                                    color: "#33C790",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    if (data === undefined) return
                                    setValue("amount", data.formatted)
                                }}
                            >
                                MAX
                            </Text>
                        </Box>
                        <BaseInput
                            css={{
                                width: "100%",
                                paddingLeft: 110,
                                paddingRight: 16,
                                paddingTop: 16,
                                paddingBottom: 60,
                            }}
                            step={"any"}
                            className={clsx(
                                // add a class to the input if there is an error
                                !!errors.amount && "error",
                            )}
                            {...register("amount", {
                                required: "This is required",

                                min: {
                                    // TODO: set min deposit amount here to avoid error, improve later
                                    value: vaultData.minDepositAmount || 0,
                                    message: `Minimum amount ${vaultData?.minDepositAmount} ${vaultData.depositAsset?.symbol}`,
                                },
                                validate: value => {
                                    return Big(value).lte(balance) || "Insufficient balance"
                                },
                            })}
                            type="number"
                            placeholder={`0.0`}
                        />
                    </Box>
                    <Text size="label" css={{ color: "$red9" }}>
                        {errors.amount?.message}
                    </Text>
                </Box>
                {vaultData.depositAsset?.symbol === "WETH" && (
                    <Text
                        as="a"
                        target="_blank"
                        css={{
                            fontSize: "$sm",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textDecoration: "none",
                            gap: "$3",
                            p: "$4",
                            pr: 32,
                            mt: "$4",
                            borderRadius: "$xl",
                            backgroundColor: "$mauve3",
                            color: "$mauve11",
                            transition: "$fast",
                            "&:hover": {
                                backgroundColor: "$blue4",
                                color: "$blue11",
                            },
                        }}
                        href={`https://jumper.exchange/?toChain=opt&toToken=${vaultData.depositAsset?.addr}`}
                    >
                        <Box>
                            The deposit asset is <span style={{ fontWeight: "bold" }}>Wrapped ETH (WETH)</span> instead
                            of <span style={{ fontWeight: "bold" }}>ETH</span>. Get WETH here.
                        </Box>
                        <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M17.25 8.25L21 12M21 12L17.25 15.75M21 12H3"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </Text>
                )}
                {/* form action */}
                <motion.div
                    layout
                    style={{
                        position: "relative",
                        overflow: "hidden",
                        width: "100%",
                    }}
                    variants={buttonSwitchVariants}
                    initial={"approve"}
                    animate={disabled ? "deposit" : "approve"}
                >
                    <motion.div variants={approveVariants}>
                        <BrandButton
                            css={{ width: "100%", justifyContent: "center" }}
                            brand={"normal"}
                            size="l"
                            disabled={disabled || isSubmitting || isLoadingVaults}
                            isLoading={isLoadingVaults || isSubmitting}
                            onClick={handleSubmit(handleApprove, onError)}
                        >
                            {!isLoadingVaults && "Approve"}
                        </BrandButton>
                    </motion.div>
                    <motion.div variants={depositVariants} style={{ width: "100%", position: "absolute" }}>
                        <BrandButton
                            css={{ width: "100%", justifyContent: "center" }}
                            brand={"normal"}
                            isLoading={isLoadingVaults || isSubmitting}
                            leftIcon={
                                !isLoadingVaults && (
                                    <motion.div
                                        animate={{
                                            y: [-2, 2],
                                        }}
                                        transition={{
                                            repeat: Infinity,
                                            repeatType: "reverse",
                                            duration: 0.8,
                                        }}
                                        style={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                            flexShrink: 0,
                                        }}
                                    >
                                        <LucideIcon size={24} name="arrow-down" />
                                    </motion.div>
                                )
                            }
                            size="l"
                            disabled={!disabled || isSubmitting}
                            type={"submit"}
                        >
                            {!isLoadingVaults && "Deposit"}
                        </BrandButton>
                    </motion.div>
                </motion.div>
            </MotionStack>
            {/* form helper message */}
            <Box
                css={{
                    background: disabled ? "#00DF7C" : "#FFB459",
                    mb: "-1px",
                }}
            >
                <Box
                    css={{
                        textAlign: "center",
                        p: "$4",
                        pb: "$3",
                    }}
                >
                    {allowanceNotEnough ? (
                        ""
                    ) : (
                        <Text as="span">
                            <Box
                                css={{
                                    display: "inline-block",
                                    verticalAlign: "text-top",
                                }}
                            >
                                <LucideIcon size={18} name="arrow-down-circle" />
                            </Box>{" "}
                            Approved{" "}
                            <Box as="span" css={{ fontWeight: "$bold" }}>
                                {vaultData.allowance} {vaultData.depositAsset?.symbol}
                            </Box>
                            .<Text as="span"> Ready to deposit.</Text>
                        </Text>
                    )}
                    {allowanceNotEnough ? (
                        <Text as="span">
                            <Box
                                css={{
                                    display: "inline-block",
                                    verticalAlign: "text-top",
                                }}
                            >
                                <LucideIcon size={20} name="verified" />
                            </Box>{" "}
                            Approve amount before depositing.
                        </Text>
                    ) : (
                        ""
                    )}
                </Box>
            </Box>
        </form>
    )
}

// animation
const depositVariants = {
    approve: {
        y: 0,
        opacity: 0,
    },
    deposit: {
        y: "-100%",
        opacity: 1,
    },
}
const approveVariants = {
    approve: {
        y: 0,
        opacity: 1,
    },
    deposit: {
        y: "-100%",
        opacity: 0,
    },
}
const buttonSwitchVariants = {
    approve: {},
    deposit: {},
}
export { Deposit }
