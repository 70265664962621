import { Provider } from "@ethersproject/providers"
import { getPublicClient, getWalletClient } from "@wagmi/core"
import { Signer, providers } from "ethers"
import { Cache } from "node-ts-cache"
import invariant from "tiny-invariant"
import { type HttpTransport } from "viem"
import { PublicClient, WalletClient } from "wagmi"
import { optimism } from "wagmi/chains"
import { RPC_URL } from "../../constants"
import { retryHttpGet } from "../../util/http"
import { isProduction } from "../../util/stage"
import { memoryCache } from "../cache/nodeTsCache"

type IProviderConfig = {
    name: string
    url: string
    wsUrl?: string
    blockNumber: number
    timestamp: number
    latency: number
}

type INodeMonitorInfo = Record<number, { providerConfigs: IProviderConfig[] }>

export class ProviderManager {
    private readonly chain = optimism
    private readonly RPC_MONITOR_URL = "https://s3.us-west-1.amazonaws.com/metadata.perp.fi/node-status/config.json"

    getChain() {
        return this.chain
    }

    @Cache(memoryCache, { ttl: 180 })
    async getRPCUrl(): Promise<string> {
        if (!isProduction()) {
            invariant(RPC_URL, "REACT_APP_RPC_URL in .env is not defined")
            return RPC_URL
        }
        try {
            const res = await retryHttpGet<INodeMonitorInfo>(this.RPC_MONITOR_URL)
            return this.getBestRPCUrl(res)
        } catch (e) {
            throw new Error(`Failed to fetch RPC URL: ${(e as Error).message}`)
        }
    }

    async getProvider(): Promise<Provider> {
        return this.publicClientToProvider(getPublicClient({ chainId: this.getChain().id }))
    }

    private publicClientToProvider(publicClient: PublicClient) {
        // https://wagmi.sh/react/ethers-adapters
        const { chain, transport } = publicClient
        const network = {
            chainId: chain.id,
            name: chain.name,
            ensAddress: chain.contracts?.ensRegistry?.address,
        }
        if (transport.type === "fallback")
            return new providers.FallbackProvider(
                (transport.transports as ReturnType<HttpTransport>[]).map(
                    ({ value }) => new providers.JsonRpcProvider(value?.url, network),
                ),
            )
        return new providers.JsonRpcProvider(transport.url, network)
    }

    private walletClientToSigner(walletClient: WalletClient) {
        // https://wagmi.sh/react/ethers-adapters
        const { account, chain, transport } = walletClient
        const network = {
            chainId: chain.id,
            name: chain.name,
            ensAddress: chain.contracts?.ensRegistry?.address,
        }
        const provider = new providers.Web3Provider(transport, network)
        return provider.getSigner(account.address)
    }

    async getSigner(): Promise<Signer | undefined> {
        const walletClient = await getWalletClient({ chainId: this.getChain().id })
        if (!walletClient) {
            return undefined
        }
        return this.walletClientToSigner(walletClient)
    }

    private getBestRPCUrl(nodeMonitorInfo: INodeMonitorInfo) {
        return nodeMonitorInfo[this.chain.id]?.providerConfigs[0]?.url
    }
}

export const providerManager = new ProviderManager()
