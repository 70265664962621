import { Result } from "@ethersproject/abi"
import { Contract, ContractInterface, Signer } from "ethers"
import { Multicall3, Multicall3__factory } from "../../typechain"
import { providerManager } from "../eth/ProviderManager"

export interface IMulticallParams {
    contractAddr: string
    abi: ContractInterface
    funcName: string
    funcParams: unknown[]
    signer?: Signer
}

export class MulticallClient {
    constructor(private readonly address: string) {}

    async tryAggregate(callParams: IMulticallParams[], requireSuccess = false): Promise<Result[]> {
        const multicall = await this.getMulticall()
        const callDataArr = await Promise.all(callParams.map(p => multicallClient.getCall(p)))
        // NOTE: require success = false, if one of the sub-call failed, it won't stop returning success data
        // NOTE: we must use callStatic here to prevent unexpected executions happened
        const callResultsRaw = await multicall.callStatic.tryAggregate(requireSuccess, callDataArr)
        const callResults: Result[] = []
        for (let i = 0; i < callResultsRaw.length; i++) {
            const callResultRaw = callResultsRaw[i]
            const callParam = callParams[i]
            callResults.push(
                await multicallClient.decodeCallResult(
                    callResultRaw.returnData,
                    callParam.contractAddr,
                    callParam.abi,
                    callParam.funcName,
                ),
            )
        }
        return callResults
    }

    async getMulticall(signer?: Signer): Promise<Multicall3> {
        const provider = await providerManager.getProvider()
        return Multicall3__factory.connect(this.address, signer ? signer : provider)
    }

    async getCall({
        contractAddr,
        abi,
        funcName,
        funcParams,
        signer,
    }: IMulticallParams): Promise<Multicall3.CallStruct> {
        const provider = await providerManager.getProvider()
        const contract = new Contract(contractAddr, abi, signer ? signer : provider)
        return {
            target: contractAddr,
            callData: contract.interface.encodeFunctionData(funcName, funcParams),
        }
    }

    // TODO: check if it can convert callStatic contract write correct, refer to test case: it("tryAggregate() should ignore the revert call")
    async decodeCallResult(
        callResult: string,
        contractAddr: string,
        abi: ContractInterface,
        funcName: string,
    ): Promise<Result> {
        const provider = await providerManager.getProvider()
        const contract = new Contract(contractAddr, abi, provider)
        return contract.interface.decodeFunctionResult(funcName, callResult)
    }
}

export const multicallClient = new MulticallClient("0xcA11bde05977b3631167028862bE2a173976CA11")
