import Big from "big.js"
import { ContractReceipt, Signer, VoidSigner } from "ethers"
import { big2BigNum, bigNum2Big } from "src/util/number"
import { ShowerRoom, ShowerRoom__factory } from "../../typechain"
import { logMethod } from "../../util/log"
import { providerManager } from "../eth/ProviderManager"
import { TheGraphClient, theGraphClient } from "../thegraph/TheGraphClient"
import type { KantabanVaultMeta } from "./KantabanVaultMeta"

export class ShowerRoomClient {
    constructor(private readonly theGraphClient: TheGraphClient) {}

    @logMethod
    async deposit(vaultMeta: KantabanVaultMeta, amount: Big, signer: Signer): Promise<ContractReceipt> {
        const showerRoom = await this.getShowerRoom(vaultMeta.showerRoomAddr, signer)
        const tx = await showerRoom.deposit(big2BigNum(amount, vaultMeta.depositAsset.decimal))
        return tx.wait()
    }

    @logMethod
    async withdrawAll(vaultMeta: KantabanVaultMeta, signer: Signer): Promise<ContractReceipt> {
        const showerRoom = await this.getShowerRoom(vaultMeta.showerRoomAddr, signer)
        const tx = await showerRoom.withdrawAll()
        return tx.wait()
    }

    @logMethod
    async getWaitingListLength(vaultMeta: KantabanVaultMeta) {
        const nextDepositIndex = await this.getNextDepositIndex(vaultMeta)
        const waitingList = await this.getWaitingList(nextDepositIndex, vaultMeta)
        return waitingList.length
    }

    @logMethod
    async getNextDepositIndex(vaultMeta: KantabanVaultMeta) {
        const showerRoom = await this.getShowerRoom(vaultMeta.showerRoomAddr)
        const nextDepositIndex = await showerRoom.callStatic.getNextDepositIndex()
        return nextDepositIndex.toNumber()
    }

    @logMethod
    async getWaitingList(index: number, vaultMeta: KantabanVaultMeta) {
        const addToWaitLists = await this.theGraphClient.getAddToWaitLists(index, vaultMeta.showerRoomAddr)
        if (addToWaitLists.length === 0) {
            return []
        }
        const timestamp = addToWaitLists[0].timestamp
        const withdrawLists = await this.theGraphClient.getWithdrawLists(Number(timestamp), vaultMeta.showerRoomAddr)
        const addToWaitListsMap = new Map()
        for (const it of addToWaitLists) {
            addToWaitListsMap.set(it.sender, it)
        }
        for (const it of withdrawLists) {
            if (addToWaitListsMap.has(it.account)) {
                if (addToWaitListsMap.get(it.account).timestamp <= it.timestamp) {
                    addToWaitListsMap.delete(it.account)
                }
            }
        }
        return Array.from(addToWaitListsMap.values())
            .sort((a, b) => a.timestamp - b.timestamp)
            .map(it => it.sender)
    }

    @logMethod
    async getMaxDepositPerUser(vaultMeta: KantabanVaultMeta) {
        const showerRoom = await this.getShowerRoom(vaultMeta.showerRoomAddr)
        const maxDepositPerUser = await showerRoom.callStatic.getMaxDepositPerUser()
        return bigNum2Big(maxDepositPerUser, vaultMeta.depositAsset.decimal)
    }

    @logMethod
    async getUserWaitListIndex(vaultMeta: KantabanVaultMeta, account: string) {
        const nextDepositIndex = await this.getNextDepositIndex(vaultMeta)
        const waitingList = await this.getWaitingList(nextDepositIndex, vaultMeta)
        // NOTE: the address data return from theGraphClient are lowercase
        return waitingList.indexOf(account.toLocaleLowerCase())
    }

    @logMethod
    async getUserTotalAssetWithdrawn(vaultMeta: KantabanVaultMeta, signer: Signer) {
        const showerRoom = await this.getShowerRoom(vaultMeta.showerRoomAddr, signer, true)
        const getUserTotalAssetWithdrawn = await showerRoom.callStatic.withdrawAll()
        return bigNum2Big(getUserTotalAssetWithdrawn, vaultMeta.depositAsset.decimal)
    }

    private async getShowerRoom(contractAddr: string, signer?: Signer, forCallStatic = false): Promise<ShowerRoom> {
        const provider = await providerManager.getProvider()
        if (signer) {
            if (forCallStatic) {
                const address = await signer.getAddress()
                return ShowerRoom__factory.connect(contractAddr, new VoidSigner(address, provider))
            }
            return ShowerRoom__factory.connect(contractAddr, signer)
        }
        return ShowerRoom__factory.connect(contractAddr, provider)
    }
}

export const showerRoomClient = new ShowerRoomClient(theGraphClient)
