import { Box, IconButton, Text } from "@sayaww/nomimono"
import { Squircle } from "corner-smoothing"
import React from "react"
import { AprChart } from "src/components/AprChart"
import { Stat } from "src/components/Stat"
import { MotionBox } from "../pages/VaultDetail/VaultDetail"

export const FullChartOriginal = (props: {
    showFullChart: boolean
    allTimeApr?: string
    sevenDayApr?: string
    thirtyDayApr?: string
    setShowFullChart: React.Dispatch<React.SetStateAction<boolean>>
}): JSX.Element => {
    return (
        <MotionBox
            style={{
                overflow: "hidden",
                width: "100%",
            }}
            initial={{
                opacity: 0,
                height: 0,
                scaleY: 0.8,
                scaleX: 0.8,
            }}
            animate={{
                opacity: props.showFullChart ? 1 : 0,
                height: props.showFullChart ? "auto" : 0,
                scaleY: props.showFullChart ? 1 : 0.8,
                scaleX: props.showFullChart ? 1 : 0.8,
            }}
            transition={{
                type: "linear",
                mass: 0.35,
                stiffness: 150,
            }}
        >
            <Squircle
                cornerRadius={30}
                cornerSmoothing={0.6}
                style={{
                    width: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: 1,
                    backgroundColor: "white",
                    position: "relative",
                }}
            >
                <Box
                    css={{
                        position: "absolute",
                        top: "$4",
                        right: "$4",
                        zIndex: "32",
                    }}
                >
                    <IconButton
                        onClick={() => {
                            props.setShowFullChart(false)
                        }}
                        style={{ borderRadius: "9999px" }}
                        size="md"
                        tone={"slate"}
                        intent="secondary"
                        name="x"
                    />
                </Box>
                <Box
                    css={{
                        width: "100%",
                        p: 32,
                    }}
                >
                    <Text
                        css={{
                            lineHeight: 1,
                        }}
                        size={"title2"}
                    >
                        Performance <br />
                        <Text css={{ lineHeight: 2, fontWeight: "normal", color: "$slate9" }} size="label">
                            Over the last 30 days
                        </Text>
                    </Text>
                </Box>
                <AprChart />
                <Box
                    css={{
                        width: "100%",
                        p: 32,
                    }}
                >
                    <Box
                        css={{
                            paddingTop: 20,
                            display: "block",
                            "@sm": {
                                display: "flex",
                                justifyContent: "stretch",
                                flexDirection: "row",
                            },
                        }}
                    >
                        <div style={{ flex: 1 }}>
                            <Stat
                                labelColor="#00DF7C"
                                label={"Latest 30-day APY"}
                                value={props.thirtyDayApr}
                                subValue="%"
                                tooltip="APY over the past 30 days"
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <Stat
                                labelColor="#6C78F4"
                                label={"Latest All-time APY"}
                                value={props.allTimeApr}
                                subValue="%"
                                tooltip="APY all-time"
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <Stat
                                label={"Latest 7-day APY"}
                                value={props.sevenDayApr}
                                subValue="%"
                                tooltip="APY over the past 7 days"
                            />
                        </div>
                    </Box>
                </Box>
            </Squircle>
        </MotionBox>
    )
}
