import Big from "big.js"
import { mapValues } from "lodash"
import { Cache } from "node-ts-cache"
import { retryHttpGet } from "../../util/http"
import { logMethod } from "../../util/log"
import { memoryCache } from "../cache/nodeTsCache"

export type CoingeckoId = "ethereum" | "bitcoin" | "optimism" | "usd-coin"

export type CoingeckoPriceMap = { [id: string]: Big }

interface CoingeckoResponse {
    [id: string]: { usd: number }
}

export class CoingeckoClient {
    @logMethod
    @Cache(memoryCache, { ttl: 180 })
    async getPrices(ids: CoingeckoId[]): Promise<CoingeckoPriceMap> {
        try {
            const res = await retryHttpGet<CoingeckoResponse>(
                `https://api.coingecko.com/api/v3/simple/price?ids=${ids}&vs_currencies=usd`,
            )
            return mapValues(res, it => new Big(it.usd))
        } catch (e) {
            throw new Error(`Failed to fetch ${ids} prices from coingecko: ${(e as Error).message}`)
        }
    }
}

export const coingeckoClient = new CoingeckoClient()
