import { Cache } from "node-ts-cache"
import { retryHttpGet } from "../../util/http"
import { logMethod } from "../../util/log"
import { memoryCache } from "../cache/nodeTsCache"

export interface IGeolocation {
    isBlocked: boolean
    countryCode: string
}

export class GeolocationClient {
    private readonly endpoint = "https://api.perp.exchange/geolocation-block"

    @logMethod
    @Cache(memoryCache, { ttl: 180 })
    async getGeolocation() {
        try {
            return retryHttpGet<IGeolocation>(this.endpoint)
        } catch (e) {
            throw new Error(`Failed to fetch geolocation: ${(e as Error).message}`)
        }
    }
}

export const geolocationClient = new GeolocationClient()
