import { useEffect, useMemo } from "react"
import { configureChains, createConfig } from "wagmi"
import { jsonRpcProvider } from "wagmi/providers/jsonRpc"
import { providerManager } from "../eth/ProviderManager"
import { useProviderStore } from "../eth/providerStore"
import { getDefaultConfig } from "connectkit"

export type WagmiClient = ReturnType<typeof createWagmiClient>

function createWagmiClient(rpcUrl: string, appName: string) {
    const chain = providerManager.getChain()
    const { chains, publicClient, webSocketPublicClient } = configureChains(
        [chain],
        [
            jsonRpcProvider({
                rpc: () => ({
                    http: rpcUrl,
                }),
            }),
        ],
    )
    return createConfig(
        getDefaultConfig({
            appName,
            walletConnectProjectId: "017fb30c4a4c2cc7a9621738869c0214",
            autoConnect: true,
            chains,
            publicClient,
            webSocketPublicClient,
        }),
    )
}

export const useWagmi = (appName: string): WagmiClient | undefined => {
    const { rpcUrl, fetch } = useProviderStore()
    useEffect(() => {
        fetch()
    }, [fetch])
    return useMemo(() => {
        if (!rpcUrl) {
            return undefined
        }
        return createWagmiClient(rpcUrl, appName)
    }, [appName, rpcUrl])
}
