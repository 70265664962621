import { Box } from "@sayaww/nomimono"
import { useFlags } from "flagsmith/react"
import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { EventGroup, EventName, segmentService } from "src/service/segment/SegmentService"
import { Deposit } from "./Deposit"
import { Queue } from "./Queue"
import { useVaultDetail } from "./useVaultDetail"

/**
 * This function determines whether to deposit or queue based on the capacity of the Hot Tub.
 * If the Hot Tub is nearing its capacity, it enters the Shower Room mode and shows the Queue button.
 */

function DepositOrQueue() {
    // Get maxDepositAmount
    const { vaultSlug } = useParams()
    const { shouldGoToShowerRoom } = useVaultDetail(vaultSlug)

    // TODO: [ShowerRoom] update isShowerRoomEnabled when flagsmith service is back
    // Feature flag
    const flags = useFlags(["shower_room"])
    const isShowerRoomEnabled = true || flags.shower_room.enabled

    // Track what users see: shower room or hot tub
    useEffect(() => {
        if (shouldGoToShowerRoom)
            segmentService.track({
                eventGroup: EventGroup.INTERACTION,
                eventName: EventName.GO_TO_SHOWER_ROOM,
                description: "nearing capacity so users go to shower room",
            })
        else
            segmentService.track({
                eventGroup: EventGroup.INTERACTION,
                eventName: EventName.GO_TO_HOTTUB,
                description: "capacity available so users go to hot tub",
            })
    }, [shouldGoToShowerRoom])

    return (
        <Box>
            {/* If depositAnyway is true, always show Deposit. Otherwise, if shower room is enabled and near capacity, determine if we should show Queue or Direct Deposit */}
            {isShowerRoomEnabled && shouldGoToShowerRoom ? <Queue /> : <Deposit />}
            {/* Show deposit anyway (skip queue) */}
        </Box>
    )
}

export default DepositOrQueue
