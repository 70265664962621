import { zodResolver } from "@hookform/resolvers/zod"
import { Box, BrandButton, HeroIcon, LucideIcon, Stack, Text } from "@sayaww/nomimono"
import Big from "big.js"
import clsx from "clsx"
import { BigNumber } from "ethers"
import { motion } from "framer-motion"
import { useSetAtom } from "jotai/react"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useParams } from "react-router-dom"
import { BaseInput } from "src/components/Input"
import { QueueDetails } from "src/components/QueueDetails"
import { PeopleIcon } from "src/components/QueueStatus"
import { useNotifStore } from "src/service/notif/notifStore"
import { EventGroup, EventName, segmentService } from "src/service/segment/SegmentService"
import { openQueueStatus, openVaultBg } from "src/util/uiAtom"
import { useAccount, useBalance } from "wagmi"
import { z } from "zod"
import { shallow } from "zustand/shallow"
import { useVaultDetail } from "./useVaultDetail"

// TODO: If you import { BNToBig } from "@perp/frontend-shared/lib/util/bn" it
// will explode.
export function BNToBig(n: BigNumber): Big {
    return Big(n.toString())
}

const MotionBox = motion(Box)
const MotionStack = motion(Stack)

// For queue
function Queue() {
    // Fetch all kinds of data
    const { vaultSlug } = useParams()
    const vaultData = useVaultDetail(vaultSlug)
    const iconUrl = vaultData.vaultCMS.icon.url
    const depositAssetAddress = vaultData.vault?.depositAsset.addr
    const depositAssetSymbol = vaultData.vault?.depositAsset.symbol
    const maxDepositPerUser = vaultData.vault?.srMaxDepositPerUser
    const waitingListLength = vaultData.vault?.srWaitingListLength
    const myWaitingAmount = vaultData.vault?.srMyWaitingAmount

    const vaultId = vaultData.vault?.id
    const showerRoomAllowance = vaultData.vault?.srAllowance

    // User's balance in wallet
    const { address } = useAccount()
    const { data } = useBalance({
        address: address,
        chainId: 10,
        token: depositAssetAddress as `0x${string}`,
        watch: true,
    })
    const userBalance = data?.formatted
    const userBalanceBig = data ? Big(data.value.toString()) : undefined

    //Approve and deposit
    const { approveShowerRoom, depositToShowerRoom, loading } = useVaultDetail(vaultSlug)
    const setOpenQueueStatus = useSetAtom(openQueueStatus)
    const setVaultBgOpen = useSetAtom(openVaultBg)

    const approve = (amount: string) => {
        const approveAmount = Big(amount)

        if (!vaultId) {
            toast.error(`Vault id is not available`)
            return
        }
        approveShowerRoom(vaultId, approveAmount)

        segmentService.track({
            eventGroup: EventGroup.INTERACTION,
            eventName: EventName.APPROVE_SHOWER_ROOM,
            payload: {
                vaultId: vaultId,
                amount: approveAmount.toString(),
            },
        })

        toast(`Approving ${amount} ${depositAssetSymbol}...`)
    }
    const deposit = async (amount: string) => {
        const depositAmount = Big(amount)

        if (!vaultId) {
            toast.error(`Vault is not available`)
            return
        }
        toast.loading("Adding to the queue...", {
            id: "adding",
        })
        const txResult = await depositToShowerRoom(vaultId, depositAmount)

        if (txResult.data !== null) {
            toast.dismiss("adding")
            setVaultBgOpen(false)
            setOpenQueueStatus(true)
            toast.success("Successfully added to the queue")
        }

        segmentService.track({
            eventGroup: EventGroup.INTERACTION,
            eventName: EventName.DEPOSIT_SHOWER_ROOM,
            payload: {
                vaultId: vaultId,
                amount: depositAmount.toString(),
            },
        })

        toast(`Adding ${amount} ${depositAssetSymbol} to the queue...`)
        // toast(`Successfully added to queue...(add amount here)`)
    }

    // Form data
    // Form validation and parsing with Zod

    // Handle Approve and deposit button change

    const schema = z.object({
        amount: z.coerce
            .number()
            .refine(value => userBalanceBig !== undefined && value <= userBalanceBig.toNumber(), "Insufficient balance")
            .refine(value => value > 0, "Amount must be greater than 0")
            .default(0),
    })

    const {
        register,
        handleSubmit,
        setValue,
        setFocus,
        watch,
        reset,
        formState: { errors, isSubmitSuccessful },
    } = useForm({
        defaultValues: {
            amount: "0",
        },
        resolver: zodResolver(schema),
    })
    const [isReadyForDeposit, setIsReadyForDeposit] = useState(false)
    const userInputAmount = watch("amount") || 0

    useEffect(() => {
        if (!!showerRoomAllowance && showerRoomAllowance.gt(0) && showerRoomAllowance.gte(userInputAmount)) {
            setIsReadyForDeposit(true)
        } else {
            setIsReadyForDeposit(false)
        }
    }, [showerRoomAllowance, userInputAmount])

    useEffect(() => {
        setFocus("amount", { shouldSelect: true })
    }, [setFocus])

    if (Object.keys(errors).length !== 0) {
        console.log(errors)
    }
    // make form editable again (reset) after tx success or failed
    const { status } = useNotifStore(
        state => ({
            status: state.status,
        }),
        shallow,
    )
    useEffect(() => {
        if (status === "success" || status === "error") {
            reset(
                { amount: "" },
                {
                    keepValues: true,
                    keepIsSubmitted: false,
                },
            )
        }
    }, [reset, status])

    return (
        <Box>
            <form
                onSubmit={handleSubmit(data => {
                    if (isReadyForDeposit) {
                        deposit(data.amount)
                    } else {
                        approve(data.amount)
                    }
                })}
            >
                <MotionStack layout p="6" gap="4">
                    <Text css={{ mb: 8 }} size="title3">
                        Queue
                    </Text>
                    <Text
                        css={{
                            color: "$gray10",

                            fontSize: "$sm",
                        }}
                    >
                        This vault is nearing its capacity and your deposit amount will be added to a queue.
                    </Text>

                    {/* form input */}
                    <Box css={{ flex: 1, flexDirection: "column", gap: 4 }}>
                        <Box
                            as="label"
                            css={{
                                position: "relative",
                            }}
                        >
                            <Text
                                css={{
                                    position: "absolute",
                                    left: 16,
                                    top: 16,
                                    display: "inline-flex",
                                    gap: 4,
                                    borderRadius: "$xl",
                                    bg: "black",
                                    color: "white",
                                    p: "$2",
                                }}
                                size="label"
                            >
                                {iconUrl && <img width={20} height={20} src={iconUrl} alt="token icon" />}{" "}
                                {depositAssetSymbol}
                            </Text>

                            <Box
                                css={{
                                    position: "absolute",
                                    left: 16,
                                    bottom: 16,
                                    display: "inline-flex",
                                    gap: 4,
                                }}
                            >
                                <Text size="label">Balance: {userBalance}</Text>
                                <Text
                                    size="label"
                                    css={{
                                        color: "#33C790",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        if (userBalance === undefined) return
                                        setValue("amount", userBalance)
                                    }}
                                >
                                    MAX
                                </Text>
                            </Box>

                            <BaseInput
                                css={{
                                    width: "100%",
                                    paddingLeft: 110,
                                    paddingRight: 16,
                                    paddingTop: 16,
                                    paddingBottom: 60,
                                }}
                                disabled={isSubmitSuccessful}
                                step={"any"}
                                className={clsx(!!errors.amount && "error")}
                                min={0}
                                type="number"
                                placeholder={"0.0"}
                                {...register("amount")}
                            />
                        </Box>
                        <Text size="label" css={{ color: "$red9" }}>
                            {errors.amount?.message}
                        </Text>
                    </Box>
                    <ExisitingWaitingAmount
                        userInputAmount={Math.round(Number(userInputAmount) * 100000) / 100000 || 0}
                        myWaitingAmount={myWaitingAmount ? Number(myWaitingAmount.toFixed(5)) : 0}
                        depositAssetSymbol={depositAssetSymbol || "—"}
                    />
                    <CapacityDescription
                        depositAssetSymbol={depositAssetSymbol ?? ""}
                        waitingListLength={waitingListLength ?? 0}
                        maxDepositPerUser={maxDepositPerUser?.toFixed(0) ?? "0"}
                    />
                    {/* form action */}
                    <motion.div
                        layout
                        style={{
                            position: "relative",
                            overflow: "hidden",
                            width: "100%",
                        }}
                        variants={buttonSwitchVariants}
                        initial={"approve"}
                        animate={isReadyForDeposit ? "deposit" : "approve"}
                    >
                        <motion.div variants={approveVariants}>
                            <BrandButton
                                disabled={loading || isSubmitSuccessful}
                                isLoading={isSubmitSuccessful}
                                type="submit"
                                css={{ width: "100%", justifyContent: "center" }}
                                brand={"normal"}
                                size="l"
                            >
                                Approve
                            </BrandButton>
                        </motion.div>
                        <motion.div variants={depositVariants} style={{ width: "100%", position: "absolute" }}>
                            <BrandButton
                                leftIcon={
                                    <motion.div
                                        animate={{
                                            y: [-2, 2],
                                        }}
                                        transition={{
                                            repeat: Infinity,
                                            repeatType: "reverse",
                                            duration: 0.8,
                                        }}
                                        style={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                            flexShrink: 0,
                                        }}
                                    >
                                        <LucideIcon size={24} name="arrow-down" />
                                    </motion.div>
                                }
                                disabled={loading || isSubmitSuccessful}
                                isLoading={isSubmitSuccessful}
                                css={{
                                    width: "100%",
                                    justifyContent: "center",
                                    // bg: "$indigo9",
                                    // "&:hover": {
                                    //     bg: "$indigo10",
                                    // },
                                }}
                                brand={"normal"}
                                size="l"
                                type={"submit"}
                            >
                                Add to Queue
                            </BrandButton>
                        </motion.div>
                    </motion.div>
                </MotionStack>
                {/* form helper message */}
                <Box
                    css={{
                        background: isReadyForDeposit ? "#00DF7C" : "#FFB459",
                        mb: "-1px",
                    }}
                >
                    <Box
                        css={{
                            textAlign: "center",
                            p: "$4",
                            gap: "$1",
                            pb: "$3",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {!isReadyForDeposit ? (
                            <>
                                <HeroIcon name="check-badge" />
                                <Text>Approve the deposit amount</Text>
                            </>
                        ) : (
                            <>
                                <HeroIcon name="user-plus" />
                                <Text>
                                    Ready to add{" "}
                                    <strong>
                                        {userInputAmount} {depositAssetSymbol}
                                    </strong>{" "}
                                    to the queue
                                </Text>
                            </>
                        )}
                    </Box>
                </Box>
            </form>
        </Box>
    )
}

// animation
const depositVariants = {
    approve: {
        y: 0,
        opacity: 0,
    },
    deposit: {
        y: "-100%",
        opacity: 1,
    },
}
const approveVariants = {
    approve: {
        y: 0,
        opacity: 1,
    },
    deposit: {
        y: "-100%",
        opacity: 0,
    },
}
const buttonSwitchVariants = {
    approve: {},
    deposit: {},
}

const CapacityDescription = ({
    waitingListLength,
    maxDepositPerUser,
    depositAssetSymbol,
}: {
    waitingListLength: number
    maxDepositPerUser: string
    depositAssetSymbol: string
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const yourPositionInQueue = (waitingListLength ?? 0) + 1

    return (
        <MotionBox
            layout
            css={{
                fontSize: "$sm",
                textDecoration: "none",
                p: "$4",
                mt: "$4",
                borderRadius: "$xl",
                backgroundColor: "$mauve3",
                color: "$mauve12",
                transition: "$fast",
            }}
        >
            <Text
                css={{ cursor: "pointer", flex: 1, alignItems: "center" }}
                onClick={() => setIsOpen(!isOpen)}
                size="subtitle2"
            >
                <PeopleIcon />
                <Box css={{ pl: "$2" }}>Your queue position will be: {yourPositionInQueue}</Box>
                <div style={{ flex: 1 }} />
                {isOpen ? <LucideIcon size={20} name="chevron-up" /> : <LucideIcon size={20} name="chevron-down" />}
            </Text>
            <MotionBox
                initial={{
                    height: 0,
                    opacity: 0,
                }}
                animate={{
                    height: isOpen ? "auto" : 0,
                    opacity: isOpen ? 1 : 0,
                }}
            >
                <Text css={{ fontSize: 14, pt: 16, ml: "$4" }}>
                    <QueueDetails maxDepositPerUser={maxDepositPerUser} depositAssetSymbol={depositAssetSymbol} />
                </Text>
            </MotionBox>
        </MotionBox>
    )
}

const ExisitingWaitingAmount = ({
    userInputAmount,
    myWaitingAmount,
    depositAssetSymbol,
}: {
    userInputAmount: number
    myWaitingAmount: number
    depositAssetSymbol: string
}) => {
    if (myWaitingAmount === 0) return null
    return (
        <MotionBox
            layout
            css={{
                textDecoration: "none",
                p: "$4",
                mt: "$4",
                borderRadius: "$xl",
                backgroundColor: "$orange3",
                color: "$mauve12",
                transition: "$fast",
            }}
        >
            <Text size={"label"}>
                Amount in queue:
                <br />
                <strong>
                    {myWaitingAmount} {depositAssetSymbol} →<br /> {userInputAmount + myWaitingAmount}{" "}
                    {depositAssetSymbol}
                </strong>
                .
            </Text>
        </MotionBox>
    )
}

export { Queue }
