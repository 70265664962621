"use client"
import React, { useEffect } from "react"
import toast from "react-hot-toast"
import { useConnect, useDisconnect } from "wagmi"
import { shallow } from "zustand/shallow"
import { useKantabanStore } from "../service/kantaban/kantabanStore"
import { useNotifStore } from "../service/notif/notifStore"
import { EtherscanLink } from "./EtherscanLink"

export function Notifications() {
    const { error: connectError } = useConnect()
    const { error: disconnectError } = useDisconnect()
    const { isLoadingVaults } = useKantabanStore(
        state => ({
            isLoadingVaults: state.loading,
        }),
        shallow,
    )
    const { isOpen, status, message, close, hash } = useNotifStore(
        state => ({
            status: state.status,
            message: state.message,
            isOpen: state.isOpen,
            close: state.close,
            hash: state.hash,
        }),
        shallow,
    )

    useEffect(() => {
        if (!isOpen) {
            return
        }

        const duration = status === "success" ? 3 * 1000 : 5 * 1000
        toast.dismiss("loading")
        toast.dismiss("approving")
        toast.dismiss("depositing")
        toast.dismiss("withdrawing")
        if (hash) {
            if (status === "success") {
                toast.success(<EtherscanLink hash={hash}>{message}</EtherscanLink>, { duration })
            }

            if (status === "error") {
                toast.error(<EtherscanLink hash={hash}>{message}</EtherscanLink>, { duration })
            }
        } else {
            if (status === "success") {
                toast.success(message, { duration })
            }
            if (status === "error") {
                toast.error(message, { duration })
            }
        }
        // NOTE: clean up the outdated information(ex. txHash) in store
        close()
    }, [isOpen, status, message, hash, close])

    useEffect(() => {
        if (isLoadingVaults) {
            toast.loading("Syncing...", { id: "loading" })
        } else {
            toast.dismiss("loading")
        }
    }, [isLoadingVaults])

    // TODO: remove this after confirm with @leo
    // useEffect(() => {
    //     if (isLoading) {
    //         toast("Debug: wallet loading")
    //     }
    // }, [isLoading])

    useEffect(() => {
        if (disconnectError) {
            toast.error(disconnectError.message)
        }
        if (connectError) {
            toast.error(connectError.message)
        }
    }, [disconnectError, connectError])

    return null
}
