import Big from "big.js"
import { ContractReceipt } from "ethers"
import { StateCreator, create } from "zustand"
import { devtools } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"
import { providerManager } from "../eth/ProviderManager"
import { notifyError } from "../notif/notifHelper"
import { useNotifStore } from "../notif/notifStore"
import { EventGroup, EventName, EventTypeContract, segmentService } from "../segment/SegmentService"
import { LoadableState, ZustandGet, ZustandLoadingGuardSequence, ZustandSet } from "../zustand/ZustandLoadingGuard"
import { KantabanVault } from "./KantabanVault"
import { KantabanVaultDataMap, kantabanVaultManager } from "./KantabanVaultManager"

interface ITxActionRes {
    data: { receipt: ContractReceipt } | null
    error: Error | null
}

export interface KantabanState extends LoadableState {
    vaults: KantabanVault[]
    fetchVaults: () => Promise<void>
    approve: (vaultId: string, amount: Big) => Promise<ITxActionRes>
    deposit: (vaultId: string, amount: Big) => Promise<ITxActionRes>
    redeem: (vaultId: string, shares: Big, slippage: Big) => Promise<ITxActionRes>
    previewRedeem: (vaultId: string, shares: Big, slippage: Big) => Promise<Big | undefined>
    approveShowerRoom: (vaultId: string, amount: Big) => Promise<ITxActionRes>
    depositToShowerRoom: (vaultId: string, amount: Big) => Promise<ITxActionRes>
    withdrawFromShowerRoom: (vaultId: string) => Promise<ITxActionRes>
}

export const initKantabanState = {
    loading: false,
    vaults: kantabanVaultManager.listVaultMetas().map(vault => ({
        ...vault,
        positionShares: Big(0),
        positionShareValue: Big(0),
        allowance: Big(0),
        showerRoomAllowance: Big(0),
        totalShares: Big(0),
        totalShareValue: Big(0),
        sharePrice: Big(0),
        remainingDepositAmount: Big(0),
        isWhitelistedLiquidityProvider: false,
        totalSupplyCap: Big(0),
        lastMintedAt: undefined,
        transferCooldown: 0,
        srMyWaitingIndex: -1,
        srMyWaitingAmount: Big(0),
        srWaitingListLength: 0,
        srMaxDepositPerUser: Big(0),
        srAllowance: Big(0),
        shouldGoToShowerRoom: false,
    })),
}

function updateVaultDatas(state: KantabanState, newVaultDataMap: KantabanVaultDataMap) {
    const { vaults } = state
    for (const vault of vaults) {
        const newVaultData = newVaultDataMap[vault.id]
        if (newVaultData) {
            Object.assign(vault, newVaultData)
        }
    }
}
async function fetchVaults(get: ZustandGet<KantabanState>, set: ZustandSet<KantabanState>) {
    try {
        const signer = await providerManager.getSigner()
        const newVaults = await kantabanVaultManager.fetchVaultDataMap(signer)
        set(state => {
            updateVaultDatas(state, newVaults)
        })
    } catch (e) {
        notifyError(e, true)
    }
}

async function approve(get: ZustandGet<KantabanState>, set: ZustandSet<KantabanState>, vaultId: string, amount: Big) {
    try {
        const signer = await providerManager.getSigner()
        if (!signer) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error("Wallet is not connected yet.")
        }
        const { vaultData, receipt } = await kantabanVaultManager.approve(vaultId, amount, signer)
        set(state => {
            updateVaultDatas(state, {
                [vaultId]: vaultData,
            })
        })
        const { openSuccess } = useNotifStore.getState()
        const depositAsset = kantabanVaultManager.getDepositAssetByVaultId(vaultId)
        openSuccess(`Approved ${amount} ${depositAsset.symbol}. Ready to deposit.`, undefined, receipt.transactionHash)

        segmentService.track({
            eventGroup: EventGroup.CONTRACT,
            eventType: EventTypeContract.TX_SUCCEEDED,
            eventName: EventName.APPROVE,
            payload: {
                vaultId: vaultId,
                transactionId: receipt.transactionHash,
            },
        })

        return {
            data: { receipt },
            error: null,
        }
    } catch (e) {
        segmentService.track({
            eventGroup: EventGroup.CONTRACT,
            eventType: EventTypeContract.TX_FAILED,
            eventName: EventName.APPROVE,
            payload: {
                vaultId: vaultId,
            },
        })
        notifyError(e, true)
        return {
            data: null,
            error: e as Error,
        }
    }
}

async function deposit(get: ZustandGet<KantabanState>, set: ZustandSet<KantabanState>, vaultId: string, amount: Big) {
    try {
        const signer = await providerManager.getSigner()
        if (!signer) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error("Wallet is not connected yet.")
        }
        const { vaultData, receipt } = await kantabanVaultManager.deposit(vaultId, amount, signer)
        set(state => {
            updateVaultDatas(state, {
                [vaultId]: vaultData,
            })
        })
        const { openSuccess } = useNotifStore.getState()
        const depositAsset = kantabanVaultManager.getDepositAssetByVaultId(vaultId)
        openSuccess(`Successfully deposited ${amount} ${depositAsset.symbol}`, undefined, receipt.transactionHash)

        segmentService.track({
            eventGroup: EventGroup.CONTRACT,
            eventType: EventTypeContract.TX_SUCCEEDED,
            eventName: EventName.DEPOSIT,
            payload: {
                vaultId: vaultId,
                amount: amount.toFixed(),
                transactionId: receipt.transactionHash,
            },
        })
        return {
            data: { receipt },
            error: null,
        }
    } catch (e) {
        segmentService.track({
            eventGroup: EventGroup.CONTRACT,
            eventType: EventTypeContract.TX_FAILED,
            eventName: EventName.DEPOSIT,
            payload: {
                vaultId: vaultId,
                amount: amount.toFixed(),
            },
        })
        notifyError(e, true)
        return {
            data: null,
            error: e as Error,
        }
    }
}

async function previewRedeem(
    get: ZustandGet<KantabanState>,
    set: ZustandSet<KantabanState>,
    vaultId: string,
    shares: Big,
    slippage: Big,
) {
    try {
        const signer = await providerManager.getSigner()
        if (!signer) {
            throw new Error("Wallet is not connected yet.")
        }
        return await kantabanVaultManager.previewRedeem(vaultId, shares, slippage, signer)
    } catch (e) {
        return undefined
    }
}

async function redeem(
    get: ZustandGet<KantabanState>,
    set: ZustandSet<KantabanState>,
    vaultId: string,
    shares: Big,
    slippage: Big,
) {
    try {
        const signer = await providerManager.getSigner()
        if (!signer) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error("Wallet is not connected yet.")
        }
        const { vaultData, receipt } = await kantabanVaultManager.redeem(vaultId, shares, slippage, signer)
        set(state => {
            updateVaultDatas(state, {
                [vaultId]: vaultData,
            })
        })
        const { openSuccess } = useNotifStore.getState()
        openSuccess(`Successfully redeemed ${shares} shares`, undefined, receipt.transactionHash)

        segmentService.track({
            eventGroup: EventGroup.CONTRACT,
            eventType: EventTypeContract.TX_SUCCEEDED,
            eventName: EventName.WITHDRAW,
            payload: {
                vaultId: vaultId,
                shares: shares.toFixed(),
                slippage: slippage.toFixed(),
                transactionId: receipt.transactionHash,
            },
        })

        return {
            data: { receipt },
            error: null,
        }
    } catch (e) {
        segmentService.track({
            eventGroup: EventGroup.CONTRACT,
            eventType: EventTypeContract.TX_FAILED,
            eventName: EventName.WITHDRAW,
            payload: {
                vaultId: vaultId,
                shares: shares.toFixed(),
                slippage: slippage.toFixed(),
            },
        })
        notifyError(e, true)
        return {
            data: null,
            error: e as Error,
        }
    }
}

async function approveShowerRoom(
    get: ZustandGet<KantabanState>,
    set: ZustandSet<KantabanState>,
    vaultId: string,
    amount: Big,
) {
    try {
        const signer = await providerManager.getSigner()
        if (!signer) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error("Wallet is not connected yet.")
        }
        const { vaultData, receipt } = await kantabanVaultManager.approveShowerRoom(vaultId, amount, signer)
        set(state => {
            updateVaultDatas(state, {
                [vaultId]: vaultData,
            })
        })
        const { openSuccess } = useNotifStore.getState()
        const depositAsset = kantabanVaultManager.getDepositAssetByVaultId(vaultId)
        openSuccess(`Approved ${amount} ${depositAsset.symbol}. Ready to deposit.`, undefined, receipt.transactionHash)

        segmentService.track({
            eventGroup: EventGroup.CONTRACT,
            eventType: EventTypeContract.TX_SUCCEEDED,
            eventName: EventName.APPROVE_SHOWER_ROOM,
            payload: {
                vaultId: vaultId,
                transactionId: receipt.transactionHash,
            },
        })

        return {
            data: { receipt },
            error: null,
        }
    } catch (e) {
        segmentService.track({
            eventGroup: EventGroup.CONTRACT,
            eventType: EventTypeContract.TX_FAILED,
            eventName: EventName.APPROVE_SHOWER_ROOM,
            payload: {
                vaultId: vaultId,
            },
        })
        notifyError(e, true)
        return {
            data: null,
            error: e as Error,
        }
    }
}

async function depositToShowerRoom(
    get: ZustandGet<KantabanState>,
    set: ZustandSet<KantabanState>,
    vaultId: string,
    amount: Big,
) {
    try {
        const signer = await providerManager.getSigner()
        if (!signer) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error("Wallet is not connected yet.")
        }
        const { vaultData, receipt } = await kantabanVaultManager.depositToShowerRoom(vaultId, amount, signer)
        set(state => {
            updateVaultDatas(state, {
                [vaultId]: vaultData,
            })
        })
        const { openSuccess } = useNotifStore.getState()
        const depositAsset = kantabanVaultManager.getDepositAssetByVaultId(vaultId)
        openSuccess(
            `Successfully deposited ${amount} ${depositAsset.symbol} to the queue.`,
            undefined,
            receipt.transactionHash,
        )

        segmentService.track({
            eventGroup: EventGroup.CONTRACT,
            eventType: EventTypeContract.TX_SUCCEEDED,
            eventName: EventName.DEPOSIT_SHOWER_ROOM,
            payload: {
                vaultId: vaultId,
                amount: amount.toFixed(),
                transactionId: receipt.transactionHash,
            },
        })

        return {
            data: { receipt },
            error: null,
        }
    } catch (e) {
        segmentService.track({
            eventGroup: EventGroup.CONTRACT,
            eventType: EventTypeContract.TX_FAILED,
            eventName: EventName.DEPOSIT_SHOWER_ROOM,
            payload: {
                vaultId: vaultId,
                amount: amount.toFixed(),
            },
        })
        notifyError(e, true)
        return {
            data: null,
            error: e as Error,
        }
    }
}

async function withdrawFromShowerRoom(get: ZustandGet<KantabanState>, set: ZustandSet<KantabanState>, vaultId: string) {
    try {
        const signer = await providerManager.getSigner()
        if (!signer) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error("Wallet is not connected yet.")
        }
        const { vaultData, receipt } = await kantabanVaultManager.withdrawFromShowerRoom(vaultId, signer)
        set(state => {
            updateVaultDatas(state, {
                [vaultId]: vaultData,
            })
        })
        const { openSuccess } = useNotifStore.getState()
        openSuccess(`Successfully withdrawn from queue`, undefined, receipt.transactionHash)

        segmentService.track({
            eventGroup: EventGroup.CONTRACT,
            eventType: EventTypeContract.TX_SUCCEEDED,
            eventName: EventName.WITHDRAW_SHOWER_ROOM,
            payload: {
                vaultId: vaultId,
                transactionId: receipt.transactionHash,
            },
        })

        return {
            data: { receipt },
            error: null,
        }
    } catch (e) {
        segmentService.track({
            eventGroup: EventGroup.CONTRACT,
            eventType: EventTypeContract.TX_FAILED,
            eventName: EventName.WITHDRAW_SHOWER_ROOM,
            payload: {
                vaultId: vaultId,
            },
        })
        notifyError(e, true)
        return {
            data: null,
            error: e as Error,
        }
    }
}

const lg = new ZustandLoadingGuardSequence<KantabanState>()
export const createKantabanStore: StateCreator<
    KantabanState,
    [["zustand/immer", never], ["zustand/devtools", never]],
    [],
    KantabanState
> = (set, get) => ({
    ...initKantabanState,
    fetchVaults: async () => lg.warp(get, set, fetchVaults),
    approve: async (vaultId, amount) => approve(get, set, vaultId, amount),
    deposit: async (vaultId, amount) => deposit(get, set, vaultId, amount),
    redeem: async (vaultId, shares, slippage) => redeem(get, set, vaultId, shares, slippage),
    previewRedeem: async (vaultId, shares, slippage) => previewRedeem(get, set, vaultId, shares, slippage),
    approveShowerRoom: async (vaultId, amount) => approveShowerRoom(get, set, vaultId, amount),
    depositToShowerRoom: async (vaultId, amount) => depositToShowerRoom(get, set, vaultId, amount),
    withdrawFromShowerRoom: async vaultId => withdrawFromShowerRoom(get, set, vaultId),
})

export const useKantabanStore = create(immer(devtools(createKantabanStore)))
