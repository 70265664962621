import { create, StateCreator } from "zustand"
import { devtools } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"
import { notifyError } from "../notif/notifHelper"
import { LoadableState, ZustandGet, ZustandLoadingGuardSequence, ZustandSet } from "../zustand/ZustandLoadingGuard"
import { geolocationClient } from "./GeolocationClient"

export interface GeolocationState extends LoadableState {
    isBlocked: boolean
    countryCode?: string
    fetch: () => Promise<void>
}

export const initGeolocationState = {
    loading: false,
    isBlocked: false,
}

async function fetch(get: ZustandGet<GeolocationState>, set: ZustandSet<GeolocationState>) {
    try {
        const { isBlocked, countryCode } = await geolocationClient.getGeolocation()
        set(state => {
            state.isBlocked = isBlocked
            state.countryCode = countryCode
        })
    } catch (e) {
        // Set isBlocked = true if there any error from the geolocation service
        set(state => {
            state.isBlocked = true
        })
        notifyError(e)
    }
}

const lg = new ZustandLoadingGuardSequence<GeolocationState>()
const createGeolocationStore: StateCreator<
    GeolocationState,
    [["zustand/immer", never], ["zustand/devtools", never]],
    [],
    GeolocationState
> = (set, get) => ({
    ...initGeolocationState,
    fetch: async () => lg.warp(get, set, fetch),
})

export const useGeolocationStore = create(immer(devtools(createGeolocationStore)))
