import { create, StateCreator } from "zustand"
import { devtools } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"
import { notifyError } from "../notif/notifHelper"
import { LoadableState, ZustandGet, ZustandLoadingGuardSequence, ZustandSet } from "../zustand/ZustandLoadingGuard"
import { coingeckoClient, CoingeckoId, CoingeckoPriceMap } from "./CoingeckoClient"

export interface CoingeckoState extends LoadableState {
    prices: CoingeckoPriceMap
    fetch: (ids: CoingeckoId[]) => Promise<void>
}

export const initCoingeckoState = {
    loading: false,
    prices: {},
}

async function fetch(get: ZustandGet<CoingeckoState>, set: ZustandSet<CoingeckoState>, ids: CoingeckoId[]) {
    try {
        const prices = await coingeckoClient.getPrices(ids)
        set(state => {
            state.prices = prices
        })
    } catch (e) {
        notifyError(e)
    }
}

const lg = new ZustandLoadingGuardSequence<CoingeckoState>()
const createCoingeckoStore: StateCreator<
    CoingeckoState,
    [["zustand/immer", never], ["zustand/devtools", never]],
    [],
    CoingeckoState
> = (set, get) => ({
    ...initCoingeckoState,
    fetch: async ids => lg.warp(get, set, fetch, ids),
})

export const useCoingeckoStore = create(immer(devtools(createCoingeckoStore)))
