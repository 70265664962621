import fetch from "cross-fetch"
import { sleep } from "./time"

export async function httpGet<T>(url: string): Promise<T> {
    const res = await fetch(url)
    if (res.status >= 400) {
        try {
            // todo save error response to sentry
        } catch (e) {
            // do nothing
        }
        throw new Error(`Bad ${res.status} response from server`)
    }
    return await res.json()
}

export async function retryHttpGet<T>(url: string): Promise<T> {
    try {
        return await httpGet(url)
    } catch (e) {
        await sleep(100)
        return await httpGet(url)
    }
}
