import { create, StateCreator } from "zustand"
import { devtools } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"
import { notifyError } from "../notif/notifHelper"
import { LoadableState, ZustandGet, ZustandLoadingGuardSequence, ZustandSet } from "../zustand/ZustandLoadingGuard"
import { providerManager } from "./ProviderManager"

interface IProviderState extends LoadableState {
    rpcUrl?: string
    fetch: () => Promise<void>
}

export const initProviderStore = {
    loading: false,
}

async function fetch(get: ZustandGet<IProviderState>, set: ZustandSet<IProviderState>) {
    try {
        const rpcUrl = await providerManager.getRPCUrl()
        set(state => {
            state.rpcUrl = rpcUrl
        })
    } catch (e) {
        notifyError(e, true)
    }
}

const lg = new ZustandLoadingGuardSequence<IProviderState>()
const createProviderStore: StateCreator<
    IProviderState,
    [["zustand/immer", never], ["zustand/devtools", never]],
    [],
    IProviderState
> = (set, get) => ({
    ...initProviderStore,
    fetch: async () => lg.warp(get, set, fetch),
})

export const useProviderStore = create(immer(devtools(createProviderStore)))
