import { gql, GraphQLClient } from "graphql-request"
import { STAGE } from "src/constants"

interface IAddToWaitList {
    id: string
    sender: string
    timestamp: string
}

interface IGetAddToWaitListRes {
    addToWaitLists: IAddToWaitList[]
}

interface IWithdrawList {
    withdraws: {
        id: string
        account: string
        timestamp: string
    }[]
}

export class TheGraphClient {
    private readonly client: GraphQLClient

    constructor(private readonly url: string) {
        this.client = new GraphQLClient(this.url)
    }

    async getAddToWaitLists(waitListIndex: number, showerRoomAddr: string): Promise<IAddToWaitList[]> {
        const query = gql`
            query AddToWaitLists($waitListIndex: Int, $showerRoomAddr: String) {
                addToWaitLists(
                    where: { waitListIndex_gte: $waitListIndex, showerRoomAddr: $showerRoomAddr }
                    orderBy: "waitListIndex"
                    orderDirection: "asc"
                ) {
                    id
                    sender
                    timestamp
                    txHash
                    waitListIndex
                }
            }
        `
        const data = await this.client.request<IGetAddToWaitListRes>(query, { waitListIndex, showerRoomAddr })
        return data.addToWaitLists
    }

    async getWithdrawLists(timestamp: number, showerRoomAddr: string) {
        const query = gql`
            query WithdrawLists($timestamp: Int, $showerRoomAddr: String) {
                withdraws(
                    where: { timestamp_gte: $timestamp, showerRoomAddr: $showerRoomAddr }
                    orderBy: "timestamp"
                    orderDirection: "asc"
                ) {
                    id
                    account
                    timestamp
                    txHash
                }
            }
        `
        const data = await this.client.request<IWithdrawList>(query, { timestamp, showerRoomAddr })
        return data.withdraws
    }
}

const opTestUrl = "https://api.thegraph.com/subgraphs/name/perpetual-protocol/kantaban-tester-subgraph"
const opUrl = "https://subgraph.satsuma-prod.com/2svBjIc8TFW1/perp/kantaban-subgraph/api"
const url = STAGE === "staging" ? opTestUrl : opUrl

export const theGraphClient = new TheGraphClient(url)
