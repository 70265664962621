import { Box, Separator, Text } from "@sayaww/nomimono"
import { Squircle } from "corner-smoothing"
import React from "react"
import { AprChart } from "src/components/AprChart"
import { Stat } from "src/components/Stat"
import { MotionBox } from "../pages/VaultDetail/VaultDetail"
import { SharePriceChart } from "./SharePriceChart"

export const FullChart = (props: {
    allTimeApr?: string
    sevenDayApr?: string
    thirtyDayApr?: string
    setShowFullChart: React.Dispatch<React.SetStateAction<boolean>>
    showFullChart?: boolean
}): JSX.Element => {
    return (
        <MotionBox>
            <Squircle
                cornerRadius={30}
                cornerSmoothing={0.6}
                style={{
                    width: "100%",
                    backgroundColor: "white",
                }}
            >
                <Box
                    css={{
                        width: "100%",
                        p: 32,
                    }}
                >
                    <Text
                        css={{
                            lineHeight: 1,
                        }}
                        size={"title2"}
                    >
                        Share Price
                    </Text>
                </Box>
                <SharePriceChart />
                <Box
                    css={{
                        width: "100%",
                        p: 32,
                        pt: 64,
                    }}
                >
                    <Text
                        css={{
                            lineHeight: 1,
                        }}
                        size={"title2"}
                    >
                        APR <br />
                        <Text css={{ lineHeight: 2, fontWeight: "normal", color: "$slate9" }} size="label">
                            Over the last 30 days
                        </Text>
                    </Text>
                </Box>
                <AprChart />
                <Box
                    css={{
                        width: "100%",
                        p: 32,
                    }}
                >
                    <Box
                        css={{
                            paddingTop: 20,
                            display: "block",
                            "@sm": {
                                display: "flex",
                                justifyContent: "stretch",
                                flexDirection: "row",
                            },
                        }}
                    >
                        <div style={{ flex: 1 }}>
                            <Stat
                                labelColor="#00DF7C"
                                label={"Latest 30-day APY"}
                                value={props.thirtyDayApr}
                                subValue="%"
                                tooltip="APY over the past 30 days"
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <Stat
                                labelColor="#6C78F4"
                                label={"Latest All-time APY"}
                                value={props.allTimeApr}
                                subValue="%"
                                tooltip="APY all-time"
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <Stat
                                label={"Latest 7-day APY"}
                                value={props.sevenDayApr}
                                subValue="%"
                                tooltip="APY over the past 7 days"
                            />
                        </div>
                    </Box>
                </Box>
            </Squircle>
        </MotionBox>
    )
}
