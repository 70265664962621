import { isProduction } from "./stage"

export const logMethod = (target: object, propertyKey: string, descriptor: PropertyDescriptor) => {
    if (isProduction()) {
        return descriptor
    }
    const originalMethod = descriptor.value
    descriptor.value = function (...args: unknown[]) {
        // console.log(`${propertyKey}(${args.join(", ")})`)
        return originalMethod.apply(this, args)
    }
    return descriptor
}

export function debugLog(...args: unknown[]) {
    if (isProduction()) {
        return
    }
    console.log(...args)
}
