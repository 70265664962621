import { Box, HeroIcon, HeroIconOne, Progress, Text } from "@sayaww/nomimono"
import Big from "big.js"
import React from "react"
import { Stat, nFormatter } from "src/components/Stat"
import { KantabanVault } from "src/service/kantaban/KantabanVault"
import { VaultData } from "../pages/VaultDetail/useVaultDetail"

export function VaultStats({
    vault,
    isCapZero,
    showFullChart,
    vaultCMS,
    setShowFullChart,
    thirtyDayApr,
    sevenDayApr,
    allTimeApr,
    sharePriceExact,
    data,
    sharePrice,
    subAccentColor,
    accentColor,
    maxDepositAmount,
}: {
    vault: KantabanVault | undefined
    isCapZero: boolean | undefined
    showFullChart: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    vaultCMS: any
    setShowFullChart: React.Dispatch<React.SetStateAction<boolean>>
    thirtyDayApr: string | undefined
    sevenDayApr: string | undefined
    allTimeApr: string | undefined
    sharePriceExact: string | undefined
    data: VaultData
    sharePrice: string
    subAccentColor: string
    accentColor: string
    maxDepositAmount: Big | undefined
}) {
    return (
        <div className="w-full flex flex-row items-center gap-10 px-10 py-10 lg:mx-auto max-w-[1110px] lg:p-9">
            <div className="flex-1">
                <div>
                    {isCapZero && <ComingSoonBadge />}
                    <div className="text-base font-normal leading-none text-white">{vault?.displaySubtitle}</div>
                    <div className="text-3xl lg:text-5xl font-bold font-['SkModernistBold'] leading-none text-white font-brand">
                        {vault?.displayName} Vault
                    </div>
                </div>

                <div className="gap-0 mt-10 lg:gap-4 lg:columns-4 md:columns-2 columns-2 lg:mt-14">
                    <Stat
                        color="white"
                        label={"APY (all-time)"}
                        value={allTimeApr}
                        subValue="%"
                        tooltip="View performance chart"
                        bottomValue={`${thirtyDayApr}% (30-day)`}
                    />
                    <Stat
                        color="white"
                        tooltip={`The current price of a single share: ${sharePriceExact}`}
                        label={"Share Price"}
                        bottomValue={data.depositAsset?.symbol}
                        value={sharePrice}
                    />
                    <Stat
                        color="white"
                        tooltip={`Total shares: ${vault?.totalShares.toFixed(6)} / Cap: ${data.totalSupplyCap}`}
                        label={"Vault Share/Cap"}
                        value={nFormatter(Number(data.totalShares), 2)}
                        bottomValue={`/${nFormatter(Number(data.totalSupplyCap), 1)} shares`}
                    />
                    <Stat
                        color="white"
                        tooltip={`Total value locked: ${data.totalShareValue} ${data.depositAsset?.symbol}`}
                        label={"TVL"}
                        value={nFormatter(Number(data.totalShareValue), 2)}
                        bottomValue={data.depositAsset?.symbol}
                    />
                </div>

                <div className="mt-4 lg:mt-6">
                    {vault?.totalSupplyCap.toNumber() !== 0 && (
                        <Progress
                            spacing={0}
                            borderColor="black"
                            barColor={accentColor}
                            leftLabel={
                                <div className="pt-2.5 text-sm font-normal leading-tight text-zinc-500">Deposit</div>
                            }
                            rightLabel={
                                <div className="pt-2.5 text-sm font-normal leading-tight text-zinc-500">
                                    {maxDepositAmount?.round(3, Big.roundDown).toString()} {data.depositAsset?.symbol}{" "}
                                    remaining — {vault?.totalShares.mul(100).div(vault?.totalSupplyCap).toFixed(2)}%
                                </div>
                            }
                            value={vault?.totalShares.round(1, Big.roundUp).toNumber()}
                            max={vault?.totalSupplyCap.toNumber()}
                        />
                    )}
                </div>
            </div>
            {/* <div style={{ flex: 1 }} /> */}
            <img
                className="hidden object-cover object-center max-w-[360px] max-h-[360px] w-full h-full lg:block"
                src={vaultCMS?.cover?.url}
                alt="cover"
            />
        </div>
    )
}

export const ComingSoonBadge = () => {
    return (
        <Box
            css={{
                borderRadius: "$full",
                bgTone9: "red",
                fontSize: 11,
                fontWeight: 500,
                px: 12,
                py: 6,
                w: "fit-content",
                display: "block",
                flex: 0,
                flexShrink: 1,
                alignSelf: "flex-start",
                flexGrow: 0,
                color: "$red2",
                mb: "$2",
            }}
        >
            COMING SOON
        </Box>
    )
}
