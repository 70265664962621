import { create, StateCreator } from "zustand"
import { devtools } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"
import { notifyError } from "../notif/notifHelper"
import { LoadableState, ZustandGet, ZustandLoadingGuardSequence, ZustandSet } from "../zustand/ZustandLoadingGuard"
import { aprClient, DailyAprArrayMap, DailyAprMap } from "./AprClient"

export interface AprState extends LoadableState {
    dailyAprMap: DailyAprMap // key: vault address
    historicalDailyAprsMap: DailyAprArrayMap // key: "lowerCaseVaultAddress-days"
    fetch: () => Promise<void>
    fetchHistoricalDailyAprs: (address: string, days: number) => Promise<void>
}

export const initAprState = {
    loading: false,
    dailyAprMap: {},
    historicalDailyAprsMap: {},
}

async function fetch(get: ZustandGet<AprState>, set: ZustandSet<AprState>) {
    try {
        const dailyAprMap = await aprClient.getDailyAprMap()
        set(state => {
            state.dailyAprMap = dailyAprMap
        })
    } catch (e) {
        notifyError(e)
    }
}

async function fetchHistoricalDailyAprs(
    get: ZustandGet<AprState>,
    set: ZustandSet<AprState>,
    address: string,
    days: number,
) {
    const lowerCaseAddr = address.toLowerCase()
    try {
        const dailyAprs = await aprClient.getHistoricalDailyAprs(lowerCaseAddr, days)
        set(state => {
            const key = `${lowerCaseAddr}-${days}`
            state.historicalDailyAprsMap[key] = dailyAprs
        })
    } catch (e) {
        notifyError(e)
    }
}

const lg = new ZustandLoadingGuardSequence<AprState>()
const createAprStore: StateCreator<AprState, [["zustand/immer", never], ["zustand/devtools", never]], [], AprState> = (
    set,
    get,
) => ({
    ...initAprState,
    fetch: async () => lg.warp(get, set, fetch),
    fetchHistoricalDailyAprs: async (address, days) => lg.warp(get, set, fetchHistoricalDailyAprs, address, days),
})

export const useAprStore = create(immer(devtools(createAprStore)))
