import { Bento, Box, HeroIcon, Progress, Stack, Text } from "@sayaww/nomimono"
import Big from "big.js"
import { Squircle } from "corner-smoothing"
import { motion } from "framer-motion"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useRef } from "react"
import { Link } from "react-router-dom"
import { PeopleIcon } from "src/components/QueueStatus"
import { SubscribeButton } from "src/components/SubscribeButton"
import { ComingSoonBadge } from "src/components/VaultStats"
import { EventGroup, EventName, segmentService } from "src/service/segment/SegmentService"
import { useVaults } from "./useVaults"

// and get their CMS data like image, description, etc from CMS
export default function Vaults() {
    const { vaultsCMS, frontpagesCMS, vaults, dailyAprMap, isLoadingVaults } = useVaults()

    // NOTE: handle shower room queue hint display tracking here to avoid tracking multiple times when re-rendered.
    const showerRoomDisplayEventTrackedRef = useRef<{ [vaultId: string]: boolean }>({})
    const trackShowerRoomDisplay = (vaultId: string) => {
        if (showerRoomDisplayEventTrackedRef.current[vaultId] === true) {
            return
        }
        segmentService.track({
            eventGroup: EventGroup.INTERACTION,
            eventName: EventName.SHOWER_ROOM_QUEUE_HINT_DISPLAYED,
            payload: { vaultId },
        })
        showerRoomDisplayEventTrackedRef.current = { ...showerRoomDisplayEventTrackedRef.current, [vaultId]: true }
    }

    const MotionBento = motion(Bento)

    return (
        <motion.div
            className="flex items-center justify-center"
            // page transition wrapper
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ userSelect: "none" }}
        >
            <Bento __width="100%" __maxWidth="1440px">
                <Box
                    css={{
                        marginTop: 12,
                        px: 6,
                        display: "flex",
                        alignItems: "stretch",
                        direction: "row",
                        justifyContent: "center",
                    }}
                >
                    <Stack textAlign="center" gap="2" __maxWidth="500px">
                        <Text
                            size="page-title"
                            css={{
                                color: "$baseBackground",
                            }}
                        >
                            {frontpagesCMS[0]?.heading}
                        </Text>
                        <Text
                            css={{
                                color: "$baseBackground",
                            }}
                        >
                            {frontpagesCMS[0]?.tagline}
                        </Text>
                    </Stack>
                </Box>

                <Box
                    css={{
                        px: "$4",
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        direction: "column",
                        gap: 24,
                        "@sm": {
                            my: "$16",
                        },
                        "@md": {
                            my: "$28",
                            direction: "row",
                            gap: 1,
                        },
                    }}
                >
                    {vaults?.map(vault => {
                        // if vault cap is 0, then disable buttons
                        const isCapZero = vault.totalSupplyCap.eq(0)

                        const vaultAddress = vault.contractAddr.toLowerCase()
                        // apr and share price
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const sevenDayApr = dailyAprMap[vaultAddress]
                            ? dailyAprMap[vaultAddress].sevenDayRollingApr.mul(100).toFixed(2)
                            : "—"
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const allTimeApr = dailyAprMap[vaultAddress]
                            ? dailyAprMap[vaultAddress].lifeTimeApr.mul(100).toFixed(2)
                            : "—"
                        const thirtyDayApr = dailyAprMap[vaultAddress]
                            ? dailyAprMap[vaultAddress].thirtyDayRollingApr.mul(100).toFixed(2)
                            : "—"

                        // assets from CMS
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const backgroundUrl = vaultsCMS.find((v: any) => v.vaultId === vault.id)?.background?.url
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const accentColor = vaultsCMS.find((v: any) => v.vaultId === vault.id)?.accent?.hex
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const description = vaultsCMS.find((v: any) => v.vaultId === vault.id)?.description
                        //  animation
                        const cardAnimation = {
                            initial: {
                                y: 0,
                            },
                            hover: {
                                y: -4,
                                borderColor: accentColor,
                            },
                        }

                        return (
                            <MotionBento
                                transition="slow"
                                __maxWidth={"432px"}
                                key={vault.id}
                                position={"relative"}
                                variants={cardAnimation}
                                initial={"initial"}
                                whileHover={"hover"}
                                alignSelf="stretch"
                            >
                                <Squircle
                                    cornerRadius={30}
                                    cornerSmoothing={0.6}
                                    style={{
                                        height: "100%",
                                        flex: 1,
                                        backgroundColor: "white",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Link
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: "100%",
                                            zIndex: 1,
                                        }}
                                        to={`/${vault.slug}`}
                                    />
                                    <div
                                        style={{
                                            userSelect: "none",
                                            objectFit: "contain",
                                        }}
                                    >
                                        <img
                                            draggable={false}
                                            style={{
                                                transform: "scale(1.01)",
                                                aspectRatio: "416/150)",
                                                borderBottom: "1px solid black",
                                            }}
                                            alt="background"
                                            width="100%"
                                            src={backgroundUrl}
                                        />
                                    </div>
                                    <Stack flexGrow={1} gap={"8"} px={{ xs: "6", md: "10" }} py={{ xs: "6", md: "8" }}>
                                        <Stack gap="6">
                                            <motion.div>
                                                {!isLoadingVaults && isCapZero && <ComingSoonBadge />}
                                                <Text size="body" css={{ color: "black" }}>
                                                    {vault.displaySubtitle}
                                                </Text>
                                                <Text size="title2">{vault.displayName}</Text>
                                            </motion.div>
                                            <Text size={"body"}>{description}</Text>
                                        </Stack>
                                        <div style={{ flex: 1 }}></div>
                                        <Stack gap={"2"}>
                                            <div
                                                style={{
                                                    justifyContent: "space-between",
                                                    display: "inline-flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Box
                                                    css={{
                                                        mb: 32,
                                                    }}
                                                >
                                                    <Text size={"body"} css={{ color: "$baseTextSecondary" }}>
                                                        APY (All-time)
                                                    </Text>

                                                    <Text
                                                        size={"title2"}
                                                        css={{
                                                            color: "$baseText",
                                                            lineHeight: 1,
                                                            overflowWrap: "anywhere",
                                                        }}
                                                    >
                                                        {allTimeApr === "0.00" || !allTimeApr ? "•_•" : allTimeApr}
                                                        <Text
                                                            css={{
                                                                ml: "0.25em",
                                                                color: "$baseTextTertiary",
                                                            }}
                                                            as="span"
                                                            size={"body-bold"}
                                                        >
                                                            %
                                                        </Text>
                                                    </Text>
                                                    <Text
                                                        css={{
                                                            fontWeight: "semibold",
                                                            pt: "0.25em",
                                                            color: "white",
                                                            fontSize: "$xs",

                                                            bg: "rgba(0,0,0,0.4)",
                                                            borderRadius: "$full",
                                                            display: "inline-block",
                                                            width: "auto",
                                                            px: "$2",
                                                            py: 2,
                                                        }}
                                                        size={"label"}
                                                    >
                                                        {thirtyDayApr}% (30-day)
                                                    </Text>
                                                </Box>
                                                {vault.shouldGoToShowerRoom && (
                                                    <QueueInfo
                                                        accentColor={accentColor}
                                                        vaultId={vault.id}
                                                        trackDisplayed={trackShowerRoomDisplay}
                                                    />
                                                )}
                                            </div>
                                            {/*check if totalSupplyCap is 0 or else it causes crash*/}
                                            {vault.totalSupplyCap.toNumber() !== 0 ? (
                                                <Progress
                                                    spacing={0}
                                                    borderColor="black"
                                                    barColor={accentColor}
                                                    leftLabel={
                                                        <Box css={{ pt: 6 }}>
                                                            <Text size="label">Deposit</Text>
                                                        </Box>
                                                    }
                                                    rightLabel={
                                                        <Box css={{ pt: 6 }}>
                                                            <Text size="label">
                                                                {vault?.totalShares
                                                                    .mul(100)
                                                                    .div(vault?.totalSupplyCap)
                                                                    .toFixed(2)}
                                                                %
                                                            </Text>
                                                        </Box>
                                                    }
                                                    value={vault?.totalShares.round(1, Big.roundUp).toNumber()}
                                                    max={vault?.totalSupplyCap.toNumber()}
                                                />
                                            ) : (
                                                // this is a placeholder
                                                <Progress
                                                    spacing={0}
                                                    borderColor="black"
                                                    barColor={accentColor}
                                                    leftLabel={
                                                        <Box css={{ pt: 6 }}>
                                                            <Text size="label">Deposit</Text>
                                                        </Box>
                                                    }
                                                    rightLabel={
                                                        <Box css={{ pt: 6 }}>
                                                            <Text size="label">—%</Text>
                                                        </Box>
                                                    }
                                                    value={0}
                                                    max={100}
                                                />
                                            )}
                                        </Stack>
                                    </Stack>
                                </Squircle>
                            </MotionBento>
                        )
                    })}
                </Box>
            </Bento>
        </motion.div>
    )
}

const QueueInfo = ({
    vaultId,
    accentColor,
    trackDisplayed,
}: {
    vaultId: string
    accentColor: string
    trackDisplayed: (vaultId: string) => void
}) => {
    trackDisplayed(vaultId)
    return (
        <Box
            css={{
                mb: 24,
                maxWidth: "45%",
                borderRadius: "$3xl",
                background: accentColor,
                px: 16,
                py: 12,
                display: "flex",
                "flex-direction": "column",
                gap: "3px",
                color: "white",
            }}
        >
            <Box
                css={{
                    display: "flex",
                    "flex-direction": "row",
                    gap: "8px",
                    color: "White",
                }}
            >
                <PeopleIcon />

                <Text size="label" css={{ fontWeight: "bold" }}>
                    Vault full?
                </Text>
            </Box>
            <Text size={"label"}>Join queue and start earning interest →</Text>
            {/* Do not delete in case the marketing team wants to use this again */}
            {/* <Text size={"caption"}>{vault?.srWaitingListLength} people in the queue</Text> */}
        </Box>
    )
}
