import { curveCardinal } from "@visx/curve"
import { Axis, buildChartTheme, Grid, LineSeries, Tooltip, XYChart } from "@visx/xychart"

import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { kantabanVaultMetas } from "src/service/kantaban/KantabanVaultMeta"
import { shallow } from "zustand/shallow"
import { DailyAprArrayMap } from "../service/databricks/AprClient"
import { useAprStore } from "../service/databricks/aprStore"

export function getContractAddrFromSlug(slug: string | undefined) {
    const vaultMeta = Object.values(kantabanVaultMetas).find(meta => {
        return meta.slug === slug
    })
    return vaultMeta?.contractAddr
}

const aprChartTheme = buildChartTheme({
    backgroundColor: "transparent",
    colors: ["#6c78f4", "#00DF7C", "hsl(0, 0%, 84%)"],
    gridColor: "rgba(0,0,0,0.1)",
    gridColorDark: "#333",
    tickLength: 7,
})

export const AprChart = () => {
    const location = useLocation() // "/arbeth-eth"
    const pathWithoutSlash = location.pathname.replace("/og", "").substring(1) // "arbeth-eth"

    const contractAddr = getContractAddrFromSlug(pathWithoutSlash)
    const { historicalDailyAprsMap: dataMap, fetchHistoricalDailyAprs } = useAprStore(
        state => ({
            historicalDailyAprsMap: state.historicalDailyAprsMap,
            fetchHistoricalDailyAprs: state.fetchHistoricalDailyAprs,
        }),
        shallow,
    )
    const data = getHistoricalDailyAprs(contractAddr, dataMap, 30)
    useEffect(() => {
        if (!contractAddr) {
            return
        }
        fetchHistoricalDailyAprs(contractAddr, 30)
    }, [contractAddr, fetchHistoricalDailyAprs])

    const accessors = {
        xAccessor: (d: any) => d.date.toISODate(),
        yAccessor: (d: any) => d.lifeTimeApr.toFixed(4),
        thirtyDayRollingAprAccessor: (d: any) => (d.thirtyDayRollingApr ? d.thirtyDayRollingApr.toFixed(4) : ""),
        sevenDayRollingAprAccessor: (d: any) => (d.sevenDayRollingApr ? d.sevenDayRollingApr.toFixed(4) : ""),
    }

    // Detect window width using useEffect and useState
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        }

        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    return (
        <XYChart
            theme={aprChartTheme}
            margin={{ top: 24, right: 32, bottom: 32, left: 64 }}
            height={260}
            xScale={{ type: "band" }}
            yScale={{
                type: "linear",
                zero: true,
                nice: false,
                domain: [-0.05, 0.4],
            }}
        >
            <Axis stroke="transparent" orientation="bottom" numTicks={windowWidth < 480 ? 3 : 6} />
            <Axis orientation="left" numTicks={4} tickFormat={tickValue => `${(tickValue * 100).toFixed(0)}%`} />
            <Grid numTicks={5} columns={false} />
            <LineSeries
                dataKey="All-time APR"
                data={data}
                xAccessor={accessors.xAccessor}
                yAccessor={accessors.yAccessor}
                strokeWidth={3}
                curve={curveCardinal}
            />
            <LineSeries
                dataKey="30-day Time APR"
                data={data}
                xAccessor={accessors.xAccessor}
                yAccessor={accessors.thirtyDayRollingAprAccessor}
                strokeWidth={3}
                curve={curveCardinal}
            />
            <LineSeries
                dataKey="7-day APR"
                data={data}
                xAccessor={accessors.xAccessor}
                yAccessor={accessors.sevenDayRollingAprAccessor}
                strokeWidth={3}
                curve={curveCardinal}
            />
            <Tooltip
                zIndex={"200"}
                style={{
                    position: "absolute",
                    fontFamily: "sans-serif",
                    borderRadius: "20px",
                    fontSize: "14px",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.3)",
                    overflow: "hidden",
                }}
                showVerticalCrosshair
                showSeriesGlyphs
                renderTooltip={({ tooltipData, colorScale }) => {
                    return (
                        <>
                            {tooltipData && tooltipData.nearestDatum && colorScale && (
                                <div
                                    style={{
                                        color: "white",
                                        zIndex: 999,
                                        paddingLeft: "1em",
                                        paddingRight: "1em",
                                        paddingTop: "0.5em",
                                        paddingBottom: "0.5em",
                                        lineHeight: 1.2,
                                        fontWeight: "bold",
                                        fontSize: "1.2em",
                                        backgroundColor: "rgba(0,0,0,0.9)",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: "0.75em",
                                            opacity: 0.5,
                                            fontWeight: "normal",
                                        }}
                                    >
                                        {accessors.xAccessor(tooltipData.nearestDatum.datum)}
                                    </span>
                                    <br />

                                    <div
                                        style={{
                                            paddingTop: "0.5em",
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: "#00DF7C",
                                            }}
                                        >
                                            {`${(
                                                accessors.thirtyDayRollingAprAccessor(tooltipData.nearestDatum.datum) *
                                                100
                                            ).toFixed(2)}%`}
                                        </span>
                                        <div
                                            style={{
                                                fontSize: "0.75em",
                                                opacity: 0.75,
                                                fontWeight: "normal",
                                            }}
                                        >
                                            30-day
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            paddingTop: "0.5em",
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: "#6c78f4",
                                            }}
                                        >
                                            {`${(accessors.yAccessor(tooltipData.nearestDatum.datum) * 100).toFixed(
                                                2,
                                            )}%`}{" "}
                                        </span>
                                        <div
                                            style={{
                                                fontSize: "0.75em",
                                                opacity: 0.75,
                                                fontWeight: "normal",
                                            }}
                                        >
                                            All-time
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            paddingTop: "0.5em",
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: "hsl(0, 0%, 84%)",
                                            }}
                                        >
                                            {`${(
                                                accessors.sevenDayRollingAprAccessor(tooltipData.nearestDatum.datum) *
                                                100
                                            ).toFixed(2)}%`}
                                        </span>
                                        <div
                                            style={{
                                                fontSize: "0.75em",
                                                opacity: 0.75,
                                                fontWeight: "normal",
                                            }}
                                        >
                                            7-day
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )
                }}
            />
        </XYChart>
    )
}

export function getHistoricalDailyAprs(contractAddr: string | undefined, dataMap: DailyAprArrayMap, days: number) {
    if (!contractAddr) {
        return []
    }
    const key = `${contractAddr.toLowerCase()}-${days}`
    const data = dataMap[key]
    if (!data) {
        return []
    }
    return data
}
