import { Radix, styled } from "@sayaww/nomimono"
import { TooltipAnchor, Tooltip as TooltipPrimitive, useTooltipState } from "ariakit/tooltip"
import React from "react"
// TODO: move to nomimono lib later

const StyledTooltip = styled(TooltipPrimitive, {
    backgroundColor: Radix.blackA.blackA11,
    backdropFilter: "blur(4px)",
    color: "$mauve1",
    borderRadius: "$2xl",
    px: "$3",
    py: "$2",
    zIndex: 1000,
    fontSize: "$xs",
    fontWeight: 400,
    fontFamily: "$body",
    boxShadow: "0 0 0 1px $mauve12",
})

export function Tooltip({ label, children }: { label: React.ReactNode; children: React.ReactNode }) {
    const tooltip = useTooltipState()
    return (
        <>
            <TooltipAnchor style={{ display: "inline-block" }} state={tooltip}>
                {children}
            </TooltipAnchor>
            <StyledTooltip state={tooltip}>{label}</StyledTooltip>
        </>
    )
}
