import { create, StateCreator } from "zustand"
import { devtools } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"
import { notifyError } from "../notif/notifHelper"
import { LoadableState, ZustandGet, ZustandLoadingGuardSequence, ZustandSet } from "../zustand/ZustandLoadingGuard"
import { hygraphClient } from "./HygraphClient"

export interface HygraphState extends LoadableState {
    vaults: any[]
    frontpages: any[]
    vaultDetails: { [vaultSlug: string]: any }
    listVaults: () => Promise<void>
    listFrontpages: () => Promise<void>
    getVaultDetail: (vaultSlug: string) => Promise<void>
}

export const initHygraphState = {
    loading: false,
    vaults: [],
    frontpages: [],
    vaultDetails: {},
}

async function listVaults(get: ZustandGet<HygraphState>, set: ZustandSet<HygraphState>) {
    try {
        const vaults = await hygraphClient.listVaults()
        set(state => {
            state.vaults = vaults
        })
    } catch (e) {
        notifyError(e)
    }
}

async function listFrontpages(get: ZustandGet<HygraphState>, set: ZustandSet<HygraphState>) {
    try {
        const frontpages = await hygraphClient.listFrontpages()
        set(state => {
            state.frontpages = frontpages
        })
    } catch (e) {
        notifyError(e)
    }
}

async function getVaultDetail(get: ZustandGet<HygraphState>, set: ZustandSet<HygraphState>, vaultSlug: string) {
    try {
        const vaultDetail = await hygraphClient.getVaultDetail(vaultSlug)
        set(state => {
            state.vaultDetails[vaultSlug] = vaultDetail
        })
    } catch (e) {
        notifyError(e)
    }
}

const lg = new ZustandLoadingGuardSequence<HygraphState>()
const createHygraphStore: StateCreator<
    HygraphState,
    [["zustand/immer", never], ["zustand/devtools", never]],
    [],
    HygraphState
> = (set, get) => ({
    ...initHygraphState,
    listVaults: async () => lg.warp(get, set, listVaults),
    listFrontpages: async () => lg.warp(get, set, listFrontpages),
    getVaultDetail: async vaultSlug => lg.warp(get, set, getVaultDetail, vaultSlug),
})

export const useHygraphStore = create(immer(devtools(createHygraphStore)))
