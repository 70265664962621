import { Box, BrandButton, LucideIcon, Stack, Text } from "@sayaww/nomimono"
import Big from "big.js"
import clsx from "clsx"
import { motion } from "framer-motion"
import { useSetAtom } from "jotai"
import React, { useEffect, useState } from "react"
import Countdown, { zeroPad } from "react-countdown"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useParams } from "react-router-dom"
import { BaseInput } from "src/components/Input"
import { Tooltip } from "src/components/Tooltip"
import { useKantabanStore } from "src/service/kantaban/kantabanStore"
import { useNotifStore } from "src/service/notif/notifStore"
import { EventGroup, EventName, segmentService } from "src/service/segment/SegmentService"
import { openVaultBg } from "src/util/uiAtom"
import { useWalletClient } from "wagmi"
import { shallow } from "zustand/shallow"

const MotionStack = motion(Stack)
const MotionBox = motion(Box)

const STORAGE_KEY_SLIPPAGE = "slippage"

function Redeem() {
    // Define form
    // Form Document https://react-hook-form.com/get-started#Quickstart
    type FormData = {
        amount: string
        slippage: number
    }

    const slippage = localStorage.getItem(STORAGE_KEY_SLIPPAGE)
    const {
        register,
        handleSubmit,
        setValue,
        setFocus,
        reset,
        watch,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            amount: "",
            slippage: Number(slippage) || 0.5,
        },
    })

    useEffect(() => {
        setFocus("amount")
    }, [setFocus])

    // Handle vaults and vault data from URL
    // get params from url
    const { vaultSlug } = useParams()
    // get all vault data
    const { vaults, redeem, previewRedeem, fetchVaults } = useKantabanStore(
        state => ({
            vaults: state.vaults,
            redeem: state.redeem,
            previewRedeem: state.previewRedeem,
            fetchVaults: state.fetchVaults,
        }),
        shallow,
    )
    // get vault data by vaultId
    const vault = vaults.find(vault => vault.slug === vaultSlug)
    const vaultData = {
        minDepositAmount: vault?.minDepositAmount,
        vaultId: vault?.id,
        vaultName: vault?.displayName,
        totalShares: vault?.totalShares.toFixed(),
        totalShareValue: vault?.totalShareValue.toFixed(),
        shares: vault?.positionShares.toFixed(),
        shareValue: vault?.positionShareValue.toFixed(),
        allowance: vault?.allowance.toFixed(),
        displaySymbol: vault?.displaySubtitle,
        depositAsset: vault?.depositAsset,
        shareAsset: vault?.shareAsset,
        lastMintedAt: vault?.lastMintedAt,
        transferCooldown: vault?.transferCooldown,
    }

    // Handle form submit
    const { vaultId } = vaultData
    const setOpen = useSetAtom(openVaultBg)

    const handleRedeem = async (data: FormData) => {
        if (!vaultId) {
            toast.error("vaultId is undefined")
            return
        }
        await localStorage.setItem(STORAGE_KEY_SLIPPAGE, `${data.slippage}`)
        const slippage = await Big(data.slippage).div(100)
        const txResult = await redeem(vaultId, Big(data.amount), slippage)

        // truncate data.amount to 4 decimal places and add ... to the end
        const truncatedAmount = Big(data.amount).toFixed(4)

        toast.loading("Redeeming:  " + truncatedAmount + " shares", {
            id: "withdrawing",
        })

        if (txResult.data !== null) {
            toast.dismiss("withdrawing")
            setOpen(false)
            toast.success("Redeem successful", { id: "withdrawing" })
        }

        segmentService.track({
            eventGroup: EventGroup.INTERACTION,
            eventName: EventName.WITHDRAW,
            payload: { vaultId, ...data },
        })
    }

    const [previewAmount, setPreviewAmount] = useState("0")
    const handlePreviewRedeem = async (data: FormData) => {
        if (!vaultId) {
            toast.error("vaultId is undefined")
            return
        }
        localStorage.setItem(STORAGE_KEY_SLIPPAGE, `${data.slippage}`)
        const slippage = Big(data.slippage).div(100)
        const redeemAmount = await previewRedeem(vaultId, Big(data.amount), slippage)
        if (redeemAmount) {
            toast.loading("Preview redeeming:  " + redeemAmount.toFixed(4) + " shares", {
                id: "previewing",
            })
            setPreviewAmount(redeemAmount.toFixed(10))
            toast.dismiss("previewing")
        } else {
            setPreviewAmount("—")
        }
    }

    // We use react-hook-form's watch to watch the amount input value and update the preview amount
    const userInputAmount = watch("amount")
    const userInputSlippage = watch("slippage")
    useEffect(() => {
        if (!userInputAmount) {
            setPreviewAmount("—")
            return
        }
        const data = { amount: userInputAmount, slippage: Number(userInputSlippage) }
        handlePreviewRedeem(data)
    }, [userInputAmount, userInputSlippage])

    // fetchVaults to check cooldown
    // if you don't run this, the user will not see if he can withdraw or not
    const { data: signer } = useWalletClient()
    useEffect(() => {
        fetchVaults()
    }, [fetchVaults, signer])

    // Note: Handle cooldown in count-down component
    // redeem button state
    const [disableRedeem, setDisableRedeem] = useState(false)

    // slippage control

    const [showSlippage, setShowSlippage] = useState(false)

    // make form editable again (reset) after tx success or failed
    const { status } = useNotifStore(
        state => ({
            status: state.status,
        }),
        shallow,
    )
    useEffect(() => {
        if (status === "success" || status === "error") {
            reset(
                { amount: "" },
                {
                    keepValues: true,
                    keepIsSubmitted: false,
                },
            )
        }
    }, [reset, status])

    return (
        <form onSubmit={handleSubmit(handleRedeem)}>
            <MotionStack p="6" gap="4">
                <Text css={{ mb: 8 }} size="title3">
                    Withdraw
                </Text>
                <MotionBox layout="position" id="deposit" css={{ flex: 1, flexDirection: "column", gap: 4 }}>
                    <Box
                        as="label"
                        css={{
                            position: "relative",
                        }}
                    >
                        <Text
                            css={{
                                position: "absolute",
                                left: 16,
                                top: 16,
                                display: "inline-flex",
                                gap: 4,
                                borderRadius: "$xl",
                                bg: "black",
                                color: "white",
                                p: "$2",
                            }}
                            size="label"
                        >
                            <ShareIcon />
                            Shares
                        </Text>

                        <Box
                            css={{
                                position: "absolute",
                                left: 16,
                                bottom: 16,
                                display: "inline-flex",
                                gap: 4,
                            }}
                        >
                            <Text size="label">
                                Shares:{" "}
                                {vault
                                    ? vault.positionShares.toNumber() === 0
                                        ? vault.positionShares.toFixed(2)
                                        : vault.positionShares.toNumber() < 0.0001
                                        ? "<0.0001"
                                        : vault.positionShares.toNumber() >= 0.0001
                                        ? vault.positionShares.toFixed(4)
                                        : "—"
                                    : "—"}
                            </Text>
                            <Text
                                size="label"
                                css={{
                                    color: "#33C790",
                                    cursor: "pointer",
                                }}
                                // set max redeemable shares
                                onClick={() => {
                                    if (vaultData.shares === undefined) {
                                        toast.error("vaultData.shares is undefined")
                                        return
                                    } else {
                                        setValue("amount", vaultData.shares)
                                    }
                                }}
                            >
                                MAX
                            </Text>
                        </Box>
                        <BaseInput
                            css={{
                                width: "100%",
                                paddingLeft: 110,
                                paddingRight: 16,
                                paddingTop: 16,
                                paddingBottom: 60,
                            }}
                            step={"any"}
                            className={clsx(
                                // add a class to the input if there is an error
                                !!errors.amount && "error",
                            )}
                            {...register("amount", {
                                required: "This is required",
                                min: {
                                    value: 0,
                                    message: `Please enter amount greater than 0`,
                                },
                                max: {
                                    value: Number(vaultData.shares),
                                    message: `Max ${vaultData.shares}`,
                                },
                            })}
                            type="number"
                            placeholder={`0.0`}
                        />
                        <Box
                            css={{
                                position: "absolute",
                                right: 16,
                                bottom: 16,
                                display: "inline-flex",
                                gap: 4,
                            }}
                        >
                            <MotionBox
                                initial={{ rotate: 0 }}
                                animate={{ rotate: showSlippage ? 270 : 0 }}
                                transition={{ duration: 0.4 }}
                                onClick={() => {
                                    setShowSlippage(!showSlippage)
                                }}
                                css={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <LucideIcon name="settings" size={20} />
                            </MotionBox>
                        </Box>
                    </Box>
                    <Text size="label" css={{ color: "$red9" }}>
                        {errors.amount?.message}
                    </Text>
                </MotionBox>

                {showSlippage && (
                    <MotionBox id="slippage" css={{ flex: 1, flexDirection: "column", gap: 4 }}>
                        {" "}
                        <Box
                            css={{
                                position: "relative",
                            }}
                        >
                            <Box
                                css={{
                                    color: "$baseText",
                                    position: "absolute",
                                    left: 20,
                                    top: 16,
                                }}
                            >
                                <Text size="label">Slippage</Text>
                            </Box>
                            <Box
                                css={{
                                    color: "$baseTextTertiary",
                                    position: "absolute",
                                    right: 16,
                                    top: 17,
                                }}
                            >
                                <Text size="label">%</Text>
                            </Box>

                            <BaseInput
                                css={{
                                    width: "100%",
                                    fontSize: "$base",
                                    p: "$4",
                                    pr: 32,
                                    pl: 100,
                                    textAlign: "right",
                                    borderRadius: "$xl",
                                }}
                                step={0.01}
                                type="number"
                                placeholder="Slippage"
                                {...register("slippage", {
                                    required: "This is required",
                                    max: {
                                        value: 30,
                                        message: "Maximum slippage is 30%",
                                    },
                                    min: {
                                        value: 0.02,
                                        message: "Minimum slippage is 0.02%",
                                    },
                                })}
                                className={clsx(
                                    // add a class to the input if there is an error
                                    !!errors.slippage && "error",
                                )}
                            />
                        </Box>
                        <Text size="label" css={{ color: "$red9" }}>
                            {errors?.slippage?.message}
                        </Text>
                    </MotionBox>
                )}

                <Box
                    css={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        css={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "$full",
                            bg: "#00DF7C",
                            color: "white",
                            boxSize: 36,
                        }}
                    >
                        <LucideIcon name="arrow-down" size={24} />
                    </Box>
                </Box>
                <MotionBox css={{ flex: 1, flexDirection: "column", gap: 4 }}>
                    <Box
                        css={{
                            position: "relative",
                        }}
                    >
                        <Box
                            css={{
                                color: "$baseText",
                                position: "absolute",
                                left: 20,
                                top: 16,
                            }}
                        >
                            <Text size="label">
                                You Receive
                                <Tooltip
                                    label={
                                        <Box css={{ maxWidth: 320 }}>
                                            The actual amount received may vary due to on-chain activities. If the
                                            amount seems too low, please wait a while and try again.
                                        </Box>
                                    }
                                >
                                    <Box css={{ position: "relative", top: 3, ml: 4 }}>
                                        <LucideIcon name="info" size={16} />
                                    </Box>
                                </Tooltip>
                            </Text>
                        </Box>
                        <Box
                            css={{
                                color: "$baseTextTertiary",
                                position: "absolute",
                                right: 16,
                                top: 17,
                            }}
                        >
                            <Text size="label">{vaultData.depositAsset?.symbol}</Text>
                        </Box>

                        <BaseInput
                            css={{
                                width: "100%",
                                fontSize: "$base",
                                p: "$4",
                                pr: "3.8em",
                                pl: 100,
                                textAlign: "right",
                                borderRadius: "$xl",
                            }}
                            type="number"
                            value={previewAmount}
                            readOnly
                        />
                    </Box>
                </MotionBox>

                <MotionBox layout="position">
                    <BrandButton
                        css={{ width: "100%", justifyContent: "center" }}
                        brand={"normal"}
                        size="l"
                        disabled={disableRedeem}
                        type={"submit"}
                    >
                        Withdraw
                    </BrandButton>
                </MotionBox>
            </MotionStack>
            {/* form helper message */}
            {vaultData.lastMintedAt && vaultData.transferCooldown && (
                <MotionBox
                    css={{
                        background: disableRedeem ? "#E55E49" : "#00DF7C",
                        color: disableRedeem ? "white" : "black",
                    }}
                >
                    <Box
                        css={{
                            textAlign: "center",
                            p: "$4",
                            pb: "$3",
                            mb: "-1px",
                        }}
                    >
                        <Text>
                            <Countdown
                                key={"timer-key"}
                                date={vaultData.lastMintedAt.toMillis() + vaultData.transferCooldown * 1000}
                                renderer={({ minutes, seconds, completed }) => {
                                    // You can redeem
                                    if (completed) {
                                        setDisableRedeem(false)
                                        return <span>Ready to withdraw</span>
                                    } else {
                                        // Wait for cooldown
                                        setDisableRedeem(true)
                                        return (
                                            <span>
                                                <Box
                                                    css={{
                                                        display: "inline-block",
                                                        verticalAlign: "text-top",
                                                    }}
                                                >
                                                    <LucideIcon size={18} name="clock" />
                                                </Box>{" "}
                                                Withdrawal ready in: {zeroPad(minutes)}:{zeroPad(seconds)}
                                            </span>
                                        )
                                    }
                                }}
                            />
                        </Text>
                    </Box>
                </MotionBox>
            )}
        </form>
    )
}

export default Redeem

const ShareIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.75 5C7.51387 5 6.3055 5.36656 5.27769 6.05332C4.24988 6.74007 3.4488 7.71619 2.97576 8.85823C2.50271 10.0003 2.37894 11.2569 2.62009 12.4693C2.86125 13.6817 3.45651 14.7953 4.33059 15.6694C5.20466 16.5435 6.31831 17.1388 7.53069 17.3799C8.74307 17.6211 9.99974 17.4973 11.1418 17.0242C12.2838 16.5512 13.2599 15.7501 13.9467 14.7223C14.6334 13.6945 15 12.4861 15 11.25H8.75V5Z"
                fill="#3CEAAA"
            />
            <path
                d="M11.25 8.75H17.5C17.5 7.0924 16.8415 5.50268 15.6694 4.33058C14.4973 3.15848 12.9076 2.5 11.25 2.5V8.75Z"
                fill="#03BCE4"
            />
        </svg>
    )
}
