import { BrandButton } from "@sayaww/nomimono"
import { motion } from "framer-motion"
import React, { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { shallow } from "zustand/shallow"
import { useMailModoStore } from "../service/mailmodo/mailModoStore"
import { AirplaneIcon } from "./CustomIcons"
import { BaseInput } from "./Input"

export function SignUpForm() {
    const [email, setEmail] = useState("")
    const [sendIt, setSendIt] = useState(false)

    const handleChange = (e: { preventDefault: () => void; target: { value: React.SetStateAction<string> } }) => {
        e.preventDefault()
        setEmail(e.target.value)
    }

    const { subscriptionResultMap, submitEmail } = useMailModoStore(
        state => ({
            submitEmail: state.submitEmail,
            subscriptionResultMap: state.subscriptionResultMap,
        }),
        shallow,
    )
    useEffect(() => {
        if (subscriptionResultMap[email] === "subscribed") {
            toast.success("Signed up successfully!")
        }
    }, [subscriptionResultMap])

    const submitForm = (e: { preventDefault: () => void }) => {
        e.preventDefault()

        if (email !== "") {
            setSendIt(true)
            submitEmail(email)
        }
    }

    return (
        <form onSubmit={submitForm}>
            <BaseInput
                css={{
                    fontSize: "$md",
                    width: "100%",
                    textAlign: "left",
                    mb: "$4",
                }}
                type="email"
                name="email"
                placeholder="Email"
                onChange={handleChange}
            />

            <BrandButton
                leftIcon={
                    <motion.div
                        style={{ width: "24px", height: "24px" }}
                        animate={{
                            x: sendIt ? 800 : 0,
                        }}
                        transition={{
                            duration: 1,
                        }}
                    >
                        <AirplaneIcon />
                    </motion.div>
                }
                css={{
                    width: "100%",
                    justifyContent: "center",
                    color: "white",
                    bg: "black",
                    h: 50,
                    border: "1px solid white",
                    cursor: "pointer",
                    "&:hover": {
                        bg: "black",
                    },
                }}
                type="submit"
                disabled={sendIt}
            >
                {sendIt ? (
                    <motion.div
                        initial={{
                            opacity: 0,
                            y: 80,
                        }}
                        animate={{
                            y: sendIt ? 0 : 80,
                            opacity: sendIt ? 1 : 0,
                            transition: {
                                delay: 0.8,
                            },
                        }}
                        style={{ paddingTop: "3px", paddingRight: "24px" }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
                            <path
                                fillRule="evenodd"
                                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </motion.div>
                ) : (
                    "Subscribe"
                )}
            </BrandButton>
        </form>
    )
}
