import { TransactionReceipt } from "@ethersproject/providers"
import { AnalyticsBrowser } from "@segment/analytics-next"
import { SEGMENT_API_KEY } from "src/constants"
import { sentryLog } from "../sentry/SentryLog"

/**
 * NOTE:
 * eventGroup
 * eventType
 * eventName: UID for the event.
 * description: In case multiple instance exists on the same url, add this to differentiate.
 * payload: Custom fields for the event.
 */
export interface AnalyticsTrackData {
    eventGroup: EventGroup
    eventType?: EventTypeContract
    eventName: EventName
    version?: { [key: string]: string }
    description?: string
    payload?: { [key: string]: string | number | boolean }
    receipt?: TransactionReceipt
}

export interface AnalyticsPageData {
    [key: string]: string
}

export enum EventGroup {
    CONTRACT = "CONTRACT", // NOTE: for things that happens on-chain.
    INTERACTION = "INTERACTION", // NOTE: for things that user does to or perceived from the UI.
}

export enum EventTypeContract {
    TX_SUCCEEDED = "TX_SUCCEEDED",
    TX_FAILED = "TX_FAILED",
}

export enum EventName {
    FIX_ANONYMOUS_ID = "FIX_ANONYMOUS_ID",

    WALLET_CONNECTED = "WALLET_CONNECTED",
    BUTTON_CLICKED = "BUTTON_CLICKED",
    OPEN_NOTIFI_WIDGET = "OPEN_NOTIFI_WIDGET",
    GO_TO_SHOWER_ROOM = "GO_TO_SHOWER_ROOM",
    GO_TO_HOTTUB = "GO_TO_HOTTUB",
    SPEC_DISPLAYED = "SPEC_DISPLAYED",
    HOTTUB_SIGNUP_VAULT = "HOTTUB_SIGNUP_VAULT",
    SUBSCRIBE_EMAIL = "SUBSCRIBE_EMAIL",
    REGISTER_EMAIL = "REGISTER_EMAIL",

    // HOT TUB
    APPROVE = "APPROVE",
    DEPOSIT = "DEPOSIT",
    WITHDRAW = "WITHDRAW",

    // SHOWER ROOM
    SHOWER_ROOM_QUEUE_HINT_DISPLAYED = "SHOWER_ROOM_QUEUE_DISPLAYED",
    APPROVE_SHOWER_ROOM = "APPROVE_SHOWER_ROOM",
    DEPOSIT_SHOWER_ROOM = "DEPOSIT_SHOWER_ROOM",
    WITHDRAW_SHOWER_ROOM = "WITHDRAW_SHOWER_ROOM",
}

// noinspection JSIgnoredPromiseFromCall
class SegmentService {
    readonly analytics?: AnalyticsBrowser

    constructor(readonly apiKey: string) {
        if (!this.apiKey) {
            sentryLog.msg("Segment writeKey is not provided.", "warning")
            return
        }
        this.analytics = AnalyticsBrowser.load({ writeKey: this.apiKey })
        this.tryFixAnonymousId()
    }

    identify(account: string) {
        if (!this.analytics) {
            return
        }
        this.analytics.identify(account)
    }

    track(data: AnalyticsTrackData) {
        if (!this.analytics) {
            return
        }
        const { eventGroup, ...rest } = data
        this.analytics.track(eventGroup, { ...rest })
    }

    page(data?: AnalyticsPageData) {
        if (!this.analytics) {
            return
        }
        this.analytics.page({ ...data })
    }

    /**
     * NOTE: This cleans up the anonymous_id in local storage if it is different from the one in the cookie.
     * They are normally kept in-sync by the SDK but is made possible due to incorrectly calling `analytics.reset()` previously.
     * (PR that fixed it: https://github.com/perpetual-protocol/perp-exchange/pull/927/files)
     *
     * Calling `reset()` regenerates the anonymous_id in both cookie and local storage but since cookie is shared across subdomains,
     * the anonymous_id in cookie and local storage will be different when the cookie is updated by another subdomain.
     *
     * Since when the anonymous_id does not exist in local storage the SDK will sync the anonymous_id from the cookie,
     * by clearing it from the local storage we can ensure the anonymous_id across all subdomains will eventually be synced via the shared cookie.
     */
    private tryFixAnonymousId() {
        const cookie_anonymous_id = document.cookie
            .split("; ")
            .find(row => row.startsWith("ajs_anonymous_id="))
            ?.split("=")[1]

        const local_storage_anonymous_id = localStorage.getItem("ajs_anonymous_id")?.replace(/^"(.*)"$/, "$1")

        const id_diff = cookie_anonymous_id !== local_storage_anonymous_id

        if (id_diff) {
            localStorage.removeItem("ajs_anonymous_id")
            this.track({
                eventGroup: EventGroup.INTERACTION,
                eventName: EventName.FIX_ANONYMOUS_ID,
                payload: {
                    cookie_anonymous_id: `${cookie_anonymous_id}`,
                    local_storage_anonymous_id: `${local_storage_anonymous_id}`,
                },
            })
        }
    }
}

export const segmentService = new SegmentService(SEGMENT_API_KEY)
