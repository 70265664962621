import { Draft } from "immer"

export type ZustandGet<State> = () => State
export type ZustandSet<State> = (partial: (state: Draft<State>) => void, replace?: boolean) => void

export interface LoadableState {
    loading: boolean
}

export class ZustandTask<T> {
    constructor(
        readonly get: ZustandGet<T>,
        readonly set: ZustandSet<T>,
        readonly func: (get: ZustandGet<T>, set: ZustandSet<T>, ...args: any[]) => Promise<void>,
        readonly args: any[],
    ) {}
}

/**
 * ZustandLoadingGuardBase
 * A STORE CAN ONLY USE THE SAME TYPE OF ZS TASK MANAGER.
 */
abstract class ZustandLoadingGuardBase<T extends LoadableState> {
    protected readonly tasks: ZustandTask<T>[] = []

    protected abstract addTask(task: ZustandTask<T>): void

    async warp(
        get: ZustandGet<T>,
        set: ZustandSet<T>,
        func: (get: ZustandGet<T>, set: ZustandSet<T>, ...args: any[]) => Promise<void>,
        ...args: any[]
    ) {
        this.addTask(new ZustandTask(get, set, func, args))
        if (!get().loading) {
            set(state => {
                state.loading = true
            })
            try {
                await this.startEventLoop()
            } finally {
                set(state => {
                    state.loading = false
                })
            }
        }
    }

    /**
     * Task event loop
     * call by reference to check the tasks.length
     * only do the tasks shift once the func call is done
     */
    private async startEventLoop() {
        while (this.tasks.length > 0) {
            const task = this.tasks[0]
            await task.func(task.get, task.set, ...task.args)
            this.tasks.shift()
        }
    }
}

// one zs store needs one manager instance, all tasks will use the same loading state
export class ZustandLoadingGuardFirst<T extends LoadableState> extends ZustandLoadingGuardBase<T> {
    /**
     * Add task to the queue
     * only push the task to the queue if the queue is empty
     * @param task
     */
    addTask(task: ZustandTask<T>) {
        if (this.tasks.length === 0) {
            this.tasks.push(task)
        }
    }
}

// one zs store function needs one manager instance, one task will use one loading state
export class ZustandLoadingGuardOverride<T extends LoadableState> extends ZustandLoadingGuardBase<T> {
    /**
     * Add task to the queue
     * only push the task to the queue if the queue is empty or only has one task
     * the max length is 2, it will replace the last task in the queue,
     * @param task
     */
    addTask(task: ZustandTask<T>) {
        if (this.tasks.length <= 1) {
            this.tasks.push(task)
        } else if (this.tasks.length === 2) {
            this.tasks[1] = task
        }
    }
}

// one zs store with one manager instance, all tasks will use the same loading state
export class ZustandLoadingGuardSequence<T extends LoadableState> extends ZustandLoadingGuardBase<T> {
    /**
     * Add task to the queue
     * @param task
     */
    addTask(task: ZustandTask<T>) {
        this.tasks.push(task)
    }
}
