import { Box } from "@sayaww/nomimono"
import React from "react"

interface EtherscanLinkProps {
    hash: string
    children: React.ReactNode
}

export function EtherscanLink({ hash, children }: EtherscanLinkProps) {
    return (
        <Box
            as="a"
            css={{ color: "black", textDecoration: "none", fontWeight: 400 }}
            href={`https://optimistic.etherscan.io/tx/${hash}`}
            target="_blank"
            rel="noreferrer noopener"
        >
            {children} <span style={{ opacity: 0.65 }}>→</span>
        </Box>
    )
}
