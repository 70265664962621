import Airtable from "airtable"
import React, { useEffect, useState } from "react"

interface BannerData {
    content?: string
    linkText?: string
    url?: string
}

// This is a banner controlled by Airtable. It will show up on top of the app.
// Edit content here: https://airtable.com/app4q6AcYwEAy2x3h/tblTahtct0f8idQ33/viwRRmLHHFNK1cpYD?blocks=hide

// Airtable client
const base = new Airtable({
    apiKey: "patYagbGzcAahrwqT.f2e138b7e3eacd943c87b9b29620cd5f3186ac37edc786e128934793b0fa930f",
}).base("app4q6AcYwEAy2x3h")
export const TopAppBanner = () => {
    // Airtable setup
    // This is a public key with limited scope, no need to hide
    // Get API info: https://airtable.com/app4q6AcYwEAy2x3h/api/docs#curl/table:token%20top%20banner

    // Create a state variable to store the fetched data
    const [bannerData, setBannerData] = useState<BannerData>({ content: "", linkText: "", url: "" })

    // Set visibility
    const [showBanner, setShowBanner] = React.useState(false)

    // When the component mounts, fetch data from Airtable
    useEffect(() => {
        const getBannerData = () => {
            base("Hot Tub Top Banner")
                .select({
                    view: "Grid view",
                })
                .firstPage(function (err, records) {
                    if (err) {
                        console.error(err)
                        return
                    }
                    records?.forEach(function (record) {
                        // Set the fetched data to the state variable
                        setBannerData({
                            content: record?.get("Content") as string | undefined,
                            linkText: record?.get("Link Text") as string | undefined,
                            url: record?.get("URL") as string | undefined,
                        })
                    })
                })
        }
        getBannerData()
    }, [base])

    // If data is fetched, show the banner by default
    useEffect(() => {
        const handleBanner = () => {
            if (bannerData.content !== "") {
                setShowBanner(true)
            } else {
                setShowBanner(false)
            }
        }
        handleBanner()
    }, [bannerData.content])

    if (showBanner && bannerData.content !== "")
        return (
            <div
                style={{
                    gap: 8,
                    color: "black",
                    backgroundColor: "#FFB459",
                    width: "100%",
                    padding: 12,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 1,
                }}
            >
                <div style={{ flex: "1", textAlign: "center" }}>
                    <span>{bannerData.content}</span>
                    {bannerData.url && bannerData.linkText && (
                        <a
                            rel="noreferrer"
                            href={bannerData.url}
                            style={{ color: "black", textDecoration: "underline", marginLeft: "8px" }}
                            target="_blank"
                        >
                            {bannerData.linkText}
                        </a>
                    )}
                </div>

                <div
                    style={{ cursor: "pointer", justifyContent: "flex-end" }}
                    onClick={() => {
                        setShowBanner(false)
                    }}
                >
                    <CloseButton />
                </div>
            </div>
        )
    return null
}

const CloseButton = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <line x1="18" x2="6" y1="6" y2="18"></line>
            <line x1="6" x2="18" y1="6" y2="18"></line>
        </svg>
    )
}
