import Big from "big.js"
import { Signer } from "ethers"
import { DateTime } from "luxon"
import { VaultToken, VaultToken__factory } from "../../typechain"
import { logMethod } from "../../util/log"
import { bigNum2Big } from "../../util/number"
import type { Erc20Meta } from "../eth/Erc20Client"
import { providerManager } from "../eth/ProviderManager"

export class VaultTokenClient {
    @logMethod
    async getTransferCooldown(shareAsset: Erc20Meta): Promise<number> {
        const vaultToken = await this.getVaultToken(shareAsset.addr)
        return vaultToken.callStatic.getTransferCooldown()
    }

    @logMethod
    async getTotalSupplyCap(shareAsset: Erc20Meta): Promise<Big> {
        const vaultToken = await this.getVaultToken(shareAsset.addr)
        return vaultToken.callStatic.getTotalSupplyCap().then(it => bigNum2Big(it, shareAsset.decimal))
    }

    @logMethod
    async getTotalSupply(shareAsset: Erc20Meta): Promise<Big> {
        const vaultToken = await this.getVaultToken(shareAsset.addr)
        return vaultToken.callStatic.totalSupply().then(it => bigNum2Big(it, shareAsset.decimal))
    }

    @logMethod
    async getShares(shareAsset: Erc20Meta, owner: string): Promise<Big> {
        const vaultToken = await this.getVaultToken(shareAsset.addr)
        return vaultToken.callStatic.balanceOf(owner).then(it => bigNum2Big(it, shareAsset.decimal))
    }

    @logMethod
    async getLastMintedAt(shareAsset: Erc20Meta, owner: string): Promise<DateTime | undefined> {
        const vaultToken = await this.getVaultToken(shareAsset.addr)
        const lastMintedAt = await vaultToken.getLastMintedAt(owner)
        if (lastMintedAt.isZero()) {
            return undefined
        }
        return DateTime.fromSeconds(lastMintedAt.toNumber())
    }

    private async getVaultToken(contractAddr: string, signer?: Signer): Promise<VaultToken> {
        const provider = await providerManager.getProvider()
        return VaultToken__factory.connect(contractAddr, signer ? signer : provider)
    }
}

export const vaultTokenClient = new VaultTokenClient()
