/****************
 * env constant *
 ****************/
export const NODE_ENV = process.env.NODE_ENV
export const STAGE = process.env.REACT_APP_STAGE!
export const RPC_URL = process.env.REACT_APP_RPC_URL!

/***************************
 * env constant - services *
 ***************************/
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN!
export const SEGMENT_API_KEY = process.env.REACT_APP_SEGMENT_API_KEY!
export const FLAGSMITH_ENV_ID = process.env.REACT_APP_FLAGSMITH_ENV_ID!

/**************************
 * env constant - testing *
 **************************/
export const TEST_WALLET_PK = process.env.REACT_APP_TEST_WALLET_PK!
export const TEST_WALLET_ADDR = "0xFc947bfa5540A7F63d3Df050E47AB5Cc7fb4EBD7"

/********
 * enum *
 ********/
export const enum Stage {
    Production = "production",
    Staging = "staging", // staging env ≈ test env ≈ development env
    Test = "test", // staging env ≈ test env ≈ development env
}
