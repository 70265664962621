import { getParsedEthersError, ReturnValue, RETURN_VALUE_ERROR_CODES } from "@enzoferey/ethers-error-parser"

export interface ContractError extends Error {
    reason?: string
    code?: string
    transactionHash?: string
}

export class ErrorParser {
    private readonly messages: Record<string, string> = {
        [RETURN_VALUE_ERROR_CODES.REJECTED_TRANSACTION]: "Transaction rejected ",
    }

    parse(err: unknown): { message: string; hash?: string } {
        if (this.isContractError(err)) {
            const parsedEthersError = getParsedEthersError(err)
            return {
                message: this.getErrorMessage(parsedEthersError),
                hash: err.transactionHash,
            }
        }

        if (this.isError(err)) {
            return {
                message: err.message,
            }
        }

        if (typeof err === "string") {
            return {
                message: err,
            }
        }

        return {
            // message: JSON.stringify(err),
            message: "Unknown error, please try again later",
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isError(err: any): err is Error {
        return err.message
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isContractError(err: any): err is ContractError {
        return err.code && err.reason
    }

    getErrorMessage(parsedEthersError: ReturnValue): string {
        const customizedMessage = this.messages[parsedEthersError.errorCode]
        const defaultMessage = `${parsedEthersError.context} (${parsedEthersError.errorCode})`
        return customizedMessage || defaultMessage
    }
}

export const errorParser = new ErrorParser()
