import { BrandButton, Text } from "@sayaww/nomimono"
import React, { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useAccount } from "wagmi"
import { shallow } from "zustand/shallow"
import { useMailModoStore } from "../service/mailmodo/mailModoStore"
import { BaseInput } from "./Input"

export function EmailRegister() {
    const [email, setEmail] = useState("")
    const { address } = useAccount()
    const handleChange = (e: { preventDefault: () => void; target: { value: React.SetStateAction<string> } }) => {
        e.preventDefault()
        setEmail(e.target.value)
    }

    const { subscriptionResultMap, register } = useMailModoStore(
        state => ({
            register: state.register,
            subscriptionResultMap: state.subscriptionResultMap,
            checkRegister: state.checkRegister,
        }),
        shallow,
    )
    useEffect(() => {
        if (subscriptionResultMap[email] === "subscribed") {
            toast.success("Signed up successfully!")
        }
    }, [subscriptionResultMap])

    const submitForm = (e: { preventDefault: () => void }) => {
        e.preventDefault()

        if (email !== "" && address) {
            register(address, email)
        }
    }

    return (
        <form onSubmit={submitForm}>
            <Text css={{ mb: "$4", color: "$gray9" }} size="label">
                Your email is needed for transaction updates. You can unsubscribe anytime!
            </Text>

            <BaseInput
                css={{
                    fontSize: "$md",
                    width: "100%",
                    textAlign: "left",
                    mb: "$4",
                }}
                type="email"
                name="email"
                placeholder="Email"
                onChange={handleChange}
            />

            <BrandButton
                css={{
                    width: "100%",
                    mb: "$1",
                    justifyContent: "center",
                    color: "white",
                    bg: "black",
                    h: 50,
                    border: "1px solid white",
                    cursor: "pointer",
                    "&:hover": {
                        bg: "black",
                    },
                }}
                type="submit"
            >
                Register
            </BrandButton>
        </form>
    )
}
