import { Box, Text } from "@sayaww/nomimono"
import React from "react"
import { Tooltip } from "./Tooltip"
// TODO: move to nomimono lib later

export const Stat = ({
    label,
    labelColor,
    isLabelUnderline,
    value,
    subValue,
    tooltip,
    formatNumber,
    bottomValue,
    labelIcon,
    color,
}: {
    label: React.ReactNode
    labelColor?: string
    isLabelUnderline?: boolean
    value: React.ReactNode
    subValue?: React.ReactNode
    tooltip?: React.ReactNode
    bottomValue?: React.ReactNode
    labelIcon?: React.ReactNode
    color?: string
    // formatNumber: this only work when you pass a number to the value or subValue prop
    // if you need additional formatting, you can use the nFormatter function outside of this component and turn off the formatNumber prop
    formatNumber?: boolean
}) => {
    // A polymorphic component for Tooltip container

    const Comp = tooltip ? Tooltip : "div"

    return (
        <Box
            css={{
                mb: 32,
                "@sm": {
                    mb: 16,
                },
            }}
        >
            {/* conditianlly render tooltip */}

            <Comp label={tooltip}>
                <>
                    <Text
                        size={{
                            "@initial": "label",
                            "@sm": "body",
                        }}
                        css={{
                            display: "inline-flex",
                            gap: 6,
                            alignItems: "center",
                            textDecoration: isLabelUnderline ? "underline" : "normal",
                            color: labelColor ? labelColor : "$baseTextSecondary",
                        }}
                    >
                        {label}
                        {labelIcon}
                    </Text>
                    <Text
                        size={{
                            "@initial": "title3",
                            "@sm": "title2",
                        }}
                        css={{
                            whiteSpace: "nowrap",
                            color: color ? color : "$baseText",
                            lineHeight: 1,
                            overflowWrap: "anywhere",
                        }}
                    >
                        {formatNumber ? nFormatter(Number(value), 2) : value}
                        <Text
                            css={{ ml: "0.25em", color: "$baseTextTertiary" }}
                            as="span"
                            size={{
                                "@initial": "overline",
                                "@sm": "body-bold",
                            }}
                        >
                            {formatNumber ? nFormatter(Number(subValue), 2) : subValue}
                        </Text>
                    </Text>

                    {bottomValue && (
                        <Text css={{ pt: "0.25em", color: "$baseTextTertiary" }} size={"label"}>
                            {bottomValue}
                        </Text>
                    )}
                </>
            </Comp>
        </Box>
    )
}

// use the nFormatter function to format large numbers or use it as a function outside of this component

export function nFormatter(num: number, digits: number | undefined) {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" },
    ]
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    const item = lookup
        .slice()
        .reverse()
        .find(function (item) {
            return num >= item.value
        })
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0"
}
