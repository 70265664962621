import { Bento, Text } from "@sayaww/nomimono"
import React from "react"
import { useRouteError } from "react-router-dom"

export default function ErrorPage() {
    const error = useRouteError()
    console.error(error)

    return (
        <Bento>
            <Text size="title1">Oops!</Text>
            <Text size="body">Sorry, an unexpected error has occurred.</Text>
        </Bento>
    )
}
