import { RichText } from "@graphcms/rich-text-react-renderer"
import { RichTextContent } from "@graphcms/rich-text-types"
import * as Tabs from "@radix-ui/react-tabs"
import { Box, BrandButton, Dialog, IconButton, LucideIcon, Text } from "@sayaww/nomimono"
import { ConnectKitButton } from "connectkit"
import { Squircle } from "corner-smoothing"
import { motion } from "framer-motion"
import { useAtom } from "jotai"
import _ from "lodash"
import React, { Suspense, useState } from "react"
import { Await, useParams } from "react-router-dom"
import { WalletIcon } from "src/components/CustomIcons"
import { EmailRegister } from "src/components/EmailRegister"
import { NomiStyle } from "src/components/NomiStyle"
import { QueueStatus } from "src/components/QueueStatus"
import { Spinner } from "src/components/Spinner"
import { Stat } from "src/components/Stat"
import { useMailModoStore } from "src/service/mailmodo/mailModoStore"
import { EventGroup, EventName, segmentService } from "src/service/segment/SegmentService"
import { openVaultBg } from "src/util/uiAtom"
import { usePreviewRedeem } from "src/util/usePreviewRedeem"
import { shallow } from "zustand/shallow"
import { FullChart } from "../../components/FullChart"
import { VaultStats } from "../../components/VaultStats"
import DepositOrQueue from "./DepositOrQueue"
import Redeem from "./Redeem"
import { useVaultDetail } from "./useVaultDetail"

export const MotionBox = motion(Box)

function VaultDetail() {
    const { vaultSlug } = useParams()
    const {
        vaultCMS,
        vault,
        data,
        accentColor,
        subAccentColor,
        sevenDayApr,
        thirtyDayApr,
        allTimeApr,
        isConnected,
        address,
        showFullChart,
        setShowFullChart,
        shouldGoToShowerRoom,
    } = useVaultDetail(vaultSlug)

    const sharePriceExact = vault?.sharePrice.toFixed()
    const sharePrice = vault?.sharePrice.toFixed(4) || "1"

    // if vault cap is 0, then disable buttons
    const isCapZero = vault?.totalSupplyCap.eq(0)

    // control vault open/close
    const [open, setOpen] = useAtom(openVaultBg)

    // Est. Withdrawal Amount
    const { previewAmount } = usePreviewRedeem(vault?.id, vault?.positionShares.toString())

    // Check register
    const { checkRegister, hasRegistered, isChecking } = useMailModoStore(
        state => ({
            hasRegistered: state.hasRegistered,
            checkRegister: state.checkRegister,
            isChecking: state.isChecking,
        }),
        shallow,
    )

    React.useEffect(() => {
        if (address === undefined) return
        checkRegister(address as string)
    }, [checkRegister, address])

    return (
        <div>
            {/* Card: Vault Stats */}
            <VaultStats
                vault={vault}
                showFullChart={showFullChart}
                vaultCMS={vaultCMS}
                setShowFullChart={setShowFullChart}
                thirtyDayApr={thirtyDayApr === "0.00" || !thirtyDayApr ? "•_•" : thirtyDayApr}
                sevenDayApr={sevenDayApr === "0.00" || !sevenDayApr ? "•_•" : sevenDayApr}
                allTimeApr={allTimeApr}
                sharePriceExact={sharePriceExact}
                data={data}
                sharePrice={sharePrice}
                subAccentColor={subAccentColor}
                accentColor={accentColor}
                maxDepositAmount={vault?.remainingDepositAmount}
                isCapZero={isCapZero}
            />

            <div className="w-full bg-[#f9f8f9]">
                <div className="container gap-14 flex flex-col lg:flex-row h-auto p-10 pb-[100px] mx-auto max-w-[1110px] mb-[80px]">
                    <div className="flex-grow order-0 lg:order-2">
                        <div className="lg:sticky top-10 lg:w-[380px]">
                            <CardWrapper
                                css={{
                                    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.05)",
                                    borderRadius: "30px",
                                }}
                            >
                                <CardTitle address={address} vaultTokenAddr={data.vaultTokenAddr} />
                                {shouldGoToShowerRoom && <QueueStatus />}
                                {/* Content */}
                                {!isConnected && (
                                    <Box
                                        css={{
                                            flex: 1,
                                            gap: 24,
                                            px: 40,
                                            pt: 24,
                                            pb: 40,
                                            // h: "100%",
                                            // flexGrow: 1,
                                            flexDirection: "column",
                                            justifyContent: "flex-start",
                                            alignItems: "stretch",
                                        }}
                                    >
                                        <Text>
                                            Connect wallet to continue, depending on the capacity of the vault, you may
                                            be able to deposit directly or join the queue.
                                        </Text>
                                        <Box css={{ flex: 0, gap: "$4" }}>
                                            <Text
                                                css={{
                                                    color: "$blue9",
                                                    display: "inline-flex",
                                                    alignItems: "center",
                                                    gap: "$1",
                                                }}
                                                size="label"
                                                as="a"
                                                href={`https://defillama.com/yields?project=perpetual-protocol`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <span>DefiLlama Stats</span>
                                                <LucideIcon size={16} name="external-link" />
                                            </Text>
                                            <Text
                                                css={{
                                                    color: "$blue9",
                                                    display: "inline-flex",
                                                    gap: "$1",
                                                    alignItems: "center",
                                                }}
                                                size="label"
                                                as="a"
                                                href={`https://optimistic.etherscan.io/token/${data.vaultTokenAddr}`}
                                            >
                                                <span>Etherscan</span>
                                                <LucideIcon size={16} name="external-link" />
                                            </Text>
                                        </Box>
                                        <div style={{ flex: 1 }} />
                                        {/* Scenario 1: User is not connected */}
                                        <ConnectKitButton.Custom>
                                            {({ isConnecting, show }) => {
                                                return (
                                                    <BrandButton
                                                        isLoading={isConnecting}
                                                        onClick={() => {
                                                            segmentService.track({
                                                                eventGroup: EventGroup.INTERACTION,
                                                                eventName: EventName.BUTTON_CLICKED,
                                                                payload: {
                                                                    button: isConnected
                                                                        ? "Wallet Button"
                                                                        : "Connect Wallet",
                                                                    component: "sidebar",
                                                                },
                                                            })
                                                            show?.()
                                                        }}
                                                        leftIcon={<WalletIcon />}
                                                        css={{
                                                            gap: 0,
                                                            cursor: "pointer",
                                                            boxSizing: "border-box",
                                                            justifyContent: "center",
                                                            width: "100%",
                                                            bg: "black",
                                                            color: "white",
                                                            "&:hover": {
                                                                bg: "black",
                                                                color: "white",
                                                            },
                                                        }}
                                                    >
                                                        Connect Wallet
                                                    </BrandButton>
                                                )
                                            }}
                                        </ConnectKitButton.Custom>
                                    </Box>
                                )}

                                {/* Scenario 2: User is connected */}
                                {address && (
                                    <Box
                                        css={{
                                            gap: 16,
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                            p: 24,
                                            "@sm": {
                                                p: 24,
                                            },
                                            "@md": {
                                                p: 40,
                                            },
                                        }}
                                    >
                                        {hasRegistered && !isChecking && (
                                            <>
                                                <Stat
                                                    label={"Est. Max Withdrawal Amount"}
                                                    subValue={data.depositAsset?.symbol}
                                                    value={previewAmount?.toPrecision(6) || "—"}
                                                    tooltip={
                                                        "Estimated max withdrawal amount. Actual amounts may vary due to market conditions."
                                                    }
                                                />
                                                <Stat
                                                    tooltip="How much share you own"
                                                    label={"Shares"}
                                                    bottomValue={`Value: ${vault?.positionShareValue?.toFixed(4)} ${
                                                        data.depositAsset?.symbol
                                                    }`}
                                                    subValue={`(${
                                                        vault
                                                            ? vault.totalSupplyCap.toNumber() !== 0 &&
                                                              vault.positionShares
                                                                  .div(vault.totalSupplyCap)
                                                                  .mul(100)
                                                                  .toFixed(2)
                                                            : "—"
                                                    }% of TVL)`}
                                                    value={
                                                        vault
                                                            ? vault.positionShares.toNumber() === 0
                                                                ? vault.positionShares.toFixed(2)
                                                                : vault.positionShares.toNumber() < 0.0001
                                                                ? "<0.0001"
                                                                : vault.positionShares.toNumber() >= 0.0001
                                                                ? vault.positionShares.toFixed(4)
                                                                : "—"
                                                            : "—"
                                                    }
                                                />
                                                <DepositOrWithdraw
                                                    shouldGoToShowerRoom={shouldGoToShowerRoom}
                                                    isDisabled={isCapZero}
                                                    open={open}
                                                    setOpen={setOpen}
                                                />
                                            </>
                                        )}
                                        {isChecking && (
                                            <Box
                                                css={{
                                                    flex: 1,
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div style={{ height: 48, width: 48 }}>
                                                    <Spinner />
                                                </div>
                                            </Box>
                                        )}
                                        {!hasRegistered && !isChecking && <EmailRegister />}
                                    </Box>
                                )}
                            </CardWrapper>
                        </div>
                    </div>
                    <NomiStyle
                        css={{
                            webkitFontSmoothing: "antialiased",
                            flexGrow: 1,
                        }}
                    >
                        {vaultCMS?.contentAboveTheChart ? (
                            <RichText content={vaultCMS.contentAboveTheChart.raw} />
                        ) : (
                            "Missing contentAboveTheChart"
                        )}

                        <div className="my-6">
                            <FullChart
                                setShowFullChart={setShowFullChart}
                                showFullChart={showFullChart}
                                thirtyDayApr={thirtyDayApr === "0.00" || !thirtyDayApr ? "•_•" : thirtyDayApr}
                                sevenDayApr={sevenDayApr === "0.00" || !sevenDayApr ? "•_•" : sevenDayApr}
                                allTimeApr={allTimeApr}
                            />
                        </div>

                        {vaultCMS?.contentBelowTheChart ? (
                            <RichText content={vaultCMS.contentBelowTheChart.raw} />
                        ) : (
                            "Missing contentBelowTheChart"
                        )}
                    </NomiStyle>
                </div>
            </div>
        </div>
    )
}

function DepositOrWithdraw({
    shouldGoToShowerRoom,
    open,
    setOpen,
    isDisabled,
}: {
    open: boolean
    shouldGoToShowerRoom: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    isDisabled?: boolean
}) {
    // const [defaultTab, setDefaultTab] = useState("deposit")
    const [currentTab, setCurrentTab] = useState("deposit")

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Trigger asChild css={{ outline: "none", border: "none", background: "none" }} disabled={isDisabled}>
                <Box css={{ display: "flex", gap: 0.5 }}>
                    <BrandButton
                        brand="normal"
                        disabled={isDisabled}
                        onClick={() => {
                            setOpen(true)
                            setCurrentTab("deposit")
                        }}
                        size={"l"}
                        css={{
                            gap: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            cursor: "pointer",
                            boxSizing: "border-box",
                            justifyContent: "center",
                            width: "100%",
                            bg: "black",
                            color: "white",
                            "&:hover": {
                                bg: "black",
                                color: "white",
                            },
                        }}
                        leftIcon={
                            <div
                                style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    flexShrink: 0,
                                }}
                            >
                                {shouldGoToShowerRoom ? (
                                    <LucideIcon size={24} name="user-plus" />
                                ) : (
                                    <LucideIcon size={24} name="arrow-down" />
                                )}
                            </div>
                        }
                    >
                        {shouldGoToShowerRoom ? "Queue" : "Deposit"}
                    </BrandButton>
                    <BrandButton
                        disabled={isDisabled}
                        onClick={() => {
                            setOpen(true)
                            setCurrentTab("withdraw")
                        }}
                        size={"l"}
                        css={{
                            gap: 0,
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            cursor: "pointer",
                            boxSizing: "border-box",
                            justifyContent: "center",
                            width: "100%",
                            bg: "black",
                            color: "white",
                            "&:hover": {
                                bg: "black",
                                color: "white",
                            },
                        }}
                        leftIcon={
                            <div
                                style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    flexShrink: 0,
                                }}
                            >
                                <LucideIcon size={24} name="arrow-up" />
                            </div>
                        }
                    >
                        Withdraw
                    </BrandButton>
                </Box>
            </Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay style={{ zIndex: 30 }}>
                    <Dialog.Content asChild>
                        <Squircle
                            cornerRadius={30}
                            cornerSmoothing={0.6}
                            style={{
                                width: "100%",
                                maxWidth: "464px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                backgroundColor: "white",
                            }}
                        >
                            <Dialog.Close asChild>
                                <Box
                                    css={{
                                        position: "absolute",
                                        top: "$4",
                                        right: "$4",
                                        zIndex: "32",
                                    }}
                                >
                                    <IconButton style={{ borderRadius: "9999px" }} name="x" intent="ghost" />
                                </Box>
                            </Dialog.Close>
                            <>
                                <Tabs.Root
                                    onValueChange={value => setCurrentTab(value)}
                                    defaultValue={currentTab}
                                    orientation="horizontal"
                                >
                                    <Tabs.Content value="deposit" asChild>
                                        <DepositOrQueue />
                                    </Tabs.Content>
                                    <Tabs.Content value="withdraw" asChild>
                                        <Redeem />
                                    </Tabs.Content>
                                </Tabs.Root>
                            </>
                        </Squircle>
                    </Dialog.Content>
                </Dialog.Overlay>
            </Dialog.Portal>
        </Dialog.Root>
    )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CardWrapper = ({ bg, css, ...props }: { bg?: string; children?: React.ReactNode; css?: any }) => {
    return (
        <Box
            css={_.merge(
                {
                    boxSizing: "border-box",
                    position: "relative",
                    ...css,
                },
                css,
            )}
        >
            <Squircle
                cornerRadius={30}
                cornerSmoothing={0.6}
                style={{
                    position: "absolute",
                    inset: 0,
                    zIndex: 0,
                    backgroundColor: bg || "white",
                }}
                /* eslint-disable-next-line react/no-children-prop */
                children={undefined}
            />
            <div
                style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    zIndex: 1,
                    display: "flex",
                    alignItems: "stretch",
                    flexDirection: "column",
                    justifyContent: "stretch",
                    justifySelf: "stretch",
                    alignSelf: "stretch",
                }}
            >
                {props.children}
            </div>
        </Box>
    )
}

type CardTitleProps = {
    address: string | undefined
    vaultTokenAddr: string | undefined
}

const CardTitle = ({ address, vaultTokenAddr }: CardTitleProps) => {
    return (
        <Squircle cornerRadius={0} topRightCornerRadius={28} topLeftCornerRadius={28} cornerSmoothing={0.6}>
            <Box
                css={{
                    borderBottom: "1px solid rgba(0,0,0,0.2)",
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    px: 24,
                    py: 20,
                }}
            >
                <Text size={"title3"}>{address ? "My Shares" : "Get Started"}</Text>

                {address && (
                    <Text
                        as="a"
                        target="_blank"
                        href={`https://optimistic.etherscan.io/token/${vaultTokenAddr}?a=${address}`}
                        css={{
                            color: "black",
                            textDecoration: "underline",
                            display: "inline-flex",
                            gap: 4,
                            flexDirection: "row",
                            alignItems: "center",
                            "&:hover": {
                                textDecoration: "underline",
                            },
                        }}
                    >
                        <LucideIcon name="clock" />
                        <span>History</span>
                    </Text>
                )}
            </Box>
        </Squircle>
    )
}

export default VaultDetail
