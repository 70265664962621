import { Box, LucideIcon, Progress, Text } from "@sayaww/nomimono"
import Big from "big.js"
import { Squircle } from "corner-smoothing"
import React from "react"
import { Stat, nFormatter } from "src/components/Stat"
import { KantabanVault } from "src/service/kantaban/KantabanVault"
import { CardWrapper, MotionBox } from "../pages/VaultDetail/VaultDetail"
import { VaultData } from "../pages/VaultDetail/useVaultDetail"
import { AprMiniChart } from "./AprMiniChart"

export function VaultStatsOriginal({
    vault,
    isCapZero,
    showFullChart,
    vaultCMS,
    setShowFullChart,
    thirtyDayApr,
    sevenDayApr,
    allTimeApr,
    sharePriceExact,
    data,
    sharePrice,
    subAccentColor,
    accentColor,
    maxDepositAmount,
}: {
    vault: KantabanVault | undefined
    isCapZero: boolean | undefined
    showFullChart: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    vaultCMS: any
    setShowFullChart: React.Dispatch<React.SetStateAction<boolean>>
    thirtyDayApr: string | undefined
    sevenDayApr: string | undefined
    allTimeApr: string | undefined
    sharePriceExact: string | undefined
    data: VaultData
    sharePrice: string
    subAccentColor: string
    accentColor: string
    maxDepositAmount: Big | undefined
}) {
    return (
        <CardWrapper
            css={{
                // h: "100%",
                alignSelf: "stretch",
                "@sm": {
                    flex: 1,
                },
                "@md": {
                    flex: 2,
                },
                "@lg": {
                    flex: 8,
                },
            }}
        >
            <Box
                css={{
                    zIndex: 1,
                    flex: 1,
                    flexDirection: "column",
                    w: "100%",
                    p: 24,
                    "@sm": {
                        p: 24,
                    },
                    "@md": {
                        p: 40,
                    },
                }}
            >
                <Box
                    css={{
                        gap: 16,
                        display: "flex",
                        justifyContent: "space-between",
                        boxSizing: "border-box",
                        pb: 64,
                    }}
                >
                    <Box>
                        {isCapZero && <ComingSoonBadge />}
                        <Text
                            size={{
                                "@initial": "overline",
                                "@sm": "subtitle2",
                            }}
                            css={{
                                color: "$baseTextSecondary",
                            }}
                        >
                            {vault?.displaySubtitle}
                        </Text>
                        <Text
                            css={{
                                fontSize: "clamp(2.2rem, 10vw, 48px)",
                                pt: 6,
                                lineHeight: 0.95,
                                fontWeight: "$bold",
                                fontFamily: "$heading",
                            }}
                        >
                            {vault?.displayName} Vault
                        </Text>
                    </Box>
                    <MotionBox
                        animate={{
                            y: showFullChart ? "15%" : 0,
                        }}
                        transition={{
                            y: {
                                type: "spring",
                                delay: 0.1,
                                mass: 1.5,
                                stiffness: 250,
                            },
                        }}
                        css={{
                            aspectRatio: "1/1",
                            flexShrink: 1,
                            flexGrow: 1,
                            marginTop: "-90px",
                            mb: "-70px",
                            maxWidth: "160px",

                            "@sm": {
                                marginTop: "-70px",
                                mb: "-50px",
                                maxWidth: "200px",
                            },
                            "@md": {
                                marginTop: "-95px",
                                mb: "-35px",
                                mr: "30px",
                                maxWidth: "280px",
                            },
                            "@lg": {
                                marginTop: "-150px",
                                mb: "-60px",
                                mr: "30px",
                                maxWidth: "326px",
                            },
                        }}
                    >
                        <img
                            style={{
                                width: "100%",
                                height: "100%",
                            }}
                            src={vaultCMS?.cover?.url}
                            alt="cover"
                        />
                    </MotionBox>
                </Box>

                <Box
                    css={{
                        columns: 2,
                        "@sm": {
                            columns: 2,
                        },
                        "@md": {
                            flex: 1,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            width: "100%",
                            columnGap: 32,
                            rowGap: 24,
                            justifyContent: "flex-start",
                        },
                    }}
                >
                    <div
                        style={{ flex: 1, display: "inline-flex", alignItems: "flex-center", cursor: "pointer" }}
                        onClick={() => {
                            setShowFullChart(true)
                        }}
                    >
                        <Stat
                            labelIcon={<LucideIcon size={18} name="line-chart" />}
                            label={"APY (30-day)"}
                            value={thirtyDayApr}
                            subValue="%"
                            tooltip="View performance chart"
                            bottomValue={`${allTimeApr}% (all-time)`}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Stat
                            tooltip={`The current price of a single share: ${sharePriceExact}`}
                            label={"Share Price"}
                            bottomValue={data.depositAsset?.symbol}
                            value={sharePrice}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Stat
                            tooltip={`Total shares: ${vault?.totalShares.toFixed(6)} / Cap: ${data.totalSupplyCap}`}
                            label={"Vault Share/Cap"}
                            value={nFormatter(Number(data.totalShares), 2)}
                            bottomValue={`/${nFormatter(Number(data.totalSupplyCap), 1)} shares`}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Stat
                            tooltip={`Total value locked: ${data.totalShareValue} ${data.depositAsset?.symbol}`}
                            label={"TVL"}
                            value={nFormatter(Number(data.totalShareValue), 2)}
                            bottomValue={data.depositAsset?.symbol}
                        />
                    </div>
                </Box>
            </Box>
            {/* <div style={{ flex: 1 }} /> */}

            <Squircle
                cornerRadius={0}
                bottomRightCornerRadius={28}
                bottomLeftCornerRadius={28}
                cornerSmoothing={0.6}
                style={{
                    backgroundColor: subAccentColor,
                }}
            >
                <Box
                    css={{
                        borderTop: "1px solid black",
                        bg: subAccentColor,
                        p: 24,
                        pb: 12,

                        "@sm": {
                            p: 24,
                            pb: 8,
                        },
                        "@md": {
                            p: 40,
                            pb: 20,
                        },
                    }}
                >
                    {vault?.totalSupplyCap.toNumber() !== 0 ? (
                        <Progress
                            spacing={0}
                            borderColor="black"
                            barColor={accentColor}
                            leftLabel={
                                <Box css={{ pt: 6 }}>
                                    <Text size="label">Deposit</Text>
                                </Box>
                            }
                            rightLabel={
                                <Box css={{ pt: 6 }}>
                                    <Text size="label">
                                        {maxDepositAmount?.round(3, Big.roundDown).toString()}{" "}
                                        {data.depositAsset?.symbol} remaining —{" "}
                                        {vault?.totalShares
                                            .round(0, Big.roundUp)
                                            .mul(100)
                                            .div(vault?.totalSupplyCap)
                                            .toFixed(2)}
                                        %
                                    </Text>
                                </Box>
                            }
                            value={vault?.totalShares.round(1, Big.roundUp).toNumber()}
                            max={vault?.totalSupplyCap.toNumber()}
                        />
                    ) : (
                        // this is a placeholder
                        <Progress
                            spacing={0}
                            borderColor="black"
                            barColor={accentColor}
                            leftLabel={
                                <Box css={{ pt: 6 }}>
                                    <Text size="label">Deposit</Text>
                                </Box>
                            }
                            rightLabel={
                                <Box css={{ pt: 6 }}>
                                    <Text size="label">—%</Text>
                                </Box>
                            }
                            value={0}
                            max={100}
                        />
                    )}
                </Box>
            </Squircle>
        </CardWrapper>
    )
}

export const ComingSoonBadge = () => {
    return (
        <Box
            css={{
                borderRadius: "$full",
                bgTone9: "red",
                fontSize: 11,
                fontWeight: 500,
                px: 12,
                py: 6,
                w: "fit-content",
                display: "block",
                flex: 0,
                flexShrink: 1,
                alignSelf: "flex-start",
                flexGrow: 0,
                color: "$red2",
                mb: "$2",
            }}
        >
            COMING SOON
        </Box>
    )
}
