import { RichText } from "@graphcms/rich-text-react-renderer"
import { RichTextContent } from "@graphcms/rich-text-types"
import * as Tabs from "@radix-ui/react-tabs"
import { Box, BrandButton, Dialog, IconButton, LucideIcon, Text } from "@sayaww/nomimono"
import { ConnectKitButton } from "connectkit"
import { Squircle } from "corner-smoothing"
import { motion } from "framer-motion"
import { useAtom } from "jotai"
import _ from "lodash"
import React, { Suspense, useState } from "react"
import { Await, useParams } from "react-router-dom"
import { WalletIcon } from "src/components/CustomIcons"
import { EmailRegister } from "src/components/EmailRegister"
import { NomiStyle } from "src/components/NomiStyle"
import { QueueStatus } from "src/components/QueueStatus"
import { Spinner } from "src/components/Spinner"
import { Stat } from "src/components/Stat"
import { useMailModoStore } from "src/service/mailmodo/mailModoStore"
import { EventGroup, EventName, segmentService } from "src/service/segment/SegmentService"
import { openVaultBg } from "src/util/uiAtom"
import { usePreviewRedeem } from "src/util/usePreviewRedeem"
import { shallow } from "zustand/shallow"
import { FullChartOriginal } from "../../components/FullChartOriginal"
import { VaultStatsOriginal } from "../../components/VaultStatsOriginal"
import DepositOrQueue from "./DepositOrQueue"
import Redeem from "./Redeem"
import { useVaultDetail } from "./useVaultDetail"

export const MotionBox = motion(Box)

function VaultDetailOriginal() {
    const { vaultSlug } = useParams()
    const {
        vaultCMS,
        vault,
        data,
        accentColor,
        subAccentColor,
        sevenDayApr,
        thirtyDayApr,
        allTimeApr,
        isLargerThan1024,
        isConnected,
        address,
        showFullChart,
        setShowFullChart,
        shouldGoToShowerRoom,
    } = useVaultDetail(vaultSlug)

    const sharePriceExact = vault?.sharePrice.toFixed()
    const sharePrice = vault?.sharePrice.toFixed(4) || "1"

    // if vault cap is 0, then disable buttons
    const isCapZero = vault?.totalSupplyCap.eq(0)

    // control vault open/close
    const [open, setOpen] = useAtom(openVaultBg)

    // Est. Withdrawal Amount
    const { previewAmount } = usePreviewRedeem(vault?.id, vault?.positionShares.toString())

    // Check register
    const { checkRegister, hasRegistered, isChecking } = useMailModoStore(
        state => ({
            hasRegistered: state.hasRegistered,
            checkRegister: state.checkRegister,
            isChecking: state.isChecking,
        }),
        shallow,
    )

    React.useEffect(() => {
        if (address === undefined) return
        checkRegister(address as string)
    }, [checkRegister, address])

    return (
        // Page Transition wrapper
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <MotionBox
                layout
                css={{
                    perspective: "1600px",
                    maxWidth: 1440,
                    margin: "0 auto",
                    boxSizing: "border-box",
                    px: 0,
                    pb: 40,
                    "@md": {
                        px: 36,
                    },
                }}
            >
                <FullChartOriginal
                    thirtyDayApr={thirtyDayApr === "0.00" || !thirtyDayApr ? "•_•" : thirtyDayApr}
                    sevenDayApr={sevenDayApr === "0.00" || !sevenDayApr ? "•_•" : sevenDayApr}
                    allTimeApr={allTimeApr}
                    showFullChart={showFullChart}
                    setShowFullChart={setShowFullChart}
                />
                {/* Animation Wrapper */}
                <MotionBox
                    animate={
                        isLargerThan1024 && {
                            transformOrigin: "center top",
                            opacity: open ? 0.75 : 1,
                            rotateX: open ? -30 : 0,
                        }
                    }
                    css={{
                        display: "flex",
                        gap: 1,
                        flex: 1,

                        flexDirection: "column",
                        alignItems: "stretch",
                        "@lg": {
                            flexDirection: "row",
                        },
                    }}
                >
                    {/* Card: Vault Stats */}
                    <VaultStatsOriginal
                        vault={vault}
                        showFullChart={showFullChart}
                        vaultCMS={vaultCMS}
                        setShowFullChart={setShowFullChart}
                        thirtyDayApr={thirtyDayApr === "0.00" || !thirtyDayApr ? "•_•" : thirtyDayApr}
                        sevenDayApr={sevenDayApr === "0.00" || !sevenDayApr ? "•_•" : sevenDayApr}
                        allTimeApr={allTimeApr}
                        sharePriceExact={sharePriceExact}
                        data={data}
                        sharePrice={sharePrice}
                        subAccentColor={subAccentColor}
                        accentColor={accentColor}
                        maxDepositAmount={vault?.remainingDepositAmount}
                        isCapZero={isCapZero}
                    />
                    {/* Card: Position */}
                    <CardWrapper
                        css={{
                            display: "flex",
                            // justifySelf: "stretch",
                            // alignSelf: "stretch",
                            justifyContent: "stretch",
                            alignItems: "stretch",
                            "@sm": {
                                flex: 1,
                            },
                            "@md": {
                                flex: 1,
                            },
                            "@lg": {
                                flex: 4,
                            },
                        }}
                    >
                        <CardTitle address={address} vaultTokenAddr={data.vaultTokenAddr} />
                        {shouldGoToShowerRoom && <QueueStatus />}
                        {/* Content */}
                        {!isConnected && (
                            <Box
                                css={{
                                    flex: 1,
                                    gap: 24,
                                    px: 40,
                                    pt: 24,
                                    pb: 40,
                                    // h: "100%",
                                    // flexGrow: 1,
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    alignItems: "stretch",
                                }}
                            >
                                <Text>
                                    Connect wallet to continue, depending on the capacity of the vault, you may be able
                                    to{" "}
                                    <span
                                        style={{
                                            verticalAlign: "middle",
                                            paddingRight: 4,
                                        }}
                                    >
                                        <LucideIcon size={16} name="arrow-down" />
                                    </span>
                                    deposit directly or{" "}
                                    <span
                                        style={{
                                            verticalAlign: "middle",
                                            paddingRight: 4,
                                        }}
                                    >
                                        <LucideIcon size={16} name="user-plus" />
                                    </span>
                                    join the queue.
                                </Text>
                                <Box css={{ flex: 0, gap: "$4" }}>
                                    <Text
                                        css={{
                                            color: "$blue9",
                                            display: "inline-flex",
                                            alignItems: "center",
                                            gap: "$1",
                                        }}
                                        size="label"
                                        as="a"
                                        href={`https://defillama.com/yields?project=perpetual-protocol`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <span>DefiLlama Stats</span>
                                        <LucideIcon size={16} name="external-link" />
                                    </Text>
                                    <Text
                                        css={{
                                            color: "$blue9",
                                            display: "inline-flex",
                                            gap: "$1",
                                            alignItems: "center",
                                        }}
                                        size="label"
                                        as="a"
                                        href={`https://optimistic.etherscan.io/token/${data.vaultTokenAddr}`}
                                    >
                                        <span>Etherscan</span>
                                        <LucideIcon size={16} name="external-link" />
                                    </Text>
                                </Box>
                                <div style={{ flex: 1 }} />
                                {/* Scenario 1: User is not connected */}
                                <ConnectKitButton.Custom>
                                    {({ isConnecting, show }) => {
                                        return (
                                            <BrandButton
                                                isLoading={isConnecting}
                                                onClick={show}
                                                leftIcon={<WalletIcon />}
                                                css={{
                                                    gap: 0,
                                                    cursor: "pointer",
                                                    boxSizing: "border-box",
                                                    justifyContent: "center",
                                                    width: "100%",
                                                    bg: "black",
                                                    color: "white",
                                                    "&:hover": {
                                                        bg: "black",
                                                        color: "white",
                                                    },
                                                }}
                                            >
                                                Connect Wallet
                                            </BrandButton>
                                        )
                                    }}
                                </ConnectKitButton.Custom>
                            </Box>
                        )}

                        {/* Scenario 2: User is connected */}
                        {address && (
                            <Box
                                css={{
                                    gap: 16,
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    p: 24,
                                    "@sm": {
                                        p: 24,
                                    },
                                    "@md": {
                                        p: 40,
                                    },
                                }}
                            >
                                {hasRegistered && !isChecking && (
                                    <>
                                        <Stat
                                            label={"Est. Max Withdrawal Amount"}
                                            subValue={data.depositAsset?.symbol}
                                            value={previewAmount?.toPrecision(6) || "—"}
                                            tooltip={
                                                "Estimated max withdrawal amount. Actual amounts may vary due to market conditions."
                                            }
                                        />
                                        <Stat
                                            tooltip="How much share you own"
                                            label={"Shares"}
                                            bottomValue={`Value: ${vault?.positionShareValue?.toFixed(4)} ${
                                                data.depositAsset?.symbol
                                            }`}
                                            subValue={`(${
                                                vault
                                                    ? vault.totalSupplyCap.toNumber() !== 0 &&
                                                      vault.positionShares.div(vault.totalSupplyCap).mul(100).toFixed(2)
                                                    : "—"
                                            }% of TVL)`}
                                            value={
                                                vault
                                                    ? vault.positionShares.toNumber() === 0
                                                        ? vault.positionShares.toFixed(2)
                                                        : vault.positionShares.toNumber() < 0.0001
                                                        ? "<0.0001"
                                                        : vault.positionShares.toNumber() >= 0.0001
                                                        ? vault.positionShares.toFixed(4)
                                                        : "—"
                                                    : "—"
                                            }
                                        />
                                        <DepositOrWithdraw
                                            shouldGoToShowerRoom={shouldGoToShowerRoom}
                                            isDisabled={isCapZero}
                                            open={open}
                                            setOpen={setOpen}
                                        />
                                    </>
                                )}
                                {isChecking && (
                                    <Box
                                        css={{
                                            flex: 1,
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div style={{ height: 48, width: 48 }}>
                                            <Spinner />
                                        </div>
                                    </Box>
                                )}
                                {!hasRegistered && !isChecking && <EmailRegister />}
                            </Box>
                        )}
                    </CardWrapper>
                </MotionBox>
                {/* Animation Wrapper */}
                <MotionBox
                    animate={
                        isLargerThan1024 && {
                            transformOrigin: "center bottom",
                            opacity: open ? 0.75 : 1,
                            rotateX: open ? 30 : 0,
                        }
                    }
                    css={{
                        pt: 24,
                        gap: 1,
                        justifyItems: "stretch",
                        alignItems: "stretch",
                        display: "grid",
                        "@sm": {
                            gridTemplateColumns: "1fr",
                        },
                        "@lg": {
                            gridTemplateColumns: "1fr 1fr",
                        },
                        "@xl": {
                            gridTemplateColumns: "1fr 1fr 1fr 1fr",
                        },
                    }}
                >
                    {/* Spec card from CMS */}
                    <Suspense fallback={<Spinner />}>
                        {/* Await manages the deferred data (promise) */}
                        <Await resolve={vaultCMS}>
                            {/* this calls back when the data is resolved */}
                            {/* feeling confident to use because all data are resolved*/}

                            {data => {
                                if (data?.specs?.length === 0) {
                                    return "no cms data"
                                }
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                return data?.specs?.map((spec: any) => {
                                    return (
                                        <SpecCard
                                            key={spec.id}
                                            heading={spec.heading}
                                            description={spec.description}
                                            backgroundColor={spec.background.css}
                                            raw={spec.content.raw}
                                        />
                                    )
                                })
                            }}
                        </Await>
                    </Suspense>
                </MotionBox>
            </MotionBox>
        </motion.div>
    )
}

function DepositOrWithdraw({
    shouldGoToShowerRoom,
    open,
    setOpen,
    isDisabled,
}: {
    open: boolean
    shouldGoToShowerRoom: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    isDisabled?: boolean
}) {
    // const [defaultTab, setDefaultTab] = useState("deposit")
    const [currentTab, setCurrentTab] = useState("deposit")

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Trigger css={{ outline: "none", border: "none", background: "none" }} disabled={isDisabled}>
                <Box css={{ display: "flex", gap: 0.5 }}>
                    <BrandButton
                        brand="normal"
                        disabled={isDisabled}
                        onClick={() => {
                            setOpen(true)
                            setCurrentTab("deposit")
                        }}
                        size={"l"}
                        css={{
                            gap: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            cursor: "pointer",
                            boxSizing: "border-box",
                            justifyContent: "center",
                            width: "100%",
                            bg: "black",
                            color: "white",
                            "&:hover": {
                                bg: "black",
                                color: "white",
                            },
                        }}
                        leftIcon={
                            <div
                                style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    flexShrink: 0,
                                }}
                            >
                                {shouldGoToShowerRoom ? (
                                    <LucideIcon size={24} name="user-plus" />
                                ) : (
                                    <LucideIcon size={24} name="arrow-down" />
                                )}
                            </div>
                        }
                    >
                        {shouldGoToShowerRoom ? "Queue" : "Deposit"}
                    </BrandButton>
                    <BrandButton
                        disabled={isDisabled}
                        onClick={() => {
                            setOpen(true)
                            setCurrentTab("withdraw")
                        }}
                        size={"l"}
                        css={{
                            gap: 0,
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            cursor: "pointer",
                            boxSizing: "border-box",
                            justifyContent: "center",
                            width: "100%",
                            bg: "black",
                            color: "white",
                            "&:hover": {
                                bg: "black",
                                color: "white",
                            },
                        }}
                        leftIcon={
                            <div
                                style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    flexShrink: 0,
                                }}
                            >
                                <LucideIcon size={24} name="arrow-up" />
                            </div>
                        }
                    >
                        Withdraw
                    </BrandButton>
                </Box>
            </Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay style={{ zIndex: 30 }}>
                    <Dialog.Content asChild>
                        <Squircle
                            cornerRadius={30}
                            cornerSmoothing={0.6}
                            style={{
                                width: "100%",
                                maxWidth: "464px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                backgroundColor: "white",
                            }}
                        >
                            <Dialog.Close asChild>
                                <Box
                                    css={{
                                        position: "absolute",
                                        top: "$4",
                                        right: "$4",
                                        zIndex: "32",
                                    }}
                                >
                                    <IconButton style={{ borderRadius: "9999px" }} name="x" intent="ghost" />
                                </Box>
                            </Dialog.Close>
                            <>
                                <Tabs.Root
                                    onValueChange={value => setCurrentTab(value)}
                                    defaultValue={currentTab}
                                    orientation="horizontal"
                                >
                                    <Tabs.Content value="deposit" asChild>
                                        <DepositOrQueue />
                                    </Tabs.Content>
                                    <Tabs.Content value="withdraw" asChild>
                                        <Redeem />
                                    </Tabs.Content>
                                </Tabs.Root>
                            </>
                        </Squircle>
                    </Dialog.Content>
                </Dialog.Overlay>
            </Dialog.Portal>
        </Dialog.Root>
    )
}

function SpecCard({
    heading,
    description,
    backgroundColor,
    raw,
}: {
    heading: string
    description: string
    backgroundColor: string
    raw: RichTextContent
}): React.JSX.Element {
    return (
        <CardWrapper bg={backgroundColor}>
            <Dialog.Root
                onOpenChange={open => {
                    open &&
                        segmentService.track({
                            eventGroup: EventGroup.INTERACTION,
                            eventName: EventName.SPEC_DISPLAYED,
                            description: heading,
                        })
                }}
            >
                <Dialog.Trigger asChild>
                    <MotionBox
                        whileHover={{ y: -5 }}
                        whileTap={{ y: 3 }}
                        css={{
                            cursor: "pointer",
                            height: "100%",
                            p: 40,
                            flex: 1,
                            flexDirection: "column",
                            gap: 24,
                            display: "flex",
                            aspectRatio: undefined,

                            "&:focus": {
                                outline: "none",
                                aspectRatio: undefined,
                            },
                            "@xl": {
                                aspectRatio: "1/1",
                                overflow: "hidden",
                            },
                        }}
                    >
                        <Text size="title2" color={"baseBackground"}>
                            {heading}
                        </Text>
                        <Text
                            css={{
                                fontWeight: 400,
                                overflow: "hidden",
                                flex: 1,
                                flexGrow: 1,
                                textOverflow: "ellipsis",

                                WebkitMaskImage:
                                    "linear-gradient(to bottom, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0) 100%)",
                                maskImage: "linear-gradient(to bottom, rgba(0, 0, 0, 1) 65%, rgba(0, 0, 0, 0) 100%)",
                            }}
                        >
                            {description}
                        </Text>
                        <Text
                            css={{
                                display: "inline-block",
                                alignSelf: "flex-start",
                                pr: 28,
                                fontWeight: "$medium",
                                background: "url(/assets/learn-more.svg)",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "right center",
                            }}
                            color={"baseBackground"}
                        >
                            Learn more
                        </Text>
                    </MotionBox>
                </Dialog.Trigger>
                <Dialog.Portal>
                    <Dialog.Overlay>
                        <Dialog.Content asChild>
                            <Squircle
                                cornerRadius={30}
                                cornerSmoothing={0.6}
                                style={{
                                    width: "100%",
                                    maxWidth: "640px",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    backgroundColor: "white",
                                }}
                            >
                                <Dialog.Close asChild>
                                    <Box
                                        css={{
                                            position: "absolute",
                                            top: "$4",
                                            right: "$4",
                                            zIndex: "32",
                                        }}
                                    >
                                        <IconButton style={{ borderRadius: "9999px" }} name="x" intent="ghost" />
                                    </Box>
                                </Dialog.Close>

                                <Box
                                    css={{
                                        position: "relative",
                                        WebkitMaskImage:
                                            "linear-gradient(to bottom, rgba(0, 0, 0, 1) 95%, rgba(0, 0, 0, 0) 100%)",
                                        maskImage:
                                            "linear-gradient(to bottom, rgba(0, 0, 0, 1) 95%, rgba(0, 0, 0, 0) 100%)",
                                        width: "100%",
                                        minWidth: "100%",
                                        maxHeight: "90vh",
                                        overflowY: "auto",
                                        webkitFontSmoothing: "antialiased",
                                        ":focus": { outline: "none" },
                                    }}
                                >
                                    <Box
                                        css={{
                                            textAlign: "center",
                                            py: "$5",
                                            borderBottom: "1px solid #E5E7EB",
                                        }}
                                    >
                                        <Text size="title3">{heading}</Text>
                                    </Box>
                                    <NomiStyle
                                        css={{
                                            p: "$6",
                                            webkitFontSmoothing: "antialiased",
                                        }}
                                    >
                                        <RichText content={raw} />
                                    </NomiStyle>
                                </Box>
                            </Squircle>
                        </Dialog.Content>
                    </Dialog.Overlay>
                </Dialog.Portal>
            </Dialog.Root>
        </CardWrapper>
    )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CardWrapper = ({ bg, css, ...props }: { bg?: string; children?: React.ReactNode; css?: any }) => {
    return (
        <Box
            css={_.merge(
                {
                    boxSizing: "border-box",
                    position: "relative",
                    ...css,
                },
                css,
            )}
        >
            <Squircle
                cornerRadius={30}
                cornerSmoothing={0.6}
                style={{
                    position: "absolute",
                    inset: 0,
                    zIndex: 0,
                    backgroundColor: bg || "white",
                }}
                /* eslint-disable-next-line react/no-children-prop */
                children={undefined}
            />
            <div
                style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    zIndex: 1,
                    display: "flex",
                    alignItems: "stretch",
                    flexDirection: "column",
                    justifyContent: "stretch",
                    justifySelf: "stretch",
                    alignSelf: "stretch",
                }}
            >
                {props.children}
            </div>
        </Box>
    )
}

type CardTitleProps = {
    address: string | undefined
    vaultTokenAddr: string | undefined
}

const CardTitle = ({ address, vaultTokenAddr }: CardTitleProps) => {
    return (
        <Squircle
            cornerRadius={0}
            topRightCornerRadius={28}
            topLeftCornerRadius={28}
            cornerSmoothing={0.6}
            style={{
                backgroundColor: "#EDEDEF",
            }}
        >
            <Box
                css={{
                    borderBottom: "1px solid black",
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    p: 24,
                    "@sm": {
                        p: 24,
                    },
                    "@md": {
                        p: 40,
                        pb: 26,
                    },
                }}
            >
                <Text
                    size={{
                        "@initial": "title3",
                        "@sm": "title2",
                    }}
                >
                    {address ? "My Shares" : "Get Started"}
                </Text>

                {address && (
                    <Text
                        as="a"
                        target="_blank"
                        href={`https://optimistic.etherscan.io/token/${vaultTokenAddr}?a=${address}`}
                        css={{
                            color: "black",
                            textDecoration: "underline",
                            display: "inline-flex",
                            gap: 4,
                            flexDirection: "row",
                            alignItems: "center",
                            "&:hover": {
                                textDecoration: "underline",
                            },
                        }}
                    >
                        <LucideIcon name="clock" />
                        <span>History</span>
                    </Text>
                )}
            </Box>
        </Squircle>
    )
}

export default VaultDetailOriginal
