import Big from "big.js"
import { Signer } from "ethers"
import { ERC20, ERC20__factory } from "../../typechain"
import { logMethod } from "../../util/log"
import { big2BigNum, bigNum2Big } from "../../util/number"

import { providerManager } from "./ProviderManager"

export interface Erc20Meta {
    symbol: string
    addr: string
    decimal: number
}

export class Erc20Client {
    @logMethod
    async approve(token: Erc20Meta, amount: Big, spender: string, signer: Signer) {
        const erc20 = await erc20Client.getErc20(token.addr, signer)
        const tx = await erc20.approve(spender, big2BigNum(amount, token.decimal), this.getOverrides())
        return tx.wait()
    }

    @logMethod
    async getAllowance(token: Erc20Meta, owner: string, spender: string): Promise<Big> {
        const erc20 = await erc20Client.getErc20(token.addr)
        return erc20.callStatic.allowance(owner, spender).then(it => bigNum2Big(it, token.decimal))
    }

    @logMethod
    async getTotalSupply(token: Erc20Meta): Promise<Big> {
        const erc20 = await erc20Client.getErc20(token.addr)
        return erc20.callStatic.totalSupply().then(it => bigNum2Big(it, token.decimal))
    }

    private getOverrides() {
        return {
            // TODO better gas limit
            gasLimit: 12_000_000,
        }
    }

    async getErc20(contractAddr: string, signer?: Signer): Promise<ERC20> {
        const provider = await providerManager.getProvider()
        return ERC20__factory.connect(contractAddr, signer ? signer : provider)
    }
}

export const erc20Client = new Erc20Client()
