import React from "react"

export const WalletIcon = (props: any) => (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M18.5 7.5V5.5C18.5 4.94772 18.0523 4.5 17.5 4.5H6.5C4.84315 4.5 3.5 5.84315 3.5 7.5V12.5"
            stroke="currentColor"
            strokeWidth="1.5"
        />
        <path
            d="M3.5 10.5C3.5 8.84315 4.84315 7.5 6.5 7.5H20.5C21.0523 7.5 21.5 7.94772 21.5 8.5V19.5C21.5 20.0523 21.0523 20.5 20.5 20.5H6.5C4.84315 20.5 3.5 19.1569 3.5 17.5V10.5Z"
            stroke="currentColor"
            strokeWidth="1.5"
        />
        <circle cx="17" cy="14" r="1.5" fill="currentColor" />
    </svg>
)

export const AirplaneIcon = (props: any) => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M6.49955 12L3.76855 3.12598C10.3014 5.02565 16.4619 8.02677 21.9846 12C16.4623 15.9738 10.3021 18.9755 3.76955 20.876L6.49955 12ZM6.49955 12H13.9996"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
