import Big from "big.js"
import { ContractReceipt, Signer } from "ethers"
import { zipObject } from "lodash"
import { sleep } from "../../util/time"
import { Erc20Meta, erc20Client } from "../eth/Erc20Client"
import { kantabanClient } from "./KantabanClient"
import { KantabanVaultData } from "./KantabanVault"
import { KantabanVaultId, KantabanVaultMeta, kantabanVaultMetas, kantabanVaultSupportList } from "./KantabanVaultMeta"
import { mcKantabanClient } from "./McKantabanClient"
import { showerRoomClient } from "./ShowerRoomClient"

export type KantabanVaultDataMap = { [p: string]: KantabanVaultData }

export class KantabanVaultManager {
    getDepositAssetByVaultId(vaultId: string): Erc20Meta {
        const vaultMeta = kantabanVaultMetas[vaultId as KantabanVaultId]
        return vaultMeta.depositAsset
    }

    // noinspection JSUnusedGlobalSymbols
    getShareAssetByVaultId(vaultId: string): Erc20Meta {
        const vaultMeta = kantabanVaultMetas[vaultId as KantabanVaultId]
        return vaultMeta.shareAsset
    }

    listVaultMetas(): KantabanVaultMeta[] {
        return Object.values(kantabanVaultMetas).filter(it => kantabanVaultSupportList.includes(it.id))
    }

    async fetchVaultDataMap(signer?: Signer): Promise<KantabanVaultDataMap> {
        const metas = this.listVaultMetas()
        const vaultDatas = await Promise.all(metas.map(vault => this.fetchVaultData(vault, signer)))
        return zipObject(
            metas.map(it => it.id),
            vaultDatas,
        )
    }

    async approve(
        vaultId: string,
        amount: Big,
        signer: Signer,
    ): Promise<{ vaultData: KantabanVaultData; receipt: ContractReceipt }> {
        const vaultMeta = kantabanVaultMetas[vaultId as KantabanVaultId]
        const depositAsset = vaultMeta.depositAsset
        const receipt = await erc20Client.approve(depositAsset, amount, vaultMeta.contractAddr, signer)
        const vaultData = await this.fetchVaultData(vaultMeta, signer)
        return { receipt, vaultData }
    }

    async deposit(
        vaultId: string,
        amount: Big,
        signer: Signer,
    ): Promise<{ vaultData: KantabanVaultData; receipt: ContractReceipt }> {
        const vaultMeta = kantabanVaultMetas[vaultId as KantabanVaultId]
        const receipt = await kantabanClient.deposit(vaultMeta, amount, signer)
        const vaultData = await this.fetchVaultData(vaultMeta, signer)
        return { receipt, vaultData }
    }

    async redeem(
        vaultId: string,
        shares: Big,
        slippage: Big,
        signer: Signer,
    ): Promise<{ vaultData: KantabanVaultData; receipt: ContractReceipt }> {
        const vaultMeta = kantabanVaultMetas[vaultId as KantabanVaultId]
        const receipt = await kantabanClient.redeem(vaultMeta, shares, slippage, signer)
        const vaultData = await this.fetchVaultData(vaultMeta, signer)
        return { receipt, vaultData }
    }

    async previewRedeem(vaultId: string, shares: Big, slippage: Big, signer: Signer): Promise<Big> {
        const vaultMeta = kantabanVaultMetas[vaultId as KantabanVaultId]
        return kantabanClient.previewRedeem(vaultMeta, shares, slippage, signer)
    }

    async approveShowerRoom(
        vaultId: string,
        amount: Big,
        signer: Signer,
    ): Promise<{ vaultData: KantabanVaultData; receipt: ContractReceipt }> {
        const vaultMeta = kantabanVaultMetas[vaultId as KantabanVaultId]
        const depositAsset = vaultMeta.depositAsset
        const receipt = await erc20Client.approve(depositAsset, amount, vaultMeta.showerRoomAddr, signer)
        const vaultData = await this.fetchVaultData(vaultMeta, signer)
        return { receipt, vaultData }
    }

    async depositToShowerRoom(
        vaultId: string,
        amount: Big,
        signer: Signer,
    ): Promise<{ vaultData: KantabanVaultData; receipt: ContractReceipt }> {
        const vaultMeta = kantabanVaultMetas[vaultId as KantabanVaultId]
        const receipt = await showerRoomClient.deposit(vaultMeta, amount, signer)

        /**************************************************
         * wait for thegraph data syncing                 *
         * we cannot make sure thegraph will sync in time *
         **************************************************/
        await sleep(2000)

        const vaultData = await this.fetchVaultData(vaultMeta, signer)
        return { receipt, vaultData }
    }

    async withdrawFromShowerRoom(
        vaultId: string,
        signer: Signer,
    ): Promise<{ vaultData: KantabanVaultData; receipt: ContractReceipt }> {
        const vaultMeta = kantabanVaultMetas[vaultId as KantabanVaultId]
        const receipt = await showerRoomClient.withdrawAll(vaultMeta, signer)

        /**************************************************
         * wait for thegraph data syncing                 *
         * we cannot make sure thegraph will sync in time *
         **************************************************/
        await sleep(2000)

        const vaultData = await this.fetchVaultData(vaultMeta, signer)
        return { receipt, vaultData }
    }

    private getShouldGoToShowerRoom(
        remainingDepositAmount: Big,
        srMaxDepositPerUser: Big,
        srWaitingListLength: number,
    ): boolean {
        const threshold = srMaxDepositPerUser.mul(0.001)
        return !!(srWaitingListLength > 0 || remainingDepositAmount.lt(threshold))
    }

    private async fetchVaultData(vaultMeta: KantabanVaultMeta, signer?: Signer): Promise<KantabanVaultData> {
        const [vaultInfo, srWaitingListLength] = await Promise.all([
            mcKantabanClient.getVaultInfo(vaultMeta),
            showerRoomClient.getWaitingListLength(vaultMeta),
        ])
        const { totalShares, totalShareValue, totalSupplyCap, transferCooldown, srMaxDepositPerUser } = vaultInfo
        const sharePrice = totalShares.eq(0) ? Big(0) : totalShareValue.div(totalShares)
        const remainingDepositAmount = totalSupplyCap.sub(totalShares).mul(sharePrice)

        const shouldGoToShowerRoom = this.getShouldGoToShowerRoom(
            remainingDepositAmount,
            srMaxDepositPerUser,
            srWaitingListLength,
        )

        if (!signer) {
            return {
                positionShareValue: Big(0),
                positionShares: Big(0),
                allowance: Big(0),
                totalShares,
                totalShareValue,
                sharePrice,
                remainingDepositAmount,
                isWhitelistedLiquidityProvider: false,
                totalSupplyCap,
                lastMintedAt: undefined,
                transferCooldown,
                srMyWaitingIndex: -1,
                srMyWaitingAmount: Big(0),
                srWaitingListLength,
                srMaxDepositPerUser,
                srAllowance: Big(0),
                shouldGoToShowerRoom,
            }
        }

        const owner = await signer.getAddress()

        // TODO: move showerRoomClient.getUserTotalAssetWithdrawn to mcKantabanClient once mc can support callstatic write function
        const [
            { balance, isWhitelistedLiquidityProvider, lastMintedAt, allowance, srAllowance },
            srMyWaitingIndex,
            srMyWaitingAmount,
        ] = await Promise.all([
            mcKantabanClient.getVaultInfoBySigner(vaultInfo, vaultMeta, signer),
            showerRoomClient.getUserWaitListIndex(vaultMeta, owner),
            showerRoomClient.getUserTotalAssetWithdrawn(vaultMeta, signer),
        ])

        return {
            positionShareValue: balance.positionShareValue,
            positionShares: balance.positionShares,
            allowance,
            totalShares,
            totalShareValue,
            sharePrice,
            remainingDepositAmount,
            isWhitelistedLiquidityProvider,
            totalSupplyCap,
            lastMintedAt,
            transferCooldown,
            srMyWaitingIndex,
            srMyWaitingAmount,
            srWaitingListLength,
            srMaxDepositPerUser,
            srAllowance,
            shouldGoToShowerRoom,
        }
    }
}

export const kantabanVaultManager = new KantabanVaultManager()
