import Big from "big.js"
import { useEffect, useState } from "react"
import { useKantabanStore } from "src/service/kantaban/kantabanStore"
import { shallow } from "zustand/shallow"

const STORAGE_KEY_SLIPPAGE = "slippage"

// TODO: refactor this previewRedeem amount to the store
// It should be located under vault.previewRedeemAmount
export function usePreviewRedeem(vaultId?: string, amountStr?: string) {
    const [previewAmount, setPreviewAmount] = useState<number | undefined>(vaultId ? 0 : undefined)
    const { previewRedeem } = useKantabanStore(
        state => ({
            previewRedeem: state.previewRedeem,
        }),
        shallow,
    )
    const fetchSlippageAndRedeemAmount = async () => {
        if (!vaultId || !amountStr) {
            setPreviewAmount(undefined)
            return
        }
        const amount = Big(amountStr)
        if (amount.eq(0)) {
            setPreviewAmount(0)
            return
        }

        const rawSlippage = (await localStorage.getItem(STORAGE_KEY_SLIPPAGE)) || 0.5
        const slippage = Big(rawSlippage).div(100)
        const redeemAmount = (await previewRedeem(vaultId, amount, slippage)) || Big(0)
        setPreviewAmount(redeemAmount.toNumber())
    }

    useEffect(() => {
        fetchSlippageAndRedeemAmount() // Run once on mount
    }, [vaultId, amountStr, previewRedeem]) //

    return {
        previewAmount,
    }
}
