import { sentryLog } from "../sentry/SentryLog"
import { useNotifStore } from "./notifStore"

export function notifyError(e: unknown | Error, showToast = false) {
    sentryLog.err(e)
    if (showToast) {
        const { openError } = useNotifStore.getState()
        openError(e, undefined)
    }
}
