import "@notifi-network/notifi-react-card/dist/index.css"
import { Box, Dialog, IconButton, Text } from "@sayaww/nomimono"
import { Squircle } from "corner-smoothing"
import { motion } from "framer-motion"
import { useAtom } from "jotai"
import React from "react"
import { openEmail } from "src/util/uiAtom"
import { SignUpForm } from "./SignUpForm"

const MotionSquircle = motion(Squircle)
export const EmailSignupModal = () => {
    const [open, setOpen] = useAtom(openEmail)
    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Portal>
                <Dialog.Overlay>
                    <Dialog.Content asChild>
                        <MotionSquircle
                            layout
                            cornerRadius={30}
                            cornerSmoothing={0.6}
                            style={{
                                padding: "2rem",
                                width: "100%",
                                maxWidth: "480px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                backgroundColor: "white",
                            }}
                        >
                            <Dialog.Close asChild>
                                <Box
                                    css={{
                                        position: "absolute",
                                        top: "$4",
                                        right: "$4",
                                        zIndex: "32",
                                    }}
                                >
                                    <IconButton
                                        onClick={() => setOpen(false)}
                                        style={{ borderRadius: "9999px" }}
                                        name="x"
                                        intent="ghost"
                                    />
                                </Box>
                            </Dialog.Close>
                            <Text size="title2" css={{ mb: "$6" }}>
                                Get our <br />
                                latest updates
                            </Text>
                            <SignUpForm />
                        </MotionSquircle>
                    </Dialog.Content>
                </Dialog.Overlay>
            </Dialog.Portal>
        </Dialog.Root>
    )
}
