import Big from "big.js"
import { useEffect, useMemo, useState } from "react"
import { useViewportWidth } from "src/util/useViewportWidth"
import { useAccount, useWalletClient } from "wagmi"
import { shallow } from "zustand/shallow"
import { useCoingeckoStore } from "../../service/coingecko/coingeckoStore"
import { useAprStore } from "../../service/databricks/aprStore"
import { Erc20Meta } from "../../service/eth/Erc20Client"
import { useHygraphStore } from "../../service/hygraph/hygraphStore"
import { KantabanVaultId } from "../../service/kantaban/KantabanVaultMeta"
import { useKantabanStore } from "../../service/kantaban/kantabanStore"

export type VaultData = {
    vaultId?: KantabanVaultId
    vaultName?: string
    totalShares?: string
    totalSupplyCap?: string
    totalShareValue?: string
    shares?: string
    shareValue?: string
    allowance?: string
    displaySubtitle?: string
    depositAsset?: Erc20Meta
    shareAsset?: Erc20Meta
    isWhitelisted?: boolean
    vaultTokenAddr?: string
    availableShares?: Big
    sharePrice?: Big
    remainingDepositAmount?: Big
    srMaxDepositPerUser?: Big
    srWaitingListLength?: number
}

export function useVaultDetail(vaultSlug?: string) {
    const { vaultDetails, getVaultDetail } = useHygraphStore(
        state => ({
            vaultDetails: state.vaultDetails,
            getVaultDetail: state.getVaultDetail,
        }),
        shallow,
    )
    useEffect(() => {
        if (!vaultSlug) {
            return
        }
        getVaultDetail(vaultSlug)
    }, [getVaultDetail, vaultSlug])
    const vaultCMS = vaultSlug ? vaultDetails[vaultSlug] : undefined

    const {
        loading,
        vaults,
        fetchVaults,
        approveShowerRoom,
        depositToShowerRoom,
        withdrawFromShowerRoom: withdrawFromShowerRoom,
    } = useKantabanStore(
        state => ({
            loading: state.loading,
            vaults: state.vaults,
            fetchVaults: state.fetchVaults,
            approveShowerRoom: state.approveShowerRoom,
            depositToShowerRoom: state.depositToShowerRoom,
            withdrawFromShowerRoom: state.withdrawFromShowerRoom,
        }),
        shallow,
    )

    const vault = vaults.find(vault => vault.slug === vaultSlug)
    const data: VaultData = {
        vaultId: vault?.id,
        vaultName: vault?.displayName,
        totalShares: vault?.totalShares.toFixed(3),
        totalSupplyCap: vault?.totalSupplyCap.toFixed(3),
        totalShareValue: vault?.totalShareValue.toFixed(2),
        shares: vault?.positionShares.toFixed(2),
        shareValue: vault?.positionShareValue.toFixed(2),
        allowance: vault?.allowance.toFixed(),
        displaySubtitle: vault?.displaySubtitle,
        depositAsset: vault?.depositAsset,
        shareAsset: vault?.shareAsset,
        isWhitelisted: vault?.isWhitelistedLiquidityProvider,
        vaultTokenAddr: vault?.shareAsset?.addr,
        availableShares: vault?.totalSupplyCap.minus(vault?.totalShares),
        sharePrice: vault?.sharePrice,
        remainingDepositAmount: vault?.remainingDepositAmount,
        srMaxDepositPerUser: vault?.srMaxDepositPerUser,
        srWaitingListLength: vault?.srWaitingListLength,
    }

    const shouldGoToShowerRoom = !!vault?.shouldGoToShowerRoom

    /***************************************************************
     * coingecho prices                                            *
     * this shows the usd value (from coingecho) in non-usd market *
     ***************************************************************/
    const { fetch, prices } = useCoingeckoStore(
        state => ({
            prices: state.prices,
            fetch: state.fetch,
            loading: state.loading,
        }),
        shallow,
    )

    useEffect(() => {
        fetch(["ethereum", "bitcoin", "optimism", "usd-coin"])
    }, [fetch])

    // map prices to vaults

    const shareValueInUsd = useMemo(() => {
        if (!vault) {
            return 0
        }
        const shareValue = vault.positionShareValue
        const shareAsset = vault.displayName.toLowerCase() // this matches bitcoin, ethereum, etc
        const sharePrice = prices[shareAsset] || Big(1) // if nothing is found, return 0 (USDC vault will have 1)
        return sharePrice.eq(0) ? null : shareValue.mul(sharePrice).toFixed(2) // shareValue * sharePrice = shareValueInUsd
    }, [vault, prices])

    // some data from CMS that's not in the suspense component
    const accentColor = vaultCMS?.accent.hex
    // join rgba object from vaultCMS.accent.rgba to valid rgba color
    const subAccentColor = `rgba(${vaultCMS?.accent.rgba.r}, ${vaultCMS?.accent.rgba.g}, ${vaultCMS?.accent.rgba.b}, 0.5)`

    // Get APR
    const { fetchApr, dailyAprMap } = useAprStore(
        state => ({
            fetchApr: state.fetch,
            dailyAprMap: state.dailyAprMap,
        }),
        shallow,
    )

    useEffect(() => {
        fetchApr()
    }, [fetchApr])

    const { sevenDayApr, thirtyDayApr, allTimeApr } = useMemo(() => {
        if (!vault || !dailyAprMap || !dailyAprMap[vault.contractAddr.toLowerCase()]) {
            return { apr: "-", sharePrice: "-" }
        }

        const vaultAddress = vault.contractAddr.toLowerCase()
        return {
            sevenDayApr: dailyAprMap[vaultAddress].sevenDayRollingApr.mul(100).toFixed(2),
            thirtyDayApr: dailyAprMap[vaultAddress].thirtyDayRollingApr.mul(100).toFixed(2),
            allTimeApr: dailyAprMap[vaultAddress] ? dailyAprMap[vaultAddress].lifeTimeApr.mul(100).toFixed(2) : "—",
        }
    }, [vault, dailyAprMap, data.availableShares])

    // Rewrite with custom hook
    const viewPortWidth = useViewportWidth()
    const isLargerThan1024 = viewPortWidth > 1024

    // Whitelist detection
    const { isConnected, address } = useAccount()
    const { data: signer } = useWalletClient()

    // fetchVaults for whitelist status
    // You need to check when
    // 1. initial load
    // 2. when user connects wallet
    // 3. when user changes wallet
    useEffect(() => {
        fetchVaults()
    }, [fetchVaults, signer])

    // show hide fullChart
    const [showFullChart, setShowFullChart] = useState(false)
    return {
        vaultCMS,
        loading,
        vault,
        data,
        shareValueInUsd,
        accentColor,
        subAccentColor,
        sevenDayApr,
        thirtyDayApr,
        allTimeApr,
        isLargerThan1024,
        isConnected,
        address,
        showFullChart,
        setShowFullChart,
        approveShowerRoom,
        depositToShowerRoom,
        withdrawFromShowerRoom,
        shouldGoToShowerRoom,
    }
}
