import { gql, GraphQLClient } from "graphql-request"
import { Cache } from "node-ts-cache"
import { memoryCache } from "../cache/nodeTsCache"

export class HygraphClient {
    private readonly graphqlUrl = "https://ap-northeast-1.cdn.hygraph.com/content/clds7l8vr3ztn01uo44qu7j0h/master"
    private readonly hygraph = new GraphQLClient(this.graphqlUrl)

    @Cache(memoryCache, { ttl: 180 })
    async listFrontpages() {
        const q = gql`
            # noinspection GraphQLUnresolvedReference
            query frontpage {
                frontpages {
                    tagline
                    heading
                }
            }
        `
        const { frontpages } = await this.hygraph.request(q)
        return frontpages
    }

    @Cache(memoryCache, { ttl: 180 })
    async listVaults() {
        const q = gql`
            # noinspection GraphQLUnresolvedReference
            query vaults {
                vaults {
                    description
                    accent {
                        hex
                    }
                    slug
                    id
                    vaultId
                    icon {
                        url
                    }
                    background {
                        url
                    }
                }
            }
        `
        const { vaults } = await this.hygraph.request(q)
        return vaults
    }

    @Cache(memoryCache, { ttl: 180 })
    async getVaultDetail(vaultSlug: string) {
        const q = gql`
            # noinspection GraphQLUnresolvedReference
            query vault($slug: String = "arbeth-usdc") {
                vault(where: { slug: $slug }) {
                    accent {
                        hex
                        rgba {
                            r
                            g
                            b
                            a
                        }
                    }
                    contentAboveTheChart {
                        raw
                    }
                    contentBelowTheChart {
                        raw
                    }
                    id
                    slug
                    vaultId
                    icon {
                        url
                    }
                    background {
                        url
                    }
                    cover {
                        url
                    }
                    specs {
                        id
                        heading
                        description
                        background {
                            css
                        }
                        content {
                            raw
                        }
                    }
                }
            }
        `
        const { vault } = await this.hygraph.request(q, { slug: vaultSlug })
        return vault
    }
}

export const hygraphClient = new HygraphClient()
