import { Box, Dialog, IconButton, Text } from "@sayaww/nomimono"
import { Squircle } from "corner-smoothing"
import { motion } from "framer-motion"
import { useAtom } from "jotai"
import React from "react"
import { openBridge } from "src/util/uiAtom"

const MotionSquircle = motion(Squircle)
export const BridgeModal = () => {
    const [open, setOpen] = useAtom(openBridge)

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Portal>
                <Dialog.Overlay
                    style={{
                        zIndex: 30,
                        overflowY: "auto",
                        placeItems: "flex-start",
                    }}
                >
                    <Dialog.Content asChild>
                        <Squircle
                            cornerRadius={30}
                            cornerSmoothing={0.6}
                            style={{
                                marginTop: "80px",
                                marginBottom: "80px",
                                width: "100%",
                                maxWidth: "560px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                backgroundColor: "white",
                            }}
                        >
                            <Dialog.Close asChild>
                                <Box
                                    css={{
                                        position: "absolute",
                                        top: "$4",
                                        right: "$4",
                                        zIndex: "32",
                                    }}
                                >
                                    <IconButton
                                        onClick={() => setOpen(false)}
                                        style={{ borderRadius: "9999px" }}
                                        name="x"
                                        intent="ghost"
                                    />
                                </Box>
                            </Dialog.Close>
                            <Text size="title2" css={{ padding: "2rem", paddingBottom: 0 }}>
                                Bridge & Swap
                            </Text>
                            <iframe src="/bridge.html" width="100%" height="780" allowFullScreen></iframe>
                        </Squircle>
                    </Dialog.Content>
                </Dialog.Overlay>
            </Dialog.Portal>
        </Dialog.Root>
    )
}
