import { BrandButton } from "@sayaww/nomimono"
import { Avatar, ConnectKitButton } from "connectkit"
import { motion } from "framer-motion"
import React, { useState } from "react"
import { EventGroup, EventName, segmentService } from "src/service/segment/SegmentService"

const MotionBrandButton = motion(BrandButton)

// strip out the first 6 character of truncatedAddress
function firstAddress(address?: string) {
    return address ? `${address.slice(0, 6)}` : ""
}

// strip out the first 6 character of truncatedAddress
function stripAddress(address?: string) {
    return address ? `${address.slice(-4)}` : ""
}

export const ConnectWalletBtn = () => {
    const [whileHover, setWhileHover] = useState(false)

    return (
        <ConnectKitButton.Custom>
            {({ isConnected, isConnecting, show, address, truncatedAddress }) => {
                return (
                    <MotionBrandButton
                        layout
                        onHoverStart={() => setWhileHover(true)}
                        onHoverEnd={() => setWhileHover(false)}
                        isLoading={isConnecting}
                        onClick={() => {
                            segmentService.track({
                                eventGroup: EventGroup.INTERACTION,
                                eventName: EventName.BUTTON_CLICKED,
                                payload: {
                                    button: isConnected ? "Wallet Button" : "Connect Wallet",
                                    component: "header",
                                },
                            })
                            console.log("Connect Wallet button clicked")
                            show?.()
                        }}
                        leftIcon={isConnected && <Avatar address={address} size={20} />}
                        css={{
                            fontWeight: 400,
                            cursor: "pointer",
                            color: isConnected ? "white" : "black",
                            bg: isConnected ? "black" : undefined,
                            border: isConnected ? "1px solid #34343A" : "none",
                            "&:hover": {
                                bg: isConnected ? "rgba(255,255,255,0.1)" : undefined,
                            },
                        }}
                        size={{
                            "@initial": "xs",
                            "@sm": "s",
                            "@md": "s",
                            "@lg": "m",
                            "@xl": "m",
                            "@2xl": "m",
                        }}
                    >
                        {isConnected ? (
                            <motion.div
                                layout="position"
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {/* <motion.div
                                    style={{
                                        overflow: "hidden",
                                        display: "inline-block",
                                        lineHeight: 1,
                                    }}
                                    initial={{ width: 0, opacity: 0, scale: 0.5 }}
                                    animate={{
                                        width: whileHover ? "auto" : 0,
                                        opacity: whileHover ? 1 : 0,
                                        scale: whileHover ? 1 : 0.5,
                                    }}
                                >
                                    {firstAddress(truncatedAddress)}
                                </motion.div> */}
                                <motion.div
                                    style={{
                                        display: "inline-block",
                                        lineHeight: 1,
                                    }}
                                >
                                    <span
                                        style={{
                                            padding: "0.2em",
                                            opacity: 0.65,
                                        }}
                                    >
                                        ••••
                                    </span>
                                    {stripAddress(truncatedAddress)}
                                </motion.div>
                            </motion.div>
                        ) : (
                            "Connect Wallet"
                        )}
                    </MotionBrandButton>
                )
            }}
        </ConnectKitButton.Custom>
    )
}
