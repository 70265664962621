import React, { SVGProps } from "react"

export const Logo = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => {
    return (
        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                opacity="0.95"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.987426 12.0033C-0.329141 10.7136 -0.329143 8.59398 0.987425 7.30428L6.97441 1.43948C8.25314 0.186843 10.2988 0.18684 11.5776 1.43948L14.7533 4.5504L15.1868 4.12534C16.4622 2.87297 18.5023 2.87297 19.7777 4.12534L23.0149 7.30408C24.3284 8.59382 24.3284 10.7138 23.0149 12.0035C19.7597 15.2 16.4405 18.3443 13.2482 21.6032C12.9098 21.9487 12.4611 22.1214 12.0124 22.1202C11.5664 22.1205 11.1214 21.9479 10.7847 21.6034C7.59133 18.3364 4.251 15.2004 0.987426 12.0033ZM14.4725 9.66017L17.4823 12.6156L20.4985 9.6538L17.4823 6.69202C16.4769 7.67924 15.4791 8.67415 14.4725 9.66017ZM6.53413 12.6154L12.2935 6.96224L9.276 4.00634L3.51087 9.65381L6.53413 12.6154ZM15.0272 15.0263L12.0211 12.0616L8.99493 15.0259L12.0149 17.9843L15.0272 15.0263Z"
                fill="currentColor"
            />
        </svg>
    )
}

export const HotTubLogo = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => {
    return (
        <svg width="91" height="24" viewBox="0 0 91 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                opacity="0.95"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.987426 13.0033C-0.329141 11.7136 -0.329143 9.59398 0.987425 8.30428L6.97441 2.43948C8.25315 1.18684 10.2988 1.18684 11.5776 2.43948L14.7533 5.5504L15.1868 5.12534C16.4622 3.87297 18.5023 3.87297 19.7777 5.12534L23.0149 8.30408C24.3284 9.59381 24.3284 11.7138 23.0149 13.0035C19.7597 16.2 16.4405 19.3443 13.2482 22.6032C12.9098 22.9487 12.4611 23.1214 12.0124 23.1202C11.5664 23.1205 11.1214 22.9479 10.7847 22.6034C7.59133 19.3364 4.251 16.2004 0.987426 13.0033ZM14.4725 10.6602L17.4823 13.6156L20.4985 10.6538L17.4823 7.69202C16.4769 8.67924 15.4791 9.67415 14.4725 10.6602ZM6.53413 13.6154L12.2935 7.96224L9.276 5.00634L3.51087 10.6538L6.53413 13.6154ZM15.0272 16.0263L12.0211 13.0616L8.99493 16.0259L12.0149 18.9843L15.0272 16.0263Z"
                fill="white"
            />
            <path
                d="M38.1 20L32.44 6.28H35.32L39.46 16.92L43.62 6.28H46.48L40.86 20H38.1ZM50.2395 20.28C47.1195 20.28 45.0395 18.16 45.0395 15C45.0395 11.84 47.1195 9.72 50.2395 9.72C51.4195 9.72 52.7195 10.26 53.4795 11.08V10H55.8795V20H53.4795V18.92C52.7195 19.74 51.4195 20.28 50.2395 20.28ZM50.4795 18.16C52.2795 18.16 53.4795 16.9 53.4795 15C53.4795 13.1 52.2795 11.84 50.4795 11.84C48.6595 11.84 47.4395 13.1 47.4395 15C47.4395 16.9 48.6595 18.16 50.4795 18.16ZM67.0141 10V20H64.6141V19.26C63.9741 19.88 62.8741 20.28 61.8941 20.28C59.1741 20.28 57.3341 18.66 57.3341 16.22V10H59.7341V15.94C59.7341 17.26 60.7141 18.16 62.1741 18.16C63.6341 18.16 64.6141 17.26 64.6141 15.94V10H67.0141ZM68.8575 20V6.28H71.2575V20H68.8575ZM77.6558 20.28C75.6758 20.28 74.3358 19.14 74.3358 17.44V12H72.7358V10H74.3358V6.28H76.7358V10H79.2558V12H76.7358V17.44C76.7358 17.94 77.0958 18.28 77.6158 18.28H79.3558V20C78.9758 20.16 78.2958 20.28 77.6558 20.28ZM84.8748 20.28C82.1748 20.28 80.3148 18.8 80.2348 16.6H82.4948C82.5748 17.6 83.5348 18.28 84.8748 18.28C86.2148 18.28 87.1148 17.76 87.1148 16.98C87.1148 16.46 86.4748 16.16 84.6548 15.8C81.8148 15.16 80.8348 14.4 80.8348 12.82C80.8348 10.96 82.4548 9.72 84.8748 9.72C86.7948 9.72 88.4748 10.76 89.0548 12.32L86.8548 12.86C86.5548 12.18 85.7548 11.72 84.8748 11.72C83.8948 11.72 83.2348 12.12 83.2348 12.7C83.2348 13.12 83.7148 13.38 85.1148 13.7C88.3748 14.38 89.5148 15.22 89.5148 16.98C89.5148 18.96 87.6548 20.28 84.8748 20.28Z"
                fill="white"
            />
        </svg>
    )
}
