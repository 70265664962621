import Big from "big.js"
import { DateTime } from "luxon"
import { Cache } from "node-ts-cache"
import { retryHttpGet } from "../../util/http"
import { logMethod } from "../../util/log"
import { memoryCache } from "../cache/nodeTsCache"

export type DailyAprMap = Record<string, DailyApr>
export type DailyAprArrayMap = Record<string, DailyApr[]>

export interface DailyApr {
    date: DateTime
    lifeTimeApr: Big
    sevenDayRollingApr: Big
    thirtyDayRollingApr: Big
    sharePrice: Big
}

interface ApiDailyApr {
    address: string
    name: string
    time: string
    share_price: number
    daily_apr: number
    yesterday_pnl: number
    "7_day_apr": number
    "7_day_ago_pnl": number
    "30_day_apr": number
    "30_day_ago_pnl": number
    lifetime_apr: number
    block_number: number
}

export class AprClient {
    private readonly APR_VAULTS_ENDPOINT =
        "https://mo5x8mw6k0.execute-api.ap-southeast-1.amazonaws.com/production/vaults"
    private readonly APR_CHART_ENDPOINT = "https://mo5x8mw6k0.execute-api.ap-southeast-1.amazonaws.com/production/chart"

    // noinspection JSUnusedLocalSymbols
    @logMethod
    @Cache(memoryCache, { ttl: 180 })
    async getDailyAprMap(start: DateTime = DateTime.now(), length = 30): Promise<DailyAprMap> {
        try {
            const vaults = await retryHttpGet<ApiDailyApr[]>(this.APR_VAULTS_ENDPOINT)
            return vaults.reduce(
                (acc, vault) => ({
                    ...acc,
                    [vault.address.toLowerCase()]: this.toDailyAir(vault),
                }),
                {},
            )
        } catch (e) {
            throw new Error(`Failed to fetch daily APR: ${(e as Error).message}`)
        }
    }

    @logMethod
    @Cache(memoryCache, { ttl: 180 })
    async getHistoricalDailyAprs(address: string, days = 30): Promise<DailyApr[]> {
        const url = `${this.APR_CHART_ENDPOINT}?address=${address}&length=${days}`
        const dailyAprs = await retryHttpGet<ApiDailyApr[]>(url)
        const filteredData = dailyAprs.filter((_, index) => index < days)
        const reversedDailyAprs = filteredData.reverse()
        return reversedDailyAprs.map(it => this.toDailyAir(it))
    }

    private toDailyAir(it: ApiDailyApr): DailyApr {
        return {
            date: DateTime.fromSQL(it.time),
            lifeTimeApr: it.lifetime_apr ? Big(it.lifetime_apr) : Big(0),
            sevenDayRollingApr: it["7_day_apr"] ? Big(it["7_day_apr"]) : Big(0),
            thirtyDayRollingApr: it["30_day_apr"] ? Big(it["30_day_apr"]) : Big(0),
            sharePrice: it.share_price ? Big(it.share_price) : Big(0),
        }
    }
}

export const aprClient = new AprClient()
