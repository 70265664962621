import React from "react"
export const QueueDetails = ({
    maxDepositPerUser,
    depositAssetSymbol,
}: {
    maxDepositPerUser: string
    depositAssetSymbol: string
}) => (
    <ul>
        <li>Earn 1.5-5% yield in queue.</li>
        <li>
            Add unlimited funds, but only max {maxDepositPerUser} {depositAssetSymbol} will be auto-deposited each time.
        </li>
        <li>Remaining funds will be moved to the queue end.</li>
        <li>There may be a maximum of 0.01% loss when withdrawing from the queue due to smart contract limitations.</li>
    </ul>
)
