import Big from "big.js"
import { ContractReceipt, Signer, VoidSigner } from "ethers"
import { Kantaban, Kantaban__factory } from "../../typechain"
import { logMethod } from "../../util/log"
import { big2BigNum, bigNum2Big } from "../../util/number"
import { KantabanBalance } from "./KantabanVault"
import type { KantabanVaultMeta } from "./KantabanVaultMeta"

import { providerManager } from "../eth/ProviderManager"
import { vaultTokenClient } from "./VaultTokenClient"

export class KantabanClient {
    @logMethod
    async getTotalAssets(vaultMeta: KantabanVaultMeta): Promise<Big> {
        const kantaban = await this.getKantaban(vaultMeta.contractAddr)
        return kantaban.callStatic.totalAssets().then(it => bigNum2Big(it, vaultMeta.depositAsset.decimal))
    }

    @logMethod
    async getBalance(vaultMeta: KantabanVaultMeta, signer: Signer): Promise<KantabanBalance> {
        const { shareAsset } = vaultMeta
        const owner = await signer.getAddress()
        const shares = await vaultTokenClient.getShares(shareAsset, owner)
        if (shares.lte(0)) {
            return {
                positionShares: Big(0),
                positionShareValue: Big(0),
            }
        }
        const [totalShareValue, totalShares] = await Promise.all([
            this.getTotalAssets(vaultMeta),
            vaultTokenClient.getTotalSupply(shareAsset),
        ])
        const amount = this.calculatePositionShareValue(shares, totalShareValue, totalShares)
        return {
            positionShares: shares,
            positionShareValue: amount,
        }
    }

    calculatePositionShareValue(shares: Big, totalShareValue: Big, totalShares: Big): Big {
        return shares.mul(totalShareValue).div(totalShares)
    }

    @logMethod
    async deposit(vaultMeta: KantabanVaultMeta, amount: Big, signer: Signer): Promise<ContractReceipt> {
        const kantaban = await this.getKantaban(vaultMeta.contractAddr, signer)
        const tx = await kantaban.deposit(big2BigNum(amount, vaultMeta.depositAsset.decimal), this.getOverrides())
        return tx.wait()
    }

    @logMethod
    async previewRedeem(vaultMeta: KantabanVaultMeta, share: Big, slippage: Big, signer: Signer): Promise<Big> {
        const kantaban = await this.getKantaban(vaultMeta.contractAddr, signer, true)
        const minRedeemAmount = await this.getMinimumRedeemAmount(vaultMeta, share, slippage, signer)
        const redeemAmount = await kantaban.callStatic.redeem(
            big2BigNum(share, vaultMeta.shareAsset.decimal),
            big2BigNum(minRedeemAmount, vaultMeta.depositAsset.decimal),
        )
        return bigNum2Big(redeemAmount, vaultMeta.depositAsset.decimal)
    }

    @logMethod
    async redeem(vaultMeta: KantabanVaultMeta, share: Big, slippage: Big, signer: Signer): Promise<ContractReceipt> {
        const kantaban = await this.getKantaban(vaultMeta.contractAddr, signer)
        // if minRedeemAmount set to 0, means don't care about slippage
        const minRedeemAmount = await this.getMinimumRedeemAmount(vaultMeta, share, slippage, signer)
        const tx = await kantaban.redeem(
            big2BigNum(share, vaultMeta.shareAsset.decimal),
            big2BigNum(minRedeemAmount, vaultMeta.depositAsset.decimal),
            this.getOverrides(),
        )
        return tx.wait()
    }

    private getOverrides() {
        return {
            gasLimit: 12_000_000,
        }
    }

    private async getKantaban(contractAddr: string, signer?: Signer, forCallStatic = false): Promise<Kantaban> {
        const provider = await providerManager.getProvider()
        if (signer) {
            if (forCallStatic) {
                const address = await signer.getAddress()
                return Kantaban__factory.connect(contractAddr, new VoidSigner(address, provider))
            }
            return Kantaban__factory.connect(contractAddr, signer)
        }
        return Kantaban__factory.connect(contractAddr, provider)
    }

    async getMinimumRedeemAmount(vaultMeta: KantabanVaultMeta, share: Big, slippage: Big, signer: Signer) {
        const kantaban = await this.getKantaban(vaultMeta.contractAddr, signer, true)
        const redeemAmount = await kantaban.callStatic
            .redeem(big2BigNum(share, vaultMeta.shareAsset.decimal), 0)
            .then(it => bigNum2Big(it, vaultMeta.depositAsset.decimal))
        return redeemAmount.mul(Big(1).minus(slippage))
    }
}

export const kantabanClient = new KantabanClient()
